import Typography from 'components/base/Typography';
import React from 'react';
import { Colors } from 'styles/colors';

interface Props {
    patientName: string;
    procedureType: string;
    quantity: number;
    appointmentTime: string | null;
    comment?: string;
}

const ScheduleHeader: React.FC<Props> = (props) => {
    return (
        <>
            <Typography
                type="span"
                color={Colors.LightBlack}
                textAlign="left"
                size={14}
                marginBottom={7}
                weight="medium"
            >
                {props.appointmentTime}
            </Typography>
            <Typography type="h3" color={Colors.Orange} textAlign="left" marginBottom={6} weight="medium">
                {props.patientName}
            </Typography>
            <Typography weight="regular" size={14} marginBottom={0} type="p" textAlign="left">
                {props.procedureType} ({props.quantity})
            </Typography>
            {props.comment && (
                <Typography color={Colors.DarkGray} type="span" lh={22} size={14} textAlign="left" marginBottom={0}>
                    {props.comment}
                </Typography>
            )}
        </>
    );
};

export default ScheduleHeader;
