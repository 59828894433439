import { DiagnosticResultType } from '@ugmk/api';
import Typography from 'components/base/Typography';
import { convertDateToDDMMYYYY } from 'helpers';
import React from 'react';
import { PatientCardPageLocationState } from '../..';
import PatientResultForm from './components/patient-result-form/patient-result-form.component';
import PatientResultOptionsButton from './components/patient-result-options-button/patient-result-options-button.component';
import * as S from './styles';

interface Props {
    resultData: DiagnosticResultType;
    fromAppointmentState?: PatientCardPageLocationState;
}

const PatientResultCard: React.FC<Props> = ({ resultData, fromAppointmentState }) => {
    const doctorFullName = resultData.doctorFullName
        .split(' ')
        .map((item, ind) => (ind === 0 ? item : ind < 3 ? (item[0] || '') + '.' : ''))
        .join(' ');

    return (
        <S.Container>
            <S.CardRow>
                <Typography type="span" weight="medium" textAlign="left">
                    {convertDateToDDMMYYYY(resultData.dateConsultation)}
                </Typography>
                <PatientResultOptionsButton resultData={resultData} fromAppointmentState={fromAppointmentState} />
            </S.CardRow>
            <Typography type="span" size={14} textAlign="left">
                {doctorFullName}
            </Typography>
            <PatientResultForm serviceType={resultData.serviceType} resultData={resultData} />
        </S.Container>
    );
};

export default PatientResultCard;
