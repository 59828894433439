import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButtonStyles as S } from './back-button.styles';

interface Props {
    label?: string;
    withIcon?: boolean;
    styles?: CSSProperties;
}

export const BackButton: React.FC<Props> = ({ label = '', withIcon = false, styles }) => {
    const navigate = useNavigate();

    return (
        <S.Container style={styles} onClick={() => navigate(-1)}>
            {withIcon && <S.Icon as={Icon} type={IconType.Arrow} />}
            {label}
        </S.Container>
    );
};
