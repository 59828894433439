import { ServiceType } from '@ugmk/api';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmAppointmentAction } from 'store/appointments/appointments.actions';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { ClearSelectedTemplate } from '../../store/appointment.actions';
import {
    appointmentIdSelector,
    appointmentServiceTypeSelector,
    selectedTemplateSelector,
} from '../../store/appointment.selectors';
import CheckupForm, { CheckupFormData } from './checkup-form/checkup-form.component';
import LfkForm, { LFKFormData } from './lfk-form/lfk-form.component';

interface Props {
    savedFormData?: CheckupFormData | LFKFormData | null;
    onFormChange?: (data: CheckupFormData | LFKFormData) => void;
    onClear: () => void;
}

const AppointmentForm: React.FC<Props> = React.memo(({ onFormChange, savedFormData, onClear }) => {
    const dispatch = useDispatch();
    const serviceType = useSelector(appointmentServiceTypeSelector);
    const appointmentId = useSelector(appointmentIdSelector);
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const selectedTemplate = useSelector(selectedTemplateSelector);

    const onConfirm = useCallback(
        (data: CheckupFormData | LFKFormData) => {
            if (currentStaffUnit && appointmentId) {
                dispatch({ ...new ClearSelectedTemplate() });
                dispatch({
                    ...new ConfirmAppointmentAction({
                        ...data,
                        appointmentId,
                        medDepId: currentStaffUnit.medDepId,
                        externalMedDepId: currentStaffUnit.externalMedDepId,
                    }),
                });
            }
        },
        [dispatch, currentStaffUnit, appointmentId],
    );

    if (serviceType === ServiceType.LFK) {
        return (
            <LfkForm
                savedFormData={savedFormData as LFKFormData | null}
                onFormChange={onFormChange}
                selectedTemplate={selectedTemplate}
                onConfirm={onConfirm}
                onClear={onClear}
            />
        );
    }
    if (serviceType === ServiceType.Checkup) {
        return (
            <CheckupForm
                savedFormData={savedFormData}
                selectedTemplate={selectedTemplate}
                onConfirm={onConfirm}
                onFormChange={onFormChange}
                onClear={onClear}
            />
        );
    }
    return <>Для данной записи в расписание не удалось подобрать оказываемую услугу</>;
});

export default AppointmentForm;
