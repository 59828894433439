import { Action } from 'redux';
import { ModalFactoryType, ModalTypeDataList } from './factory-modal.types';

export class OpenFactoryModal<ModalType extends ModalFactoryType> implements Action {
    public static readonly Name = '[Factory Modal] OpenFactoryModal';
    readonly type = OpenFactoryModal.Name;
    constructor(public modalType: ModalType, public data: ModalTypeDataList[ModalType]) {}
}

export class CloseFactoryModal<ModalType extends ModalFactoryType> implements Action {
    public static readonly Name = '[Factory Modal] CloseFactoryModal';
    readonly type = CloseFactoryModal.Name;
    constructor(public modalType: ModalType) {}
}

export type FactoryModalActions<ModalType extends ModalFactoryType> =
    | OpenFactoryModal<ModalType>
    | CloseFactoryModal<ModalType>;
