import Header from './components/Header';
import MenuNavigation from './components/MenuNavigation';
import HelpLink from './components/help-link/help-link.component';
import React, { PropsWithChildren } from 'react';
import { Content, MainLayoutContainer } from './styles';
import MainLayoutProvider, { useMainLayoutState } from './state/MainLayoutProvider';

const Container: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { headerActive } = useMainLayoutState();

    return (
        <MainLayoutContainer isHeaderActive={headerActive}>
            <Content>{children}</Content>
        </MainLayoutContainer>
    );
};

const MainLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <MainLayoutProvider>
            <Container>{children}</Container>
        </MainLayoutProvider>
    );
};

export default Object.assign(MainLayout, { Header, MenuNavigation, HelpLink });
