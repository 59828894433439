import { combineReducers } from 'redux';
import { combineFactoryModalReducers } from './factory-modal/factory-modal.helper';
import { FactoryModalsMapState } from './factory-modal/factory-modal.types';
import { infoModalReducer, InfoModalState } from './info-modal/info-modal.reducers';

export interface CommonState {
    infoModal: InfoModalState;
    factoryModal: FactoryModalsMapState;
}

export const commonReducer = combineReducers<CommonState>({
    infoModal: infoModalReducer,
    factoryModal: combineFactoryModalReducers(),
});
