import Typography from 'components/base/Typography';
import React from 'react';
import { Colors } from 'styles/colors';

interface Props {
    patientName: string;
    yearBirth: string | null;
    procedureType: string;
    quantity: number;
    comment?: string;
}

const ServiceHeader: React.FC<Props> = (props) => {
    return (
        <>
            <Typography type="h3" color={Colors.Orange} textAlign="left" marginBottom={6} weight="medium">
                {props.patientName}
            </Typography>
            <Typography type="span" size={14} textAlign="left" marginBottom={12}>
                Год рождения — {props.yearBirth}
            </Typography>
            <Typography weight="medium" size={14} type="p" marginBottom={0} textAlign="left">
                {props.procedureType} ({props.quantity})
            </Typography>
            {props.comment && (
                <Typography color={Colors.DarkGray} type="span" lh={22} size={14} textAlign="left" marginBottom={0}>
                    {props.comment}
                </Typography>
            )}
        </>
    );
};

export default ServiceHeader;
