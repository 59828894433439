import appointmentPageSaga from 'pages/main-app/appointment/store/appointment.saga';
import patientAnalyzesPageSaga from 'pages/main-app/patient-analyzes/store/patient-analyzes.saga';
import patientCardPageSaga from 'pages/main-app/patient-card/store/patient-card.saga';
import patientPageSaga from 'pages/main-app/patient/store/patient.saga';
import { all, spawn } from 'redux-saga/effects';
import appointmentSaga from './appointments/appointments.saga';
import authSaga from './auth/auth.saga';
import handbooksSaga from './handbooks/handbooks.saga';
import patientsSaga from './patients/patients.saga';

export default function* rootSaga() {
    yield all([
        spawn(authSaga),
        spawn(appointmentPageSaga),
        spawn(appointmentSaga),
        spawn(patientsSaga),
        spawn(handbooksSaga),
        spawn(patientCardPageSaga),
        spawn(patientPageSaga),
        spawn(patientAnalyzesPageSaga),
    ]);
}
