import {Appointment, AppointmentFilterType, IAppointmentType, ServiceType} from '@ugmk/api';
import AppointmentFilters from 'models/appointment/AppointmentFilters';
import moment from 'moment';
import {
    AppointmentsActions,
    ClearAppointmentsAction,
    ConfirmAppointmentAction, ConfirmAppointmentActionError, ConfirmAppointmentActionSuccess,
    FinishAppointmentsFetching,
    GetAppointmentsAction,
    GetAppointmentsErrorAction,
    GetAppointmentsSuccessAction,
    GetAppointmentTypesErrorAction,
    GetAppointmentTypesSuccessAction,
    LoadNextPageAppointmentsAction,
    LoadNextPageAppointmentsSuccessAction,
    SetAppointmentFiltersAction,
    StartAppointmentsFetching,
    UpdateAppointmentQuantityAction,
} from './appointments.actions';

export interface AppointmentsState {
    isLoading: boolean;
    isConfirmPending: boolean;
    isAppointmentTypesInitialized: boolean;
    appointments: Appointment[];
    isAppointmentsOver: boolean;
    appointmentTypes: IAppointmentType[];
    filters: AppointmentFilters;
    error?: any;
}

const initialState: AppointmentsState = {
    isLoading: true,
    isConfirmPending: false,
    isAppointmentTypesInitialized: false,
    appointments: [],
    isAppointmentsOver: false,
    appointmentTypes: [],
    filters: {
        type: undefined,
        serviceType: ServiceType.All,
        page: 1,
        dateFilter: moment(new Date()).format('YYYY-MM-DD'),
        searchName: undefined,
        filterType: AppointmentFilterType.Service,
    },
    error: undefined,
};

export function appointmentsReducer(state = initialState, action: AppointmentsActions): AppointmentsState {
    switch (action.type) {
        case LoadNextPageAppointmentsAction.Name:
        case GetAppointmentsAction.Name: {
            const isAppointmentsOver = state.isAppointmentsOver;

            if (isAppointmentsOver) {
                return {
                    ...state,
                };
            }
            return {
                 ...state,
                 isLoading: true,
            };
        }

        case UpdateAppointmentQuantityAction.Name: {
            const updatedAppointments = [...state.appointments];
            const appointmentInd = updatedAppointments.findIndex((a) => a.appointmentId === action.appointmentId);

            if (appointmentInd > -1) {
                if (updatedAppointments[appointmentInd].quantity > 1) {
                    updatedAppointments[appointmentInd].quantity -= 1;
                } else {
                    updatedAppointments.splice(appointmentInd, 1);
                }
            }

            return {
                ...state,
                appointments: updatedAppointments,
            };
        }

        case LoadNextPageAppointmentsSuccessAction.Name: {
            return {
                ...state,
                isLoading: false,
                appointments: state.appointments.concat(action.newAppointments),
                filters: {
                    ...state.filters,
                    page: action.nextPage,
                },
            };
        }

        case StartAppointmentsFetching.Name: {
            return {
                ...state,
                isLoading: true, // true
                isAppointmentsOver: false, // false
            };
        }

        case FinishAppointmentsFetching.Name: {
            return {
                ...state,
                isLoading: false, // false
                isAppointmentsOver: true, // true
            };
        }

        case GetAppointmentsSuccessAction.Name: {
            return {
                ...state,
                isLoading: false,
                appointments: action.data,
            };
        }

        case GetAppointmentsErrorAction.Name:
        case GetAppointmentTypesErrorAction.Name: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case GetAppointmentTypesSuccessAction.Name: {
            return {
                ...state,
                appointmentTypes: action.data,
                isLoading: false,
                isAppointmentTypesInitialized: true,
            };
        }

        case SetAppointmentFiltersAction.Name: {
            return {
                ...state,
                isLoading: true,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        }

        case ClearAppointmentsAction.Name: {
            return {
                ...state,
                appointments: [],
                isAppointmentsOver: false,
                filters: {
                    ...state.filters,
                    page: 1,
                },
            };
        }

        case ConfirmAppointmentAction.Name: {
            return {
                ...state,
                isConfirmPending: true
            }
        }

        case ConfirmAppointmentActionSuccess.Name: {
            return {
                ...state,
                isConfirmPending: false
            }
        }

        case ConfirmAppointmentActionError.Name: {
            return {
                ...state,
                isConfirmPending: false
            }
        }

        default:
            return state;
    }
}
