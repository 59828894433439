import { combineReducers } from 'redux';
import { History } from 'history';
import { authReducer, AuthState } from './auth/auth.reducers';
import { commonReducer, CommonState } from './common/common.reducers';
import { appointmentsReducer, AppointmentsState } from './appointments/appointments.reducers';
import { patientReducer, PatientState } from './patients/patients.reducers';
import { handbooksReducer, HandbooksState } from './handbooks/handbooks.reducers';
import { appointmentPageReducer, AppointmentPageState } from 'pages/main-app/appointment/store/appointment.reducers';
import { patientCardPageReducer, PatientCardPageState } from 'pages/main-app/patient-card/store/patient-card.reducer';
import { createRouterReducer, ReduxRouterState } from '@lagunovsky/redux-react-router';
import { patientPageReducer, PatientPageState } from 'pages/main-app/patient/store/patient.reducer';
import {
    patientAnalyzesPageReducer,
    PatientAnalyzesPageState,
} from 'pages/main-app/patient-analyzes/store/patient-analyzes.reducer';

export interface RootState {
    auth: AuthState;

    appointmentPage: AppointmentPageState;
    patientCardPage: PatientCardPageState;
    patientPage: PatientPageState;
    patientAnalyzesPage: PatientAnalyzesPageState;

    appointments: AppointmentsState;
    patient: PatientState;
    common: CommonState;
    handbooks: HandbooksState;
    router: ReduxRouterState;
}

export const createRootReducer = (history: History) =>
    combineReducers<RootState>({
        auth: authReducer,
        appointmentPage: appointmentPageReducer,
        appointments: appointmentsReducer,
        patient: patientReducer,
        patientCardPage: patientCardPageReducer,
        common: commonReducer,
        handbooks: handbooksReducer,
        router: createRouterReducer(history),
        patientPage: patientPageReducer,
        patientAnalyzesPage: patientAnalyzesPageReducer,
    });
