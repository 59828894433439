import { CheckupTemplate } from '@ugmk/api';
import { CheckupTemplateInfoKey } from './template-info.types';

export function getCheckupTemplateFieldText(
    templateItem: CheckupTemplate,
    key: CheckupTemplateInfoKey,
): string | undefined {
    switch (key) {
        case 'diagnosis': {
            return templateItem[key]?.code;
        }
        case 'isClose': {
            return templateItem[key] ? 'Закрыт' : 'Открыт';
        }
        case 'outcome': {
            return templateItem[key]?.title;
        }
        case 'result': {
            return templateItem[key]?.title;
        }
        default: {
            return templateItem[key];
        }
    }
}
