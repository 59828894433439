import React from 'react';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    background-color: ${Colors.DarkBlue};
    height: 58px;
    width: 58px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.White};
    font-size: 24px;
    font-weight: 600;
`;

const HelpLink: React.FC = () => {
    return (
        <Container as={'a'} href="http://medapphelp.tilda.ws" target="_blank">
            ?
        </Container>
    );
};

export default HelpLink;
