export const TOKEN = 'TOKEN';

export function setToken(token: string, key: string = TOKEN) {
    window.localStorage.setItem(key, token);
}

export function getToken(key: string = TOKEN): string | null {
    const token = window.localStorage.getItem(key);
    return token || null;
}

export function clearStorage() {
    window.localStorage.removeItem(TOKEN);
}
