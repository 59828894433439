import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Item = styled.div`
    padding: 18px 20px;
    width: max-content;
    color: ${Colors.LightBlack};
    font-weight: 500;

    &:not(:last-child) {
        border-bottom: 1px solid ${Colors.Gray};
    }
`;
