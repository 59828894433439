import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import React, { PropsWithChildren, useRef } from 'react';
import KebabMenuProvider, { useKebabMenuContext } from './kebab-menu.provider';
import * as S from './kebab-menu.styles';

const Container: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <KebabMenuProvider>
            <S.Container>{children}</S.Container>
        </KebabMenuProvider>
    );
};

type OnToggleFunctionType = (isShow: boolean) => React.ReactNode;
const Toggler: React.FC<{ children: React.ReactNode | OnToggleFunctionType }> = ({ children }) => {
    const { toggleMenu, isShowing } = useKebabMenuContext();

    return (
        <S.Toggler onClick={() => toggleMenu()}>
            {typeof children === 'function' ? (children as OnToggleFunctionType)(isShowing) : children}
        </S.Toggler>
    );
};

const List: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const menuRef = useRef<HTMLDivElement | null>(null);
    const { isShowing, toggleMenu } = useKebabMenuContext();
    useOutsideClickHandler(menuRef, () => toggleMenu(false));

    if (isShowing) {
        return (
            <S.Menu ref={menuRef} onClick={() => toggleMenu(false)}>
                {children}
            </S.Menu>
        );
    }
    return null;
};

export const Kebab = Object.assign(Container, { Toggler, List });
