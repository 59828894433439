import { call, put, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { ApiResponse, Appointment, AppointmentApi, TemplateApi, TemplateType } from '@ugmk/api';
import { AppointmentPageType } from 'models/appointment/appointment-page-type';
import {
    DeleteTamplate,
    DeleteTemplateError,
    DeleteTemplateSuccess,
    GetAppointmentById,
    GetAppointmentByIdError,
    GetAppointmentByIdSuccess,
    GetTemplates,
    GetTemplatesError,
    GetTemplatesSuccess,
} from './appointment.actions';

function* getAppointmentByIdHandler(action: GetAppointmentById) {
    const getAppointmentById =
        action.pageType === AppointmentPageType.Schedule
            ? AppointmentApi.getAppointmentScheduleById
            : AppointmentApi.getAppointmentServiceById;
    const { data, error }: ApiResponse<Appointment> = yield call(getAppointmentById, action.id, action.medDepId);

    if (data) {
        yield put({ ...new GetAppointmentByIdSuccess(data) });
    } else if (error) {
        yield put({ ...new GetAppointmentByIdError(error) });
    }
}

function* getTemplatesHandler(action: GetTemplates): SagaIterator {
    const { data, error }: ApiResponse<TemplateType[]> = yield call(TemplateApi.getTemplates, action.serviceType);

    if (data) {
        yield put({ ...new GetTemplatesSuccess(data) });
    } else if (error) {
        yield put({ ...new GetTemplatesError(error) });
    }
}

function* deleteTemplateHandler(action: DeleteTamplate): SagaIterator {
    const { error }: ApiResponse<any> = yield call(TemplateApi.deleteTemplateById, action.templateId);

    if (!error) {
        yield put({ ...new DeleteTemplateSuccess(action.templateId) });
        action.successCb();
    } else {
        yield put({ ...new DeleteTemplateError(error) });
    }
}

export default function* appointmentPageSaga(): SagaIterator {
    yield takeLatest(GetAppointmentById.Name, getAppointmentByIdHandler);
    yield takeLatest(GetTemplates.Name, getTemplatesHandler);
    yield takeLatest(DeleteTamplate.Name, deleteTemplateHandler);
}
