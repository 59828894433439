import React from 'react';
import { CheckupDiagnosticResult } from '@ugmk/api';
import { Textarea } from 'components/base/input';
import Switcher from 'components/base/Switcher';
import { TextareaOnlyRead } from '../../../../styles';

interface Props extends CheckupDiagnosticResult {}
const EMPTY_STATE_STRING = 'Здесь ещё ничего нет...';

export const CheckupResultForm: React.FC<Props> = (props) => {
    return (
        <>
            <TextareaOnlyRead isActive={!!props.complaints}>
                <Textarea
                    name="complaints"
                    defaultValue={props.complaints || EMPTY_STATE_STRING}
                    label="Жалобы"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <TextareaOnlyRead isActive={!!props.expertAnamnesis}>
                <Textarea
                    name="expertAnamnesis"
                    defaultValue={props.expertAnamnesis || EMPTY_STATE_STRING}
                    label="Экспертный анамнез"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <TextareaOnlyRead isActive={!!props.diseaseHistory}>
                <Textarea
                    name="diseaseHistory"
                    defaultValue={props.diseaseHistory || EMPTY_STATE_STRING}
                    label="История заболевания"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <TextareaOnlyRead isActive={!!props.objectively}>
                <Textarea
                    name="objectively"
                    defaultValue={props.objectively || EMPTY_STATE_STRING}
                    label="Объективно"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <TextareaOnlyRead isActive={!!props.diagnosis}>
                <Textarea
                    name="diagnosis"
                    defaultValue={props.diagnosis || EMPTY_STATE_STRING}
                    label="Код диагноза"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <TextareaOnlyRead isActive={!!props.diagnosisDescription}>
                <Textarea
                    name="diagnosis"
                    defaultValue={props.diagnosisDescription || EMPTY_STATE_STRING}
                    label="Диагноз"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <TextareaOnlyRead isActive={!!props.recommendations}>
                <Textarea
                    name="recommendations"
                    defaultValue={props.recommendations || EMPTY_STATE_STRING}
                    label="Рекомендации"
                    disabled={true}
                />
            </TextareaOnlyRead>
            <Switcher label="Случай закрыт" value={!!props.isClose} onChange={() => {}} disabled={true} />

            {!props.isClose && (
                <TextareaOnlyRead isActive={!!props.result}>
                    <Textarea
                        name="result"
                        defaultValue={props.result || EMPTY_STATE_STRING}
                        label="Результат"
                        disabled={true}
                    />
                </TextareaOnlyRead>
            )}
            {!props.isClose && (
                <TextareaOnlyRead isActive={!!props.outcome}>
                    <Textarea
                        name="outcome"
                        defaultValue={props.outcome || EMPTY_STATE_STRING}
                        label="Исход"
                        disabled={true}
                    />
                </TextareaOnlyRead>
            )}
        </>
    );
};
