import { ServiceType } from '@ugmk/api';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Loader from 'components/base/Loader';
import Typography from 'components/base/Typography';
import MainLayout from 'components/layouts/MainLayout';
import { BackButton } from 'components/partial/back-button';
import { useLocationState } from 'hooks/use-location-state';
import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import { CheckupFormData } from '../appointment/components/appointment-form/checkup-form/checkup-form.component';
import { LFKFormData } from '../appointment/components/appointment-form/lfk-form/lfk-form.component';
import { usePatientById } from './hooks/use-patient-by-id';
import * as S from './patient.styles';

export interface PatientPageLocationState {
    fromAppointment: boolean;
    serviceType: ServiceType;
    oldAppointmentState: CheckupFormData | LFKFormData | null;
}

type PatientPageParams = Record<'id', string>;

const PatientPage: React.FC = () => {
    const params = useParams<PatientPageParams>();
    const locationState = useLocationState<PatientPageLocationState>();
    const { loadingPatient, patient, errorPatient } = usePatientById(params.id || null);

    return (
        <MainLayout>
            <MainLayout.Header isShowSearchButton={false}>
                <S.HeaderContainer>
                    <BackButton label="Вернуться назад" withIcon={true} styles={{ marginBottom: 20 }} />
                    {loadingPatient && <Loader size={20} />}
                    {!loadingPatient && errorPatient && <S.ErrorMessage>{errorPatient}</S.ErrorMessage>}
                    {!loadingPatient && patient && (
                        <S.HeaderRow>
                            <Typography type="h3" size={17} weight="medium" marginBottom={0} textAlign="left">
                                {patient.surname} {patient.name} {patient.patronymic[0]}.
                            </Typography>
                            <Typography type="span" size={14} weight="regular" marginBottom={0}>
                                ({patient.birthYear} г.)
                            </Typography>
                        </S.HeaderRow>
                    )}
                </S.HeaderContainer>
            </MainLayout.Header>
            <S.Body>
                {loadingPatient && <Loader />}
                {!loadingPatient &&
                    patient &&
                    SECTIONS.map((section, ind) => {
                        return (
                            <S.SectionButton
                                key={ind}
                                to={generatePath(section.route, { id: params.id })}
                                state={locationState}
                            >
                                {section.title}
                                <Icon type={IconType.Angle} />
                            </S.SectionButton>
                        );
                    })}
            </S.Body>
            <MainLayout.MenuNavigation />
        </MainLayout>
    );
};

const SECTIONS = [
    { title: 'Диагностика', route: AppRoutes.PatientAnalyzesList },
    { title: 'Медицинская карта', route: AppRoutes.PatientCard },
];

export default PatientPage;
