import { Button } from 'components/base/button';
import { IconType } from 'components/base/icon/types';
import { InputText } from 'components/base/input';
import PatientCardResultsFilters from 'models/patient/PatientCardResultsFilters';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetCardResultsFiltersAction } from 'store/patients/patients.actions';
import { RootState } from 'store/root.reducer';
import { ServiceType } from '@ugmk/api';
import ServiceTypeFilter from '../service-type-filter';
import { SearchFormContainer } from './styles';

interface Props {
    currentMedDepId: string;
    onAfterSearch?: () => void;
}

const PatientCardResultsSearchForm: React.FC<Props> = ({ currentMedDepId, onAfterSearch }) => {
    const dispatch = useDispatch();
    const patientCardResultsFilters = useSelector((state: RootState) => state.patient.cardResultsFilters);
    const [headerFilters, setHeaderFilters] = useState<Partial<PatientCardResultsFilters>>({});

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = event.target.value;

        if (searchString) {
            setHeaderFilters((prevState) => ({ ...prevState, searchString }));
        }
    };

    const onSearch = () => {
        dispatch({
            ...new SetCardResultsFiltersAction(headerFilters, currentMedDepId),
        });
        onAfterSearch && onAfterSearch();
    };

    const onServiceTypeChange = (serviceType: ServiceType) => {
        setHeaderFilters((prevState) => ({ ...prevState, serviceType: serviceType }));
    };

    return (
        <SearchFormContainer>
            <InputText
                onChange={onChangeHandler}
                label="Поиск по ключевой фразе"
                icon={IconType.Search}
                isClear={true}
            />
            <ServiceTypeFilter
                onChange={onServiceTypeChange}
                value={
                    headerFilters?.serviceType !== undefined
                        ? headerFilters.serviceType
                        : patientCardResultsFilters.serviceType
                }
            />
            <Button label="Найти запись" onClick={onSearch} />
        </SearchFormContainer>
    );
};

export default PatientCardResultsSearchForm;
