import React, { useState, useEffect } from 'react';
import { Diagnosis } from '@ugmk/api';
import * as S from './diagnosis-field.styles';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import { AppStateContext } from 'state';
import { InputText } from 'components/base/input';

interface Props {
    value?: Diagnosis;
    error?: string;
    onSelectCode: (diagnosis: Diagnosis) => void;
}

const DiagnosisField: React.FC<Props> = ({ value, error, onSelectCode }) => {
    const [diagnosisFromScreen, setDiagnosisFromScreen] = useState<Diagnosis | undefined>();

    useEffect(() => {
        setDiagnosisFromScreen(value);
    }, [value]);

    /*const onDiagnosisSearch = (searchString: string) => {
        if (!currentStaffUnit) return;
        dispatch({ ...new GetDiagnosesBySearchString(currentStaffUnit.medDepId, searchString) });
    };*/

    return (
        <S.Container>
            <InputText disabled={true} error={error} label="Код диагноза" defaultValue={diagnosisFromScreen?.code} />
            <AppStateContext.Consumer>
                {({ openDiagnosisScreen }) => (
                    <S.DiagnosisScreenOpener
                        onClick={() =>
                            openDiagnosisScreen((diagnosis) => {
                                setDiagnosisFromScreen(diagnosis);
                                onSelectCode(diagnosis);
                            })
                        }
                    >
                        <Icon type={IconType.SquareList} />
                    </S.DiagnosisScreenOpener>
                )}
            </AppStateContext.Consumer>
        </S.Container>
    );
};

export default DiagnosisField;
