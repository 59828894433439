import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store/root.reducer';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './checkup-form.styles';
import { Textarea } from 'components/base/input';
import Switcher from 'components/base/Switcher';
import { Dropdown } from 'components/base/dropdown';
import { Button } from 'components/base/button';
import { DiagnosisField } from './components/diagnosis-field';
import { CheckupTemplate } from '@ugmk/api';
import { LocalStorageService } from 'helpers/local-storage-service/local-storage-service';
import { LocalStorageKey } from 'helpers/local-storage-service/local-storage-service.types';
import { isDiagnosisFieldRequiredSelector } from 'pages/main-app/appointment/store/appointment.selectors';

export type CheckupFormData = {
    diagnosisId?: string;
    diagnosisDescription?: string;
    isClose?: boolean;
    outcomeId?: string;
    resultId?: string;
    complaints?: string;
    expertAnamnesis?: string;
    diseaseHistory?: string;
    objectively?: string;
    recommendations?: string;
    sendToLK?: boolean;
};

const getSchema = (isDiagnosisRequired: boolean) =>
    yup.object().shape({
        diagnosisId: isDiagnosisRequired
            ? yup.number().nullable().required('Выберите диагноз')
            : yup.number().nullable().notRequired(),
        diagnosisDescription: yup.string().nullable().notRequired(),
        outcomeId: yup
            .string()
            .nullable()
            .when('isClose', {
                is: true,
                then: yup.string().required('Выберите исход'),
            }),
        resultId: yup
            .string()
            .nullable()
            .when('isClose', {
                is: true,
                then: yup.string().required('Выберите результат'),
            }),
        complaints: yup.string().nullable().notRequired(),
        expertAnamnesis: yup.string().nullable().notRequired(),
        diseaseHistory: yup.string().nullable().notRequired(),
        objectively: yup.string().nullable().notRequired(),
        recommendations: yup.string().nullable().notRequired(),
        isClose: yup.boolean(),
        sendToLK: yup.boolean(),
    });

interface Props {
    savedFormData?: CheckupFormData | null;
    selectedTemplate?: CheckupTemplate;
    onFormChange?: (data: CheckupFormData) => void;
    onConfirm: (data: CheckupFormData) => void;
    onClear: () => void;
}

const CheckupForm: React.FC<Props> = ({ onConfirm, onFormChange, onClear, selectedTemplate, savedFormData }) => {
    const results = useSelector((root: RootState) => root.handbooks.results);
    const outcomes = useSelector((root: RootState) => root.handbooks.outcomes);
    const isConfirmPending = useSelector((root: RootState) => root.appointments.isConfirmPending);
    const isDiagnosisFieldRequired = useSelector(isDiagnosisFieldRequiredSelector);

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<CheckupFormData>({
        resolver: yupResolver(getSchema(isDiagnosisFieldRequired)),
        defaultValues: {
            diagnosisId: selectedTemplate?.diagnosis?.diagnosisId || savedFormData?.diagnosisId || undefined,
            diagnosisDescription: selectedTemplate?.diagnosisDescription || savedFormData?.diagnosisDescription || '',
            outcomeId: selectedTemplate?.outcome?.outcomeId || savedFormData?.outcomeId || '',
            resultId: selectedTemplate?.result?.resultId || savedFormData?.resultId || '',
            complaints: selectedTemplate?.complaints || savedFormData?.complaints || '',
            expertAnamnesis: selectedTemplate?.expertAnamnesis || savedFormData?.expertAnamnesis || '',
            diseaseHistory: selectedTemplate?.diseaseHistory || savedFormData?.diseaseHistory || '',
            objectively: selectedTemplate?.objectively || savedFormData?.objectively || '',
            recommendations: selectedTemplate?.recommendations || savedFormData?.recommendations || '',
            isClose:
                selectedTemplate !== undefined
                    ? selectedTemplate.isClose
                    : savedFormData
                    ? savedFormData.isClose
                    : true,
        },
    });

    const formStateValuesMap = watch();

    useEffect(() => {
        if (onFormChange) {
            onFormChange(formStateValuesMap);
        }
    }, [formStateValuesMap, onFormChange]);

    useEffect(() => {
        if (!selectedTemplate) return;
        if (selectedTemplate.diagnosis) {
            LocalStorageService.setItem(LocalStorageKey.SAVED_DIAGNOSIS_FORM_DATA, selectedTemplate.diagnosis);
        }

        setValue('diagnosisId', selectedTemplate.diagnosis?.diagnosisId);
        setValue('diagnosisDescription', selectedTemplate.diagnosisDescription);
        setValue('outcomeId', selectedTemplate.outcome?.outcomeId);
        setValue('resultId', selectedTemplate.result?.resultId);
        setValue('complaints', selectedTemplate.complaints);
        setValue('expertAnamnesis', selectedTemplate.expertAnamnesis);
        setValue('diseaseHistory', selectedTemplate.diseaseHistory);
        setValue('objectively', selectedTemplate.objectively);
        setValue('recommendations', selectedTemplate.recommendations);

        setValue('isClose', selectedTemplate.isClose);
    }, [selectedTemplate, setValue]);

    const savedFormDiagnosis = useMemo(() => {
        if (savedFormData && savedFormData.diagnosisId) {
            const savedDiagnosis = LocalStorageService.getItem(LocalStorageKey.SAVED_DIAGNOSIS_FORM_DATA);
            if (savedDiagnosis?.diagnosisId === savedFormData.diagnosisId) {
                return savedDiagnosis;
            }
            return undefined;
        }
    }, [savedFormData]);

    const onConfirmHandler = handleSubmit(
        (data: CheckupFormData) => {
            onConfirm(data);
            onClear();
        },
        (errors) => {
            console.log('Confirm error: ', errors, getValues());
        },
    );

    const isCloseValue = watch().isClose;

    return (
        <S.Form>
            <Controller
                name="complaints"
                control={control}
                defaultValue={selectedTemplate?.complaints || savedFormData?.complaints || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Жалобы" />;
                }}
            />

            <Controller
                name="expertAnamnesis"
                control={control}
                defaultValue={selectedTemplate?.expertAnamnesis || savedFormData?.expertAnamnesis || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Экспертный анамнез" />;
                }}
            />

            <Controller
                name="diseaseHistory"
                control={control}
                defaultValue={selectedTemplate?.diseaseHistory || savedFormData?.diseaseHistory || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="История заболевания" />;
                }}
            />

            <Controller
                name="objectively"
                control={control}
                defaultValue={selectedTemplate?.objectively || savedFormData?.objectively || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Объективно" />;
                }}
            />

            <Controller
                name="diagnosisId"
                defaultValue={selectedTemplate?.diagnosis?.diagnosisId || savedFormDiagnosis?.diagnosisId}
                control={control}
                render={({ field }) => {
                    console.log(selectedTemplate?.diagnosis || savedFormDiagnosis, field.value);

                    return (
                        <DiagnosisField
                            value={selectedTemplate?.diagnosis || savedFormDiagnosis}
                            error={errors.diagnosisId?.message}
                            onSelectCode={(diagnosis) => {
                                LocalStorageService.setItem(LocalStorageKey.SAVED_DIAGNOSIS_FORM_DATA, diagnosis);
                                field.onChange(diagnosis.diagnosisId);
                                setValue(
                                    'diagnosisDescription',
                                    diagnosis.title + (getValues().diagnosisDescription || ''),
                                );
                            }}
                        />
                    );
                }}
            />

            <Controller
                name="diagnosisDescription"
                defaultValue={selectedTemplate?.diagnosisDescription || savedFormData?.diagnosisDescription || ''}
                control={control}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Диагноз" />;
                }}
            />

            <Controller
                name="recommendations"
                control={control}
                defaultValue={selectedTemplate?.recommendations || savedFormData?.recommendations || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Рекомендации" />;
                }}
            />

            <Controller
                name="isClose"
                control={control}
                render={({ field }) => {
                    return (
                        <Switcher
                            value={field.value}
                            label="Случай закрыт"
                            onChange={(isActive) => {
                                field.onChange(isActive);
                            }}
                        />
                    );
                }}
                defaultValue={
                    selectedTemplate !== undefined
                        ? selectedTemplate.isClose
                        : savedFormData
                        ? savedFormData.isClose
                        : true
                }
            />
            {isCloseValue && (
                <>
                    <Controller
                        name="resultId"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Dropdown
                                    isDisabled={isConfirmPending}
                                    error={errors.resultId?.message}
                                    label="Результат"
                                    items={results}
                                    value={
                                        selectedTemplate?.result ||
                                        results.find((r) => r.resultId === savedFormData?.resultId)
                                    }
                                    onSelect={(result) => {
                                        field.onChange(result.resultId);
                                    }}
                                    labelSelector={(item) => item.title}
                                />
                            );
                        }}
                        defaultValue={selectedTemplate?.result?.resultId || savedFormData?.resultId}
                    />
                    <Controller
                        name="outcomeId"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Dropdown
                                    isDisabled={isConfirmPending}
                                    error={errors.outcomeId?.message}
                                    label="Исход"
                                    items={outcomes}
                                    value={
                                        selectedTemplate?.outcome ||
                                        outcomes.find((o) => o.outcomeId === savedFormData?.outcomeId)
                                    }
                                    onSelect={(outcome) => {
                                        field.onChange(outcome.outcomeId);
                                    }}
                                    labelSelector={(item) => item.title}
                                />
                            );
                        }}
                        defaultValue={selectedTemplate?.outcome?.outcomeId || savedFormData?.outcomeId}
                    />
                </>
            )}
            <Controller
                name="sendToLK"
                control={control}
                render={({ field }) => {
                    return (
                        <Switcher
                            value={savedFormData?.sendToLK || field.value}
                            label="Выгрузить в личный кабинет"
                            onChange={field.onChange}
                        />
                    );
                }}
                defaultValue={false}
            />
            {isConfirmPending ? (
                <Button disabled load={true} />
            ) : (
                <Button label="Подтвердить услугу" size="medium" onClick={onConfirmHandler} />
            )}
        </S.Form>
    );
};

export default CheckupForm;
