import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const RadioButtonContainer = styled.div<{ isActive: boolean }>`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border: 1px solid;
    flex-shrink: 0;

    background-color: ${({ isActive }) => (isActive ? Colors.Orange : Colors.White)};
    border-color: ${({ isActive }) => (isActive ? Colors.Orange : Colors.DarkGray)};

    svg {
        width: 12px;
    }
`;
