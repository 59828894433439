import * as PatientListItemStyles from './components/patient-list-item/styles';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px 25px 60px;
    flex: 1;
    overflow: auto;

    ${PatientListItemStyles.Container} {
        margin-top: 20px;

        &:last-of-type {
            margin-bottom: 20px;
        }
    }
`;
