import {
    GetPatientCardRequest,
    IPatient,
    IPatientExtended,
    GetPatientsRequest,
    GetAnalysisResultsParams,
    AnalysisResult,
} from '../models';
import Api from '../utils/api';

export class PatientApi {
    static getPatients(request: GetPatientsRequest) {
        return Api.get<IPatient[]>('/patients', { params: { ...request } });
    }

    static getPatientById(patientId: string, medDepId: string) {
        return Api.get<IPatient>(`/patients/${patientId}`, { params: { medDepId } });
    }

    static getPatientCard(patientId: string, request: GetPatientCardRequest) {
        return Api.get<IPatientExtended>(`/patients/${patientId}/card`, { params: { ...request } });
    }

    static getAnalysisResults(patientId: string, params: GetAnalysisResultsParams) {
        return Api.get<AnalysisResult[]>(`/patients/${patientId}/analysisResults`, { params });
    }

    static getAnalysisResultById(fileId: string, medDepId: string) {
        return Api.get<string>(`/patients/analysisResultFile`, { params: { fileId, medDepId } });
    }

    static getAnalysisResultFileByUrl(fileUrl: string) {
        return Api.get<Blob>(fileUrl, { responseType: 'blob' });
    }
}
