import { IUser, LoginResponse } from '../models';
import { IConfirmCodeRequest } from '../models/identity/IConfirmCodeRequest';
import { ISendCodeRequest } from '../models/identity/ISendCodeRequest';
import Api from '../utils/api';

export class UserIdentityApi {
    static login(email: string, password: string) {
        return Api.post<LoginResponse>('/auth/login', { email, password });
    }

    static logout() {
        return Api.get('/auth/logout');
    }

    static register(email: string, password: string) {
        return Api.post<IUser>('/users', { email, password });
    }

    static getMe() {
        return Api.get<IUser>('/users/me');
    }

    static sendCode(request: ISendCodeRequest) {
        return Api.post('/users/sendCode', request);
    }

    static confirmCode(request: IConfirmCodeRequest) {
        return Api.post('/users/confirm', request);
    }

    static getWaitingTimeCode(email: string) {
        return Api.get('/users/codeWaitingTime', { params: { email } });
    }

    static restorePassword(userId: string, password: string) {
        return Api.put(`/users/restore/${userId}`, { password });
    }
}
