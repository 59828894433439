import { Appointment, AxiosError, ServiceType, TemplateType } from '@ugmk/api';
import { AppointmentPageType } from 'models/appointment/appointment-page-type';
import { Action } from 'redux';

export class GetAppointmentById implements Action {
    public static readonly Name = '[AppointmentPage] GetAppointmentById';
    readonly type = GetAppointmentById.Name;
    constructor(public id: number, public medDepId: string, public pageType: AppointmentPageType) {}
}

export class GetAppointmentByIdSuccess implements Action {
    public static readonly Name = '[AppointmentPage] GetAppointmentByIdSuccess';
    readonly type = GetAppointmentByIdSuccess.Name;
    constructor(public appointment: Appointment) {}
}

export class GetAppointmentByIdError implements Action {
    public static readonly Name = '[AppointmentPage] GetAppointmentByIdError';
    readonly type = GetAppointmentByIdError.Name;
    constructor(public error: AxiosError) {}
}

export class OpenTemplateSelectionScreen implements Action {
    public static readonly Name = '[AppointmentPage] OpenTemplateSelectionScreen';
    readonly type = OpenTemplateSelectionScreen.Name;
    constructor(public serviceType: ServiceType) {}
}

export class CloseTemplateSelectionScreen implements Action {
    public static readonly Name = '[AppointmentPage] CloseTemplateSelectionScreen';
    readonly type = CloseTemplateSelectionScreen.Name;
}

export class GetTemplates implements Action {
    public static readonly Name = '[AppointmentPage] GetTemplates';
    readonly type = GetTemplates.Name;
    constructor(public serviceType: ServiceType) {}
}

export class GetTemplatesSuccess implements Action {
    public static readonly Name = '[AppointmentPage] GetTemplatesSuccess';
    readonly type = GetTemplatesSuccess.Name;
    constructor(public temapltes: TemplateType[]) {}
}

export class GetTemplatesError implements Action {
    public static readonly Name = '[AppointmentPage] GetTemplatesError';
    readonly type = GetTemplatesError.Name;
    constructor(public error: AxiosError) {}
}

export class SelectTemplate implements Action {
    public static readonly Name = '[AppointmentPage] SelectTemplate';
    readonly type = SelectTemplate.Name;
    constructor(public template: TemplateType) {}
}

export class ClearAppointmentPageStore implements Action {
    public static readonly Name = '[AppointmentPage] ClearAppointmentPageStore';
    readonly type = ClearAppointmentPageStore.Name;
}

export class ClearSelectedTemplate implements Action {
    public static readonly Name = '[AppointmentPage] ClearSelectedTemplate';
    readonly type = ClearSelectedTemplate.Name;
}

export class DeleteTamplate implements Action {
    public static readonly Name = '[AppointmentPage] DeleteTamplate';
    readonly type = DeleteTamplate.Name;
    constructor(public templateId: string, public successCb: () => void) {}
}

export class DeleteTemplateSuccess implements Action {
    public static readonly Name = '[AppointmentPage] DeleteTemplateSuccess';
    readonly type = DeleteTemplateSuccess.Name;
    constructor(public templateId: string) {}
}

export class DeleteTemplateError implements Action {
    public static readonly Name = '[AppointmentPage] DeleteTemplateError';
    readonly type = DeleteTemplateError.Name;
    constructor(public error: AxiosError) {}
}

export type AppointmentPageActions =
    | GetAppointmentById
    | GetAppointmentByIdSuccess
    | GetAppointmentByIdError
    | OpenTemplateSelectionScreen
    | CloseTemplateSelectionScreen
    | GetTemplates
    | GetTemplatesSuccess
    | GetTemplatesError
    | SelectTemplate
    | DeleteTamplate
    | DeleteTemplateSuccess
    | DeleteTemplateError
    | ClearAppointmentPageStore
    | ClearSelectedTemplate;
