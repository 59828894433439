import { IPatient, IPatientExtended } from '@ugmk/api';
import PatientCardResultsFilters from 'models/patient/PatientCardResultsFilters';
import PatientFilters from 'models/patient/PatientFilter';
import { Action } from 'redux';

export class GetPatientsAction implements Action {
    public static readonly Name = '[Patients] Get Patients';
    readonly type = GetPatientsAction.Name;
    constructor(public medDepId: string) {}
}

export class GetPatientsSuccessAction implements Action {
    public static readonly Name = '[Patients] Get Patients Success';
    readonly type = GetPatientsSuccessAction.Name;
    constructor(public patients: IPatient[]) {}
}

export class GetPatientsErrorAction implements Action {
    public static readonly Name = '[Patients] Get Patients Error';
    readonly type = GetPatientsErrorAction.Name;
    constructor(public error: any) {}
}

export class LoadNextPagePatientsAction implements Action {
    public static readonly Name = '[Patients] Load Next Page Patients';
    readonly type = LoadNextPagePatientsAction.Name;
    constructor(public medDepId: string) {}
}

export class LoadNextPagePatientsSuccessAction implements Action {
    public static readonly Name = '[Patients] Load Next Page Patients Success';
    readonly type = LoadNextPagePatientsSuccessAction.Name;
    constructor(public patients: IPatient[]) {}
}

export class SetPatientFiltersAction implements Action {
    public static readonly Name = '[Patients] Set Patient Filters';
    readonly type = SetPatientFiltersAction.Name;
    constructor(public filters: Partial<PatientFilters>, public medDepId: string) {}
}

export class GetPatientCardAction implements Action {
    public static readonly Name = '[Patients] Get Patient Card';
    readonly type = GetPatientCardAction.Name;
    constructor(public medDepId: string) {}
}

export class GetPatientCardForModalAction implements Action {
    public static readonly Name = '[Patients] Get Patient Card For Modal';
    readonly type = GetPatientCardForModalAction.Name;
    constructor(public filters: Partial<PatientCardResultsFilters>, public medDepId: string) {}
}

export class GetPatientCardSuccessAction implements Action {
    public static readonly Name = '[Patients] Get Patient Card Success';
    readonly type = GetPatientCardSuccessAction.Name;
    constructor(public card: IPatientExtended) {}
}

export class GetPatientCardErrorAction implements Action {
    public static readonly Name = '[Patients] Get Patient Card Error';
    readonly type = GetPatientCardErrorAction.Name;
    constructor(public error: any) {}
}

export class SetCardResultsFiltersAction implements Action {
    public static readonly Name = '[Patients] Set Card Results Filters';
    readonly type = SetCardResultsFiltersAction.Name;
    constructor(public filters: Partial<PatientCardResultsFilters>, public medDepId: string) {}
}

export class LoadNextPageResultsPatientCardAction implements Action {
    public static readonly Name = '[Patients] Load Next Page Results Patient Card';
    readonly type = LoadNextPageResultsPatientCardAction.Name;
    constructor(public medDepId: string) {}
}

export class LoadNextPageResultsPatientCardSuccessAction implements Action {
    public static readonly Name = '[Patients] Load Next Page Results Patient Card Success';
    readonly type = LoadNextPageResultsPatientCardSuccessAction.Name;
    constructor(public card: IPatientExtended) {}
}

export class ClearPageResultsPatientCardAction implements Action {
    public static readonly Name = '[Patients] Clear Page Results Patient Card';
    readonly type = ClearPageResultsPatientCardAction.Name;
}

export type PatientsActions =
    | GetPatientsAction
    | GetPatientsSuccessAction
    | GetPatientsErrorAction
    | LoadNextPagePatientsAction
    | LoadNextPagePatientsSuccessAction
    | SetPatientFiltersAction
    | GetPatientCardAction
    | GetPatientCardSuccessAction
    | GetPatientCardErrorAction
    | SetCardResultsFiltersAction
    | LoadNextPageResultsPatientCardAction
    | LoadNextPageResultsPatientCardSuccessAction
    | ClearPageResultsPatientCardAction
    | GetPatientCardForModalAction;
