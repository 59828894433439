import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { ModalContainer, Overlay } from './styles';

interface Props {
    isOpen: boolean;
    hideOnOverlayClicked?: boolean;
    overlayTheme?: 'dark' | 'light';
    styles?: React.CSSProperties;
    onHide?: () => void;
}

const ModalBase: React.FC<PropsWithChildren<Props>> = (props) => {
    const { overlayTheme = 'light' } = props;
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.body.style.overflow = props.isOpen ? 'hidden' : 'auto';
        if (!props.isOpen) {
            setTimeout(() => {
                ref.current?.style.setProperty('display', 'none');
            }, 300);
        } else {
            ref.current?.style.setProperty('display', 'block');
        }
    }, [props.isOpen]);

    return (
        <Overlay
            ref={ref}
            style={{ backgroundColor: overlayTheme === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)' }}
            active={props.isOpen}
            onClick={() => props.hideOnOverlayClicked && !!props.onHide && props.onHide()}
        >
            <ModalContainer onClick={stopPropagation} active={props.isOpen} style={props.styles}>
                {props.children}
            </ModalContainer>
        </Overlay>
    );
};

const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
};

ModalBase.defaultProps = {
    isOpen: false,
    hideOnOverlayClicked: true,
};

export default ModalBase;
