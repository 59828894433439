import * as React from 'react';
import Icon from '../icon';
import { IconType } from '../icon/types';
import * as S from './button.styles';
import { ButtonContainerProps } from './button.types';
import Loader from "../Loader";

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface Props {
    label?: string;
    icon?: IconType;
    load?: boolean
}
/**
 *
 * @param label - `required`
 * @param icon - `IconType`
 * @param theme - 'filled' | 'ghost'
 * @param size - 'big' | 'medium' | 'small'
 * @param side - 'right' | 'left'
 * @param HTMLButtonElement
 * @returns
 */
const Button: React.FC<Props & ButtonContainerProps & HTMLButtonProps> = (props) => {
    return (
        <S.Container {...props}>
            {!!props.icon && <S.IconContainer as={Icon} type={props.icon} />}
            {!!props.load && <Loader/>}
            {props.label}
        </S.Container>
    );
};

Button.defaultProps = {
    theme: 'filled',
    size: 'big',
    side: 'left',
};

export { Button };
