import React from 'react';
import { AppointmentFilterType, ServiceType } from '@ugmk/api';
import ServiceHeader from './components/ServiceHeader';
import ScheduleHeader from './components/ScheduleHeader';
import * as S from './appointment-card.styles';

type Props = {
    patientName: string;
    yearBirth: string | null;
    appointmentTime: string | null;
    procedureType: string;
    appointmentId: number;
    quantity: number;
    patientId: string;
    serviceType: ServiceType;
    filterType: AppointmentFilterType;
    comment?: string;
    onClick: () => void;
};

const AppointmentCard: React.FC<Props> = React.memo((props) => {
    const Header =
        props.filterType === AppointmentFilterType.Service ? (
            <ServiceHeader
                patientName={props.patientName}
                yearBirth={props.yearBirth}
                procedureType={props.procedureType}
                quantity={props.quantity}
                comment={props.comment}
            />
        ) : (
            <ScheduleHeader
                patientName={props.patientName}
                procedureType={props.procedureType}
                quantity={props.quantity}
                appointmentTime={props.appointmentTime}
                comment={props.comment}
            />
        );

    return <S.Container onClick={props.onClick}>{Header}</S.Container>;
});

export { AppointmentCard };
