import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppState } from 'state';
import { Colors } from 'styles/colors';
import * as S from './diagnosis-search-screen.styles';
import { InputText } from 'components/base/input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/root.reducer';
import DiagnosisItem from './components/diagnosis-item/diagnosis-item.component';
import { GetDiagnosesByParent, GetDiagnosesBySearchString } from 'store/handbooks/handbooks.actions';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import PreContentLayout from 'components/layouts/PreContentLayout';
import { Diagnosis } from '@ugmk/api';
import { debounce } from 'lodash';
import { Button } from 'components/base/button';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import { EmptyState } from 'components/base/empty-state/empty-state.component';

export const DiagnosisSearchScreen: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const isLoading = useSelector((root: RootState) => root.handbooks.loading.diagnoses);
    const diagnoses = useSelector((root: RootState) => root.handbooks.diagnoses);
    const { isDiagnosisScreenOpen, closeDiagnosisScreen, setDiagnosisToCard } = useAppState();
    const [parentDiagnosis, setParentDiagnosis] = useState<Diagnosis | null>(null);
    const [historyMoves, setHistoryMoves] = useState<Diagnosis[]>([]);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState<Diagnosis | null>(null);
    const [emptySearchInput, setEmptySearchInput] = useState<boolean>(true);

    const screenRef = useRef<HTMLDivElement | null>(null);
    useOutsideClickHandler(screenRef, closeDiagnosisScreen);
    const isEmpty = useMemo(() => diagnoses.length === 0, [diagnoses.length]);

    const searchByParent = useCallback(
        (parentId?: string) => {
            if (!currentStaffUnit) return;

            dispatch({ ...new GetDiagnosesByParent(currentStaffUnit.medDepId, parentId || '0') });
        },
        [dispatch, currentStaffUnit],
    );

    const searchByString = useCallback(
        (searchString: string) => {
            return debounce(() => {
                if (!currentStaffUnit) return;

                dispatch({ ...new GetDiagnosesBySearchString(currentStaffUnit.medDepId, searchString) });
            }, 150)();
        },
        [dispatch, currentStaffUnit],
    );

    useEffect(() => {
        if (isDiagnosisScreenOpen) {
            searchByParent(parentDiagnosis?.diagnosisId.toString());
        } else {
            searchByString('');
            setParentDiagnosis(null);
            setHistoryMoves([]);
        }
    }, [dispatch, searchByParent, searchByString, parentDiagnosis, isDiagnosisScreenOpen]);

    const backButtonText: string = useMemo(() => {
        if (historyMoves.length === 0) {
            return 'Вернуться к карточке';
        } else if (historyMoves.length === 1) {
            return 'Вернуться к классам МКБ-10';
        } else {
            return `Вернуться к ${historyMoves[historyMoves.length - 1]?.code}`;
        }
    }, [historyMoves]);

    const backAction = () => {
        setSelectedDiagnosis(null);
        if (historyMoves.length === 1) {
            setHistoryMoves([]);
            setParentDiagnosis(null);
        } else if (historyMoves.length > 1) {
            setParentDiagnosis(historyMoves[historyMoves.length - 2]);
            setHistoryMoves((prevHistory) => {
                return prevHistory.slice(0, prevHistory.length - 1);
            });
        } else {
            closeDiagnosisScreen();
        }
    };

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = event.target.value;
        if (searchString.length > 0) {
            searchByString(searchString);
            setEmptySearchInput(false);
            setHistoryMoves([]);
            setParentDiagnosis(null);
        } else {
            setEmptySearchInput(true);
            searchByParent();
        }
    };

    return (
        <S.DiagnosisSearchScreenContainer ref={screenRef} active={isDiagnosisScreenOpen}>
            <S.Header>
                <S.BackButton onClick={() => backAction()}>
                    <Icon type={IconType.Arrow} />
                    <Typography color={Colors.Orange} size={14} marginBottom={0} weight="medium" type="span">
                        {backButtonText}
                    </Typography>
                </S.BackButton>
                <InputText
                    onChange={onSearch}
                    icon={IconType.Search}
                    label="Код или название диагноза"
                    isClear={true}
                    onClearHandle={() => {
                        setHistoryMoves([]);
                        setParentDiagnosis(null);
                        searchByParent();
                    }}
                />
            </S.Header>
            <S.DiagnosisList isDiagnosisSelected={!!selectedDiagnosis}>
                <PreContentLayout isLoading={isLoading}>
                    {diagnoses.map((diagnosis) => {
                        return (
                            <DiagnosisItem
                                key={diagnosis.diagnosisId}
                                diagnosis={diagnosis}
                                isActive={diagnosis.diagnosisId === selectedDiagnosis?.diagnosisId}
                                onClick={() => {
                                    if (diagnosis.forbidden) {
                                        setSelectedDiagnosis(null);
                                        setParentDiagnosis(diagnosis);
                                        setHistoryMoves((prev) => [...prev, diagnosis]);
                                    } else {
                                        setSelectedDiagnosis(diagnosis);
                                    }
                                }}
                            />
                        );
                    })}

                    {isEmpty && emptySearchInput && (
                        <EmptyState icon={IconType.Search} title="Пожалуйста введите код или название диагноза" />
                    )}

                    {isEmpty && !emptySearchInput && (
                        <EmptyState
                            icon={IconType.Search}
                            title="Мы ничего не нашли..."
                            description="Возможно стоит изменить параметры поиска"
                        />
                    )}

                    {selectedDiagnosis && (
                        <S.FloatingButton>
                            <Button
                                label="Подтвердить диагноз"
                                size="medium"
                                onClick={() => {
                                    setDiagnosisToCard(selectedDiagnosis);
                                    setSelectedDiagnosis(null);
                                    closeDiagnosisScreen();
                                }}
                            />
                        </S.FloatingButton>
                    )}
                </PreContentLayout>
            </S.DiagnosisList>
        </S.DiagnosisSearchScreenContainer>
    );
});
