import { Expander } from 'components/base/expander';
import { IconType } from 'components/base/icon/types';
import Loader from 'components/base/Loader';
import Typography from 'components/base/Typography';
import MainLayout from 'components/layouts/MainLayout';
import { MainLayoutContext } from 'components/layouts/MainLayout/state/MainLayoutProvider';
import PatientCardResultsSearchForm from 'components/partial/patient-card-results-search-form';
import PatientResultCardFeed from 'pages/main-app/patient-card/components/patient-result-card-feed';
import { convertDateToDDMMYYYY } from 'helpers';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppRoutes } from 'router/routes';
import { ClearPageResultsPatientCardAction, SetCardResultsFiltersAction } from 'store/patients/patients.actions';
import { RootState } from 'store/root.reducer';
import SaveAsTemplateModal from './components/save-as-template-modal/save-as-template-modal.component';
import * as S from './styles';
import Icon from 'components/base/icon';
import { ServiceType } from '@ugmk/api';
import { CheckupFormData } from '../appointment/components/appointment-form/checkup-form/checkup-form.component';
import { LFKFormData } from '../appointment/components/appointment-form/lfk-form/lfk-form.component';
import { useLocationState } from 'hooks/use-location-state';
import { usePatientById } from './hooks/use-patient-by-id';

export interface PatientCardPageLocationState {
    fromAppointment: boolean;
    serviceType: ServiceType;
    oldAppointmentState: CheckupFormData | LFKFormData | null;
}

type PatientCardPageRouteParams = Record<'id', string>;

const PatientCardPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locationState = useLocationState<PatientCardPageLocationState>();

    const routeParams = useParams<PatientCardPageRouteParams>();
    const patientId = routeParams.id;

    const currentMedDepId = useSelector((state: RootState) => state.auth.currentStaffUnit?.medDepId);
    const isLoadingCard = useSelector((state: RootState) => state.patient.isLoadingCard);
    const patientCard = useSelector((state: RootState) => state.patient.patientCard);
    const { loadingPatient, patient } = usePatientById(patientId || null);

    const backButtonOptions = useMemo(() => {
        if (locationState?.fromAppointment) {
            return {
                title: 'Назад к карточке',
                onClick: () => navigate(-1),
            };
        } else {
            return {
                title: 'Вернуться к поиску пациентов',
                onClick: () => navigate(AppRoutes.PatientList),
            };
        }
    }, [locationState, navigate]);

    useEffect(() => {
        if (patientId && currentMedDepId) {
            dispatch({
                ...new SetCardResultsFiltersAction(
                    {
                        patientId,
                    },
                    currentMedDepId,
                ),
            });
        }
    }, [dispatch, patientId, currentMedDepId]);

    useEffect(() => {
        return () => {
            dispatch({ ...new ClearPageResultsPatientCardAction() });
        };
    }, [dispatch]);

    if (isLoadingCard || loadingPatient) {
        return <Loader />;
    }

    return (
        <MainLayout>
            <MainLayout.Header>
                <S.PatientCardHeader>
                    <S.BackButton onClick={backButtonOptions.onClick}>
                        <S.BackButtonIcon as={Icon} type={IconType.Arrow} />
                        {backButtonOptions.title}
                    </S.BackButton>
                    {patientCard && (
                        <React.Fragment>
                            {patient && (
                                <S.PatientCardHeaderRow>
                                    <Typography type="h3" size={17} weight="medium" marginBottom={0} textAlign="left">
                                        {patient.surname} {patient.name} {patient.patronymic[0]}.
                                    </Typography>
                                    <Typography type="span" size={14} weight="regular" marginBottom={0}>
                                        ({patient.birthYear} г.)
                                    </Typography>
                                </S.PatientCardHeaderRow>
                            )}
                            {patientCard.diagnosticResults[0] && (
                                <Typography type="span" size={14} marginBottom={20} textAlign="left">
                                    Последнее посещение:{' '}
                                    {convertDateToDDMMYYYY(patientCard.diagnosticResults[0].dateConsultation)}
                                </Typography>
                            )}

                            <MainLayoutContext.Consumer>
                                {({ headerActive, setHeaderActive }) => (
                                    <Expander isActive={headerActive}>
                                        {currentMedDepId && (
                                            <PatientCardResultsSearchForm
                                                currentMedDepId={currentMedDepId}
                                                onAfterSearch={() => setHeaderActive(false)}
                                            />
                                        )}
                                    </Expander>
                                )}
                            </MainLayoutContext.Consumer>
                        </React.Fragment>
                    )}
                </S.PatientCardHeader>
            </MainLayout.Header>
            <PatientResultCardFeed
                patientId={patientId}
                medDepId={currentMedDepId}
                fromAppointmentState={locationState}
            />
            <MainLayout.MenuNavigation />
            <SaveAsTemplateModal />
        </MainLayout>
    );
};

export default PatientCardPage;
