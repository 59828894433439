import { AnalysisResult } from '@ugmk/api';
import { Action } from 'redux';

export class GetAnalysisResults implements Action {
    public static readonly Name = '[Patient Analyzes Page] GetAnalysisResults';
    readonly type = GetAnalysisResults.Name;
    constructor(public patientId: string) {}
}

export class GetAnalysisResultsSuccess implements Action {
    public static readonly Name = '[Patient Analyzes Page] GetAnalysisResultsSuccess';
    readonly type = GetAnalysisResultsSuccess.Name;
    constructor(public data: AnalysisResult[]) {}
}

export class GetAnalysisResultsError implements Action {
    public static readonly Name = '[Patient Analyzes Page] GetAnalysisResultsError';
    readonly type = GetAnalysisResultsError.Name;
    constructor(public error: string) {}
}

export class LoadNewPageAnalysisResults implements Action {
    public static readonly Name = '[Patient Analyzes Page] LoadNewPageAnalysisResults';
    readonly type = LoadNewPageAnalysisResults.Name;
    constructor(public patientId: string) {}
}

export class LoadNewPageAnalysisResultsSuccess implements Action {
    public static readonly Name = '[Patient Analyzes Page] LoadNewPageAnalysisResultsSuccess';
    readonly type = LoadNewPageAnalysisResultsSuccess.Name;
    constructor(public data: AnalysisResult[]) {}
}

export class LoadNewPageAnalysisResultsError implements Action {
    public static readonly Name = '[Patient Analyzes Page] LoadNewPageAnalysisResultsError';
    readonly type = LoadNewPageAnalysisResults.Name;
    constructor(public error: string) {}
}

export class GetAnalysisFileById implements Action {
    public static readonly Name = '[Patient Analyzes Page] GetAnalysisFileById';
    readonly type = GetAnalysisFileById.Name;
    constructor(public fileId: string) {}
}

export class GetAnalysisFileByIdSuccess implements Action {
    public static readonly Name = '[Patient Analyzes Page] GetAnalysisFileByIdSuccess';
    readonly type = GetAnalysisFileByIdSuccess.Name;
    constructor(public file: Blob) {}
}

export class GetAnalysisFileByIdError implements Action {
    public static readonly Name = '[Patient Analyzes Page] GetAnalysisFileByIdError';
    readonly type = GetAnalysisFileByIdError.Name;
    constructor(public error: string) {}
}

export type PatientAnalyzesPageActions =
    | GetAnalysisResults
    | GetAnalysisResultsSuccess
    | GetAnalysisResultsError
    | GetAnalysisFileById
    | GetAnalysisFileByIdSuccess
    | GetAnalysisFileByIdError
    | LoadNewPageAnalysisResults
    | LoadNewPageAnalysisResultsSuccess
    | LoadNewPageAnalysisResultsError;
