import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import {
    ErrorLabel,
    InputContainer,
    InputLabel,
    RightIconContainer,
    TextareaContainer,
} from 'components/base/input/styles';
import React, { useEffect, useRef, useState } from 'react';

type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

interface Props {
    label: string;
    isClear?: boolean;
    error?: string;
    icon?: IconType;
    value?: string;
    onClearHandle?: () => void;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, Props & TextareaProps>(
    (props: Props & TextareaProps, ref) => {
        const [isActive, setActive] = useState(false);
        const [isFilled, setFilled] = useState(false);
        const textareaRef = useRef<HTMLTextAreaElement>();


        const autoResize = () => {
            textareaRef.current!.style.height = 'auto';
            textareaRef.current!.style.height = textareaRef.current?.scrollHeight + 'px';
        };

        useEffect(() => {
            if (!!props.defaultValue || !!props.value) {
                setFilled(true);
            } else {
                setFilled(false);
            }
            setTimeout(() => {
                autoResize();
            }, 50);
        }, [props.defaultValue, props.value]);

        useEffect(() => {
            const onFocusHandler = () => {
                setActive(true);
                autoResize();
            };
            const onBlurHandler = () => {
                setActive(false);
            };
            const onChangeHandler = () => {
                setFilled(!!textareaRef.current?.value);
                autoResize();
            };

            textareaRef.current?.addEventListener('focus', onFocusHandler);
            textareaRef.current?.addEventListener('blur', onBlurHandler);
            textareaRef.current?.addEventListener('change', onChangeHandler);
            //textareaRef.current?.addEventListener('input', onChangeHandler);

            return () => {
                textareaRef.current?.removeEventListener('focus', onFocusHandler);
                textareaRef.current?.removeEventListener('blur', onBlurHandler);
                textareaRef.current?.removeEventListener('change', onChangeHandler);
                //textareaRef.current?.removeEventListener('input', onChangeHandler);
            };
        }, [textareaRef]);

        const onClear = () => {
            textareaRef.current!.value = '';
            setFilled(false);
            textareaRef.current?.focus();
            props.onClearHandle && props.onClearHandle();
        };

        return (
            <InputContainer>
                <InputLabel
                    onClick={() => textareaRef.current?.focus()}
                    error={!!props.error}
                    filled={isFilled}
                    active={isActive}
                    htmlFor={props.id}
                >
                    {props.label}
                </InputLabel>
                <TextareaContainer
                    active={isActive}
                    error={!!props.error}
                    autoComplete="off"
                    value={props.value}
                    {...(props as TextareaProps)}
                    onChange={(e) => {
                        setFilled(!!e.target.value);
                        autoResize();
                        props.onChange && props.onChange(e);
                    }}
                    ref={(node: HTMLTextAreaElement) => {
                        textareaRef.current = node;
                        if (typeof ref === 'function') {
                            ref(node);
                        } else if (ref) {
                            (ref as React.MutableRefObject<HTMLTextAreaElement>).current = node;
                        }
                    }}
                />

                <RightIconContainer>
                    {!isFilled && !!props.icon && <Icon type={props.icon} />}
                    {isFilled && !!props.isClear && <Icon type={IconType.Close} onClick={onClear} />}
                </RightIconContainer>
                {props.error && <ErrorLabel>{props.error}</ErrorLabel>}
            </InputContainer>
        );
    },
);
