import styled, { css } from 'styled-components';
import { Colors } from 'styles/colors';

export const InputContainer = styled.div`
    position: relative;
`;

export const InputLabel = styled.label<{ active: boolean; filled: boolean; error: boolean }>`
    position: absolute;
    left: 22px;
    user-select: none;
    transition: font-size 0.3s, top 0.3s;
    font-weight: ${({ active, filled }) => (active || filled ? 500 : 400)};
    top: ${({ active, filled }) => (active || filled ? '11px' : '19px')};
    color: ${({ active, error }) => getColorState(active, error, Colors.DarkGray)};
    font-size: ${({ active, filled }) => (active || filled ? '11px' : '16px')};
`;

const InputStyles = css`
    border: 0;
    background-color: ${Colors.DarkWhite};
    height: 58px;
    border-radius: 15px;
    width: 100%;
    padding: 26px 50px 12px 22px;
    font-size: 16px;
    &::-webkit-clear-button,
    &::-moz-clear-button,
    &::-o-clear-button,
    &::-ms-clear-button,
    &::-webkit-inner-spin-button,
    &::-moz-inner-spin-button,
    &::-o-inner-spin-button,
    &::-ms-inner-spin-button {
        display: none;
    }
`;

export const TextInputContainer = styled.input<{ active: boolean; error: boolean }>`
    ${InputStyles};
    transition: border-color 0.3s;
    border: 1.5px solid ${({ active, error }) => getColorState(active, error, Colors.DarkWhite)};
`;

export const TextareaContainer = styled.textarea<{ active: boolean; error: boolean }>`
    ${InputStyles};
    height: 76px;
    transition: border-color 0.3s, height 0.3s;
    border: 1.5px solid ${({ active, error }) => getColorState(active, error, Colors.DarkWhite)};
    resize: none;
    overflow: hidden;
`;

export const RightIconContainer = styled.div`
    position: absolute;
    top: 21px;
    right: 21px;
    width: 16px;
    height: 16px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const ErrorLabel = styled.div`
    color: ${Colors.Red};
    font-size: 11px;
    font-weight: 500;
    width: 100%;
    padding: 9px 15px 0;
`;

function getColorState(active: boolean, error: boolean, defaultColor: string) {
    if (error) return Colors.Red;
    if (active) return Colors.DarkBlue;
    return defaultColor;
}
