import React from 'react';
import Icon from '../icon';
import { IconType } from '../icon/types';
import { RadioButtonContainer } from './styles';

interface Props {
    isActive: boolean;
}

const RadioButton: React.FC<Props> = ({ isActive }) => {
    return (
        <RadioButtonContainer isActive={isActive}>{isActive && <Icon type={IconType.Check} />}</RadioButtonContainer>
    );
};

export default RadioButton;
