import styled from 'styled-components';
import { Colors } from 'styles/colors';

export namespace BackButtonStyles {
    export const Icon = styled.div`
        margin-right: 12px;
        transform: rotate(180deg);
    `;
    export const Container = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${Colors.Orange};
        font-weight: 500;

        ${Icon} {
            margin-right: 12px;
        }
    `;
}
