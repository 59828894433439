import React from 'react';
import ReactDOM from 'react-dom/client';
import RootRouter from './router';
import { Provider } from 'react-redux';
import './assets/styles/init-fonts.css';
import store, { history } from './store';
import { GlobalStyles } from './styles/global-styles';
import AppStateProvider from 'state';
import InfoModal from 'components/partial/info-modal';
import { AxiosError, initAxios } from '@ugmk/api';
import { AuthErrorAction } from 'store/auth/auth.actions';
import reportWebVitals from 'reportWebVitals';
import { ReduxRouter } from '@lagunovsky/redux-react-router';

initAxios((window as any)._env_.API_DEV_LOCAL_URL, (error: AxiosError) => {
    if (401 === error.response?.status) {
        store.dispatch({ ...new AuthErrorAction() });
    }
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <GlobalStyles />
            <AppStateProvider>
                <ReduxRouter store={store} history={history}>
                    <RootRouter />
                    <InfoModal />
                </ReduxRouter>
            </AppStateProvider>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
