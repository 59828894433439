import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import {
    ErrorLabel,
    InputContainer,
    InputLabel,
    RightIconContainer,
    TextInputContainer,
} from 'components/base/input/styles';
import React, { useEffect, useRef, useState } from 'react';
import useShowPassword from '../hooks/useShowPassword';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface Props {
    label: string;
    isClear?: boolean;
    error?: string;
    icon?: IconType;
    onClearHandle?: () => void;
}

export const InputText = React.forwardRef<HTMLInputElement, Props & InputProps>((props: Props & InputProps, ref) => {
    const [isActive, setActive] = useState(false);
    const [isFilled, setFilled] = useState(false);
    const inputRef = useRef<HTMLInputElement>();
    const { getInputType, onShowPassword } = useShowPassword(props.type, () => inputRef.current?.focus());

    useEffect(() => {
        const onFocusHandler = () => {
            setActive(true);
        };
        const onBlurHandler = () => {
            setActive(false);
        };
        const onChangeHandler = () => {
            if (props.type !== 'date') setFilled(!!inputRef.current?.value);
        };

        inputRef.current?.addEventListener('focus', onFocusHandler);
        inputRef.current?.addEventListener('blur', onBlurHandler);
        inputRef.current?.addEventListener('change', onChangeHandler);
        inputRef.current?.addEventListener('input', onChangeHandler);

        return () => {
            inputRef.current?.removeEventListener('focus', onFocusHandler);
            inputRef.current?.removeEventListener('blur', onBlurHandler);
            inputRef.current?.removeEventListener('change', onChangeHandler);
            inputRef.current?.removeEventListener('input', onChangeHandler);
        };
    }, [inputRef, props.type]);

    useEffect(() => {
        if (!!props.defaultValue) {
            setFilled(true);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        if (props.type === 'date') {
            setFilled(true);
        }
    }, [props.type]);

    const onClear = () => {
        inputRef.current!.value = '';
        if (props.type !== 'date') setFilled(false);
        inputRef.current?.focus();
        props.onClearHandle && props.onClearHandle();
    };

    return (
        <InputContainer>
            <InputLabel
                onClick={() => inputRef.current?.focus()}
                error={!!props.error}
                filled={isFilled}
                active={isActive}
                htmlFor={props.id}
            >
                {props.label}
            </InputLabel>
            <TextInputContainer
                active={isActive}
                error={!!props.error}
                autoComplete="off"
                {...(props as InputProps)}
                type={getInputType()}
                ref={(node: HTMLInputElement) => {
                    inputRef.current = node;
                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        (ref as React.MutableRefObject<HTMLInputElement>).current = node;
                    }
                }}
            />

            <RightIconContainer>
                {!isFilled && !!props.icon && <Icon type={props.icon} />}
                {isFilled && !!props.isClear && <Icon type={IconType.Close} onClick={onClear} />}
                {isFilled && props.type === 'password' && <Icon type={IconType.Eye} onClick={onShowPassword} />}
            </RightIconContainer>
            {props.error && <ErrorLabel>{props.error}</ErrorLabel>}
        </InputContainer>
    );
});
