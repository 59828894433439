import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    overflow-y: auto;
    padding: 20px 25px 0;
`;

export const Content = styled.div`
    background-color: ${Colors.White};
    border-radius: 15px;
    padding: 20px 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
        margin-bottom: 15px;
    }
`;
