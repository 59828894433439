import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { getBackgroundColor, getContentColor, getHeight } from './button.helper';
import { ButtonContainerProps } from './button.types';

export const IconContainer = styled.div`
    width: 20px;
    height: 20px;
`;

export const Container = styled.button<ButtonContainerProps>`
    position: relative;
    display: flex;
    flex-direction: ${({ side }) => (side === 'left' ? 'row' : 'row-reverse')};
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 100%;
    background-color: ${getBackgroundColor};
    border: 1px solid ${({ disabled }) => (!!disabled ? Colors.Gray : Colors.Orange)};
    color: ${getContentColor};
    font-size: ${({ size }) => (size === 'small' ? '14px' : '15px')};
    font-weight: ${({ size }) => (size === 'small' ? 500 : 600)};
    height: ${getHeight};
    padding: 0;
    box-sizing: border-box;

    ${IconContainer} {
        margin: 0 ${({ size }) => (size === 'small' ? '5px' : '10px')};

        svg path {
            fill: ${getContentColor};
        }
    }
`;
