import { Button } from 'components/base/button';
import { IconType } from 'components/base/icon/types';
import React, { useState } from 'react';
import { AppointmentSearchFormContainer } from './appointment-search-form.styles';
import { InputText } from 'components/base/input';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/root.reducer';
import { IAppointmentType, ServiceType } from '@ugmk/api';
import { Dropdown } from 'components/base/dropdown';
import { SetAppointmentFiltersAction } from 'store/appointments/appointments.actions';
import AppointmentFilters from 'models/appointment/AppointmentFilters';
import { debounce } from 'lodash';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import moment from 'moment';
import ServiceTypeFilter from '../service-type-filter';
import { AppointmentSearchFormField } from './appointment-search-form.types';

interface Props {
    onAfterSearch?: () => void;
    fields: AppointmentSearchFormField;
}

export const AppointmentSearchForm: React.FC<Props> = React.memo(({ fields, onAfterSearch }) => {
    const dispatch = useDispatch();
    const appointmentTypes = useSelector((state: RootState) => state.appointments.appointmentTypes);
    const appointmentFilters = useSelector((state: RootState) => state.appointments.filters);
    const [headerFilters, setHeaderFilters] = useState<Partial<AppointmentFilters>>();
    const currentStaffUnit = useSelector(currentStaffUnitSelector);

    const defaultDate = appointmentFilters.dateFilter && moment(appointmentFilters.dateFilter).format('YYYY-MM-DD');

    const changeFilters = debounce((filters: Partial<AppointmentFilters>) => {
        setHeaderFilters((prevState) => ({ ...prevState, ...filters }));
    }, 150);

    const onFullNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            changeFilters({ searchName: event.target.value });
        }
    };

    const onNotationTypeChangeHandler = (notationType: IAppointmentType) => {
        setHeaderFilters((prevState) => ({ ...prevState, type: notationType }));
    };

    const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        if (event.target) {
            setHeaderFilters((prevState) => ({ ...prevState, dateFilter: event.target.value }));
        }
    };

    const onServiceTypeChange = (serviceType: ServiceType) => {
        setHeaderFilters((prevState) => ({ ...prevState, serviceType: serviceType }));
    };

    const onSearch = () => {
        if (currentStaffUnit) {
            dispatch({ ...new SetAppointmentFiltersAction({ ...headerFilters }, currentStaffUnit) });
        }
        onAfterSearch && onAfterSearch();
    };

    return (
        <AppointmentSearchFormContainer>
            {fields.searchByName && (
                <InputText
                    icon={IconType.Search}
                    isClear={true}
                    defaultValue={appointmentFilters.searchName}
                    label="Поиск по ФИО или услуге"
                    onChange={onFullNameChangeHandler}
                    onClearHandle={() => changeFilters({ searchName: '' })}
                />
            )}
            {fields.appointmentType && (
                <Dropdown<IAppointmentType>
                    icon={IconType.List}
                    value={headerFilters?.type || appointmentFilters.type}
                    label="Тип записи"
                    items={appointmentTypes}
                    labelSelector={(type: IAppointmentType) => type.name}
                    onSelect={onNotationTypeChangeHandler}
                />
            )}
            {fields.calendar && (
                <InputText
                    icon={IconType.Calendar}
                    defaultValue={defaultDate}
                    onChange={onDateChange}
                    type="date"
                    label="Дата записи"
                    isClear={true}
                    onClearHandle={() => changeFilters({ dateFilter: undefined })}
                />
            )}
            {fields.serviceType && (
                <ServiceTypeFilter
                    onChange={onServiceTypeChange}
                    value={
                        headerFilters?.serviceType !== undefined
                            ? headerFilters.serviceType
                            : appointmentFilters.serviceType
                    }
                />
            )}
            <Button label="Найти записи" onClick={onSearch} />
        </AppointmentSearchFormContainer>
    );
});
