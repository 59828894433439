import { DiagnosisSearchScreen } from 'components/partial/diagnosis-search-screen';
import { AppointmentPageType } from 'models/appointment/appointment-page-type';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import AppointmentPage from './appointment/appointment.component';
import LinkLoginPage from './link-login/link-login.component';
import PatientAnalyzesPage from './patient-analyzes/patient-analyzes.component';
import PatientCardPage from './patient-card';
import { PatientListPage } from './patient-list';
import PatientPage from './patient/patient.component';
import { SchedulePage } from './schedule';
import { ServicePage } from './service';
import DocumentPage from "./document-page/document-page.component";

const MainApp: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path={'/'} element={<Navigate to={AppRoutes.Schedule} replace={true} />} />
                <Route path={AppRoutes.Service} element={<ServicePage />} />
                <Route path={AppRoutes.Schedule} element={<SchedulePage />} />

                <Route path={AppRoutes.PatientList} element={<PatientListPage />} />
                <Route path={AppRoutes.Patient} element={<PatientPage />} />
                <Route path={AppRoutes.PatientCard} element={<PatientCardPage />} />
                <Route path={AppRoutes.PatientAnalyzesList} element={<PatientAnalyzesPage />} />

                <Route
                    path={AppRoutes.AppointmentSchedule}
                    element={<AppointmentPage pageType={AppointmentPageType.Schedule} />}
                />
                <Route
                    path={AppRoutes.AppointmentService}
                    element={<AppointmentPage pageType={AppointmentPageType.Service} />}
                />
                <Route path={AppRoutes.Login} element={<LinkLoginPage />} />
                <Route path={AppRoutes.PatientDocument} element={<DocumentPage />}/>
            </Routes>
            <DiagnosisSearchScreen />
        </>
    );
};

export default MainApp;
