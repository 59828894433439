import { AppointmentFilterType, Appointment } from '@ugmk/api';
import PreContentLayout from 'components/layouts/PreContentLayout';
import { getPatientCardName } from 'helpers/patient';
import usePaginateByScroll from 'hooks/usePaginateByScroll';
import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import {
    ClearAppointmentsAction,
    LoadNextPageAppointmentsAction,
    SetAppointmentFiltersAction,
} from 'store/appointments/appointments.actions';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { RootState } from 'store/root.reducer';
import { AppointmentCard } from '../appointment-card';
import SearchEmptyState from '../search-empty-state';
import * as S from './appointments-feed.styles';

interface Props {
    filterType: AppointmentFilterType;
}

export const AppointmentsFeed: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const feedContainer = useRef<HTMLDivElement | null>(null);
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const isLoading = useSelector((state: RootState) => state.appointments.isLoading);
    const appointments = useSelector((state: RootState) => state.appointments.appointments);
    const isEmpty = useMemo(() => appointments.length === 0, [appointments.length]);

    usePaginateByScroll(feedContainer, () => {
        if (currentStaffUnit) {
            dispatch({ ...new LoadNextPageAppointmentsAction(currentStaffUnit) });
        }
    });

    useEffect(() => {
        dispatch({ ...new ClearAppointmentsAction() });
    }, [dispatch]);

    useEffect(() => {
        if (currentStaffUnit) {
            dispatch({ ...new SetAppointmentFiltersAction({ filterType: props.filterType }, currentStaffUnit) });
        }
    }, [dispatch, props.filterType, currentStaffUnit]);

    const onAppointmentClick = useCallback(
        (appointmentId: number) => {
            const appointmentRoute =
                props.filterType === AppointmentFilterType.Schedule
                    ? AppRoutes.AppointmentSchedule
                    : AppRoutes.AppointmentService;
            navigate(generatePath(appointmentRoute, { id: appointmentId.toString() }));
        },
        [navigate, props.filterType],
    );

    return (
        <S.Container ref={feedContainer}>
            <PreContentLayout isLoading={isLoading} underContent={true}>
                {appointments.map((appointment: Appointment, ind: number) => {
                    return (
                        <AppointmentCard
                            key={ind}
                            patientName={getPatientCardName(appointment.patient)}
                            yearBirth={appointment.patient.birthYear}
                            procedureType={appointment.name}
                            appointmentId={appointment.appointmentId}
                            quantity={appointment.quantity}
                            patientId={appointment.patient.patientId}
                            serviceType={appointment.serviceType}
                            appointmentTime={appointment.patient.appointmentTime}
                            filterType={props.filterType}
                            comment={appointment.comment}
                            onClick={() => onAppointmentClick(appointment.appointmentId)}
                        />
                    );
                })}
                {isEmpty && !isLoading && <SearchEmptyState />}
            </PreContentLayout>
        </S.Container>
    );
};
