import { AxiosError, Diagnosis, Outcome, Result } from '../../../../api-cli/build';
import {
    GetDiagnosesByParent,
    GetDiagnosesByParentError,
    GetDiagnosesByParentSuccess,
    GetDiagnosesBySearchString,
    GetDiagnosesBySearchStringError,
    GetDiagnosesBySearchStringSuccess,
    GetOutcomes,
    GetOutcomesError,
    GetOutcomesSuccess,
    GetResults,
    GetResultsError,
    GetResultsSuccess,
    HandbooksActions,
} from './handbooks.actions';

export interface HandbooksState {
    loading: {
        results: boolean;
        outcomes: boolean;
        diagnoses: boolean;
    };

    results: Result[];
    outcomes: Outcome[];
    diagnoses: Diagnosis[];

    errors: {
        results: AxiosError | null;
        outcomes: AxiosError | null;
        diagnoses: AxiosError | null;
    };
}

const initialState: HandbooksState = {
    loading: {
        results: false,
        outcomes: false,
        diagnoses: false,
    },

    results: [],
    outcomes: [],
    diagnoses: [],

    errors: {
        results: null,
        outcomes: null,
        diagnoses: null,
    },
};

export function handbooksReducer(state = initialState, action: HandbooksActions): HandbooksState {
    switch (action.type) {
        case GetResults.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    results: true,
                },
            };
        }
        case GetResultsSuccess.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    results: false,
                },
                results: action.results,
            };
        }
        case GetResultsError.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    results: false,
                },
                errors: {
                    ...state.errors,
                    results: action.error,
                },
            };
        }

        case GetOutcomes.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    outcomes: true,
                },
            };
        }
        case GetOutcomesSuccess.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    outcomes: false,
                },
                outcomes: action.outcomes,
            };
        }
        case GetOutcomesError.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    outcomes: false,
                },
                errors: {
                    ...state.errors,
                    outcomes: action.error,
                },
            };
        }

        case GetDiagnosesBySearchString.Name:
        case GetDiagnosesByParent.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    diagnoses: true,
                },
            };
        }
        case GetDiagnosesBySearchStringSuccess.Name:
        case GetDiagnosesByParentSuccess.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    diagnoses: false,
                },
                diagnoses: action.diagnoses,
            };
        }
        case GetDiagnosesBySearchStringError.Name:
        case GetDiagnosesByParentError.Name: {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    diagnoses: false,
                },
                errors: {
                    ...state.errors,
                    diagnoses: action.error,
                },
            };
        }
        default: {
            return state;
        }
    }
}
