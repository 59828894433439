import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Menu = styled.div`
    position: absolute;
    right: 0;
    background-color: ${Colors.White};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.07), 0px -4px 193px rgba(0, 0, 0, 0.27);
    border-radius: 15px;
    z-index: 1000;
    top: calc(100% + 10px);
`;

export const Toggler = styled.div``;

export const Container = styled.div`
    position: relative;
`;
