import { useState, useCallback } from 'react';

export function usePatientCardModalState() {
    const [patientCardModalState, setPatientCardModalState] = useState<PatientCardModalState>({ ...initialState });

    const showModal = useCallback(
        (patientId: string) => {
            setPatientCardModalState((prev) => ({ ...prev, isShow: true, patientId }));
        },
        [setPatientCardModalState],
    );

    const closeModal = useCallback(() => {
        setPatientCardModalState({ ...initialState });
    }, [setPatientCardModalState]);

    return { patientCardModalState, showModal, closeModal };
}

export interface PatientCardModalState {
    isShow: boolean;
    patientId?: string;
}

const initialState: PatientCardModalState = {
    isShow: false,
};
