import { GetAnalysisFileById } from 'pages/main-app/patient-analyzes/store/patient-analyzes.actions';
import { patientAnalysisFileSelector } from 'pages/main-app/patient-analyzes/store/patient-analyzes.selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useDocumentFile(fileId: string | null) {
    const dispatch = useDispatch();
    const analysisFileState = useSelector(patientAnalysisFileSelector);

    useEffect(() => {
        if (!fileId) return;

        dispatch({ ...new GetAnalysisFileById(fileId) });
    }, [dispatch, fileId]);

    return analysisFileState;
}
