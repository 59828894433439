import React, { createContext, useContext } from 'react';
import { SetDiagnosisCb, useDiagnosisSearchState } from './hooks/useDiagnosisSearchState';
import { Diagnosis } from '@ugmk/api';
import { PatientCardModalState, usePatientCardModalState } from './hooks/usePatientCardModalState';

export interface StateContextType {
    isDiagnosisScreenOpen: boolean;
    openDiagnosisScreen: (setDiagnosisCb: SetDiagnosisCb) => void;
    setDiagnosisToCard: (diagnosis: Diagnosis) => void;
    closeDiagnosisScreen: () => void;

    patientCardModalState: PatientCardModalState;
    showPatientCardModal: (patientId: string) => void;
    closePatientCardModal: () => void;
}

export const AppStateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
    const {
        patientCardModalState,
        showModal: showPatientCardModal,
        closeModal: closePatientCardModal,
    } = usePatientCardModalState();
    const { isDiagnosisScreenOpen, openDiagnosisScreen, closeDiagnosisScreen, setDiagnosisToCard } =
        useDiagnosisSearchState();

    return (
        <AppStateContext.Provider
            value={{
                isDiagnosisScreenOpen,
                openDiagnosisScreen,
                closeDiagnosisScreen,
                setDiagnosisToCard,

                patientCardModalState,
                showPatientCardModal,
                closePatientCardModal,
            }}
        >
            {props.children}
        </AppStateContext.Provider>
    );
}

export function useAppState() {
    const context = useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}
