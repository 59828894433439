import { IPatient, IPatientExtended, ServiceType } from '@ugmk/api';
import PatientCardResultsFilters from 'models/patient/PatientCardResultsFilters';
import PatientFilters from 'models/patient/PatientFilter';
import {
    ClearPageResultsPatientCardAction,
    GetPatientCardAction,
    GetPatientCardErrorAction,
    GetPatientCardForModalAction,
    GetPatientCardSuccessAction,
    GetPatientsAction,
    GetPatientsErrorAction,
    GetPatientsSuccessAction,
    LoadNextPagePatientsSuccessAction,
    LoadNextPageResultsPatientCardSuccessAction,
    PatientsActions,
    SetCardResultsFiltersAction,
    SetPatientFiltersAction,
} from './patients.actions';

export interface PatientState {
    isLoading: boolean;
    isLoadingCard: boolean;
    patients: IPatient[];
    patientCard?: IPatientExtended;
    cardsFilters: PatientFilters;
    cardResultsFilters: PatientCardResultsFilters;
    errorGetPatients?: any;
    errorGetPatientCard?: any;
}

const initialState: PatientState = {
    isLoading: true,
    isLoadingCard: false,
    patients: [],
    patientCard: undefined,
    cardsFilters: {
        page: 1,
        searchName: undefined,
    },
    cardResultsFilters: {
        page: 1,
        searchString: undefined,
        patientId: undefined,
        serviceType: ServiceType.All,
    },
    errorGetPatients: undefined,
    errorGetPatientCard: undefined,
};

export function patientReducer(state = initialState, action: PatientsActions): PatientState {
    switch (action.type) {
        case GetPatientsAction.Name: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case GetPatientsSuccessAction.Name: {
            return {
                ...state,
                isLoading: false,
                patients: action.patients,
                errorGetPatients: undefined,
            };
        }

        case LoadNextPagePatientsSuccessAction.Name: {
            return {
                ...state,
                patients: state.patients.concat(action.patients),
                cardsFilters: {
                    ...state.cardsFilters,
                    page: state.cardsFilters.page + 1,
                },
            };
        }

        case SetPatientFiltersAction.Name: {
            return {
                ...state,
                cardsFilters: {
                    ...state.cardsFilters,
                    ...action.filters,
                    page: 1,
                },
            };
        }

        case GetPatientsErrorAction.Name: {
            return {
                ...state,
                isLoading: false,
                errorGetPatients: action.error,
            };
        }

        case GetPatientCardAction.Name: {
            return {
                ...state,
                isLoadingCard: true,
            };
        }

        case GetPatientCardSuccessAction.Name: {
            return {
                ...state,
                isLoadingCard: false,
                patientCard: action.card,
            };
        }

        case GetPatientCardErrorAction.Name: {
            return {
                ...state,
                isLoadingCard: false,
                errorGetPatientCard: action.error,
            };
        }

        case LoadNextPageResultsPatientCardSuccessAction.Name: {
            if (state.patientCard) {
                return {
                    ...state,
                    patientCard: {
                        ...state.patientCard,
                        diagnosticResults: [...state.patientCard.diagnosticResults, ...action.card.diagnosticResults],
                    },
                    cardResultsFilters: {
                        ...state.cardResultsFilters,
                        page: state.cardResultsFilters.page + 1,
                    },
                };
            } else {
                return {
                    ...state,
                };
            }
        }

        case GetPatientCardForModalAction.Name:
        case SetCardResultsFiltersAction.Name: {
            return {
                ...state,
                cardResultsFilters: {
                    ...state.cardResultsFilters,
                    ...action.filters,
                    page: 1,
                },
            };
        }

        case ClearPageResultsPatientCardAction.Name: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
}
