import { RootState } from 'store/root.reducer';
import { createSelector } from 'reselect';
import { PatientPageState } from './patient.reducer';

export const patientPageSelector = (state: RootState): PatientPageState => state.patientPage;

export const isLoadingPatientSelector = createSelector([patientPageSelector], (patientPage) => patientPage.loading);

export const currentPatientSelector = createSelector([patientPageSelector], (patientPage) => patientPage.patient);

export const currentPatientErrorSelector = createSelector([patientPageSelector], (patientPage) => patientPage.error);
