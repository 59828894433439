import { GetDiagnosesByParentRequest, GetDiagnosesBySearchStringRequest, Diagnosis, Outcome, Result } from '../models';
import Api from '../utils/api';

export class HandbooksApi {
    static getResults(medDepId: string) {
        return Api.get<Result[]>('/handbooks/results', { params: { medDepId } });
    }

    static getOutcomes(medDepId: string) {
        return Api.get<Outcome[]>('/handbooks/outcomes', { params: { medDepId } });
    }

    static getDiagnosesBySearchString(request: GetDiagnosesBySearchStringRequest) {
        return Api.get<Diagnosis[]>('/handbooks/findDiagnoses', { params: { ...request } });
    }

    static getDiagnosesByParent(request: GetDiagnosesByParentRequest) {
        return Api.get<Diagnosis[]>('/handbooks/diagnosesByParent', { params: { ...request } });
    }
}
