import styled from 'styled-components/macro';

export namespace DocumentModalStyles {
    export const Container = styled.div`
        overflow: scroll;
        height: 100%;
        width: 100%;
    `;

    export const TitleContainer = styled.div`
        position: absolute;
        top: -50px;
    `;

    export const DocumentContainer = styled.div`
        margin: 0;
        height: 100%;
        width: 100%;
        background: white;
    `;

    export const CloseButton = styled.div`
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: -100px;

        svg path {
            stroke: #fff;
        }
    `;

    export const ControlContainer = styled.div`
        position: absolute;
        bottom: -70px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    `;

    export const ControlButton = styled.button<{ rotate?: string }>`
        border: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background-color: rgba(255, 255, 255, 0.3);
        transform: rotate(${({ rotate }) => rotate});
        margin: 0 15px;
        color: white;
        font-size: 28px;

        svg path {
            stroke: white;
        }
    `;
}
