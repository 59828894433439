import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import { MenuNavigationLinkContainer } from './styles';

interface Props {
    icon: IconType;
    label: string;
    to: AppRoutes;
}

const MenuNavigationLink: React.FC<Props> = (props) => {
    return (
        <NavLink to={props.to} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            {({ isActive }) => (
                <MenuNavigationLinkContainer active={isActive}>
                    <Icon type={props.icon} />
                    <Typography type="span" size={11} weight="regular" marginBottom={0}>
                        {props.label}
                    </Typography>
                </MenuNavigationLinkContainer>
            )}
        </NavLink>
    );
};

export default MenuNavigationLink;
