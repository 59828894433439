import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

export interface IKebabMenuContext {
    isShowing: boolean;
    toggleMenu: (isShow?: boolean) => void;
}

export const KebabMenuContext = createContext<IKebabMenuContext>(null!);

const KebabMenuProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [isShowing, toggle] = useState<boolean>(false);

    const toggleMenu = useCallback((isShow?: boolean) => {
        toggle((prev) => (isShow === undefined ? !prev : isShow));
    }, []);

    return <KebabMenuContext.Provider value={{ isShowing, toggleMenu }}>{children}</KebabMenuContext.Provider>;
};

export function useKebabMenuContext() {
    const context = useContext(KebabMenuContext);
    if (!context) {
        throw new Error('useKebabMenuContext must be used within a KebabMenuProvider');
    }
    return context;
}

export default KebabMenuProvider;
