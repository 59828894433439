import { Button } from 'components/base/button';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import React from 'react';
import { Colors } from 'styles/colors';
import * as S from './empty-state.styles';

interface Props {
    icon?: IconType;
    title: string;
    description?: string;
    button?: {
        label: string;
        onClick: () => void;
    };
}

export const EmptyState: React.FC<Props> = ({ icon, title, description, button }) => {
    return (
        <S.Container>
            {icon && (
                <S.IconContainer>
                    <Icon type={icon} />
                </S.IconContainer>
            )}
            <Typography type="h3" color={Colors.DarkGray} weight="medium" marginBottom={7}>
                {title}
            </Typography>
            <Typography type="span" size={14} color={Colors.DarkGray} marginBottom={25}>
                {description}
            </Typography>
            {button && <Button label={button.label} size="medium" onClick={button.onClick} />}
        </S.Container>
    );
};
