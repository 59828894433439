import { Action } from 'redux';
import { IConfirmCodeRequest, ISendCodeRequest, IUser, RegisterStatus, IStaffUnit } from '@ugmk/api';

export class GetMeAction implements Action {
    public static readonly Name = '[Auth] Get Me';
    readonly type = GetMeAction.Name;
}

export class GetMeSuccessAction implements Action {
    public static readonly Name = '[Auth] Get Me Success';
    readonly type = GetMeSuccessAction.Name;
    constructor(public me: IUser) {}
}

export class GetMeErrorAction implements Action {
    public static readonly Name = '[Auth] Get Me Error';
    readonly type = GetMeErrorAction.Name;
    constructor(public error: any) {}
}

export class SendCodeAction implements Action {
    public static readonly Name = '[Auth] Send Code';
    readonly type = SendCodeAction.Name;
    constructor(public request: ISendCodeRequest) {}
}

export class ConfirmCodeAction implements Action {
    public static readonly Name = '[Auth] Confirm Code';
    readonly type = ConfirmCodeAction.Name;
    constructor(public request: IConfirmCodeRequest) {}
}

export class ConfirmCodeErrorAction implements Action {
    public static readonly Name = '[Auth] Confirm Code Error';
    readonly type = ConfirmCodeErrorAction.Name;
    constructor(public error: string) {}
}

export class AuthErrorAction implements Action {
    public static readonly Name = '[Auth] Auth Error';
    readonly type = AuthErrorAction.Name;
}

export class LogInAction implements Action {
    public static readonly Name = '[Auth] Login';
    readonly type = LogInAction.Name;
    constructor(public email: string, public password: string) {}
}

export class LogInSuccessAction implements Action {
    public static readonly Name = '[Auth] Login Success';
    readonly type = LogInSuccessAction.Name;
    constructor(public user: IUser) {}
}

export class LogInErrorAction implements Action {
    public static readonly Name = '[Auth] Login Error';
    readonly type = LogInErrorAction.Name;
    constructor(public error: any) {}
}

export class RegisterAction implements Action {
    public static readonly Name = '[Auth] Register';
    readonly type = RegisterAction.Name;
    constructor(public email: string, public password: string) {}
}

export class RegisterSuccessAction implements Action {
    public static readonly Name = '[Auth] Register Success';
    readonly type = RegisterSuccessAction.Name;
    constructor(public status: RegisterStatus) {}
}

export class RegisterErrorAction implements Action {
    public static readonly Name = '[Auth] Register Error';
    readonly type = RegisterErrorAction.Name;
    constructor(public status: RegisterStatus, public error: any) {}
}

export class LogoutAction implements Action {
    public static readonly Name = '[Auth] Logout';
    readonly type = LogoutAction.Name;
}

export class LogoutSuccessAction implements Action {
    public static readonly Name = '[Auth] Logout Success';
    readonly type = LogoutSuccessAction.Name;
}

export class LogoutErrorAction implements Action {
    public static readonly Name = '[Auth] Logout Error';
    readonly type = LogoutErrorAction.Name;
    constructor(public error: string) {}
}

export class GetWaitingTimeCodeAction implements Action {
    public static readonly Name = '[Auth] Get Waiting Time Code';
    readonly type = GetWaitingTimeCodeAction.Name;
    constructor(public email: string) {}
}

export class GetWaitingTimeCodeSuccessAction implements Action {
    public static readonly Name = '[Auth] Get Waiting Time Code Success';
    readonly type = GetWaitingTimeCodeSuccessAction.Name;
    constructor(public seconds: number) {}
}

export class RestorePasswordAction implements Action {
    public static readonly Name = '[Auth] Restore Password';
    readonly type = RestorePasswordAction.Name;
    constructor(public userId: string, public password: string) {}
}

export class SetCurrentStaffUnitAction implements Action {
    public static readonly Name = '[Auth] Set Current Staff Unit';
    readonly type = SetCurrentStaffUnitAction.Name;
    constructor(public staffUnit: IStaffUnit) {}
}

export type AuthActions =
    | LogInAction
    | LogInSuccessAction
    | RegisterAction
    | RegisterSuccessAction
    | LogInErrorAction
    | RegisterErrorAction
    | GetMeAction
    | GetMeSuccessAction
    | GetMeErrorAction
    | AuthErrorAction
    | LogoutAction
    | LogoutSuccessAction
    | LogoutErrorAction
    | ConfirmCodeAction
    | ConfirmCodeErrorAction
    | SendCodeAction
    | GetWaitingTimeCodeAction
    | GetWaitingTimeCodeSuccessAction
    | RestorePasswordAction
    | SetCurrentStaffUnitAction;
