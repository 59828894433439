import { Button } from 'components/base/button';
import { IconType } from 'components/base/icon/types';
import { InputText } from 'components/base/input';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { SetPatientFiltersAction } from 'store/patients/patients.actions';
import { RootState } from 'store/root.reducer';
import { PatientSearchFromContainer } from './styles';

interface Props {
    onAfterSearch?: () => void;
}

const PatientSearchForm: React.FC<Props> = ({ onAfterSearch }) => {
    const dispatch = useDispatch();
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const patientFilters = useSelector((state: RootState) => state.patient.cardsFilters);
    const [searchName, setSearchName] = useState<string>('');

    const onFullNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setSearchName(event.target.value);
        }
    };

    const onSearch = () => {
        if (currentStaffUnit) {
            dispatch({ ...new SetPatientFiltersAction({ searchName }, currentStaffUnit.medDepId) });
        }
        onAfterSearch && onAfterSearch();
    };

    return (
        <PatientSearchFromContainer>
            <InputText
                icon={IconType.Search}
                isClear={true}
                defaultValue={patientFilters.searchName}
                label="Поиск по ФИО"
                onChange={onFullNameChangeHandler}
                onClearHandle={() => setSearchName('')}
            />
            <Button label="Найти записи" onClick={onSearch} />
        </PatientSearchFromContainer>
    );
};

export default PatientSearchForm;
