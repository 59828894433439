import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

export const IconContainer = styled.div`
    width: 48px;
    height: 48px;
    margin-bottom: 20px;

    svg {
        width: 100%;
        height: 100%;
    }
`;
