import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const HoverComponent = styled.div`
    position: relative;
`;

export const TooltipContainer = styled.div`
    display: none;
    position: absolute;
    max-width: 80vw;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1.5px solid ${Colors.Blue};
    box-shadow: 8px 7px 26px rgb(0 0 0 / 4%);
    background-color: ${Colors.DarkWhite};
    z-index: 100000;
`;

export const Container = styled.div`
    position: relative;

    ${HoverComponent}:hover + ${TooltipContainer} {
        display: block;
    }
`;
