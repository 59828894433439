import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const BackButtonIcon = styled.span`
    transform: rotate(180deg);
`;

export const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${Colors.Orange};
    font-weight: 500;

    ${BackButtonIcon} {
        margin-right: 12px;
    }
`;

export const PatientCardHeader = styled.div`
    display: flex;
    flex-direction: column;

    ${BackButton} {
        justify-content: flex-start;
        margin-bottom: 17px;
    }
`;

export const PatientCardHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 10px;

    span {
        margin-left: 10px;
    }
`;
