import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import {useEffect, useRef, useState} from 'react';
import Icon from '../icon';
import {IconType} from '../icon/types';
import Typography from '../Typography';
import * as S from './dropdown.styles';
import {ErrorLabel} from "./dropdown.styles";

interface Props<T> {
    items: T[];
    error?: string
    label: string;
    icon?: IconType;
    labelSelector: (item: T) => string;
    onSelect: (item: T) => void;
    value?: T;
    isDisabled?: boolean
}

export function Dropdown<T>(props: Props<T>) {
    const dropwdownRef = useRef<HTMLDivElement | null>(null);
    useOutsideClickHandler(dropwdownRef, () => openDropdown(false));

    const [isOpened, openDropdown] = useState(false);
    const [selectedItem, setSelectedItem] = useState<T | undefined>();

    useEffect(() => {
        setSelectedItem(props.value);
    }, [props.value]);

    const selectItem = (item: T) => {
        props.onSelect(item);
        setSelectedItem(item);
        openDropdown(false);
    };

    return (
        <div>
            <S.Container active={isOpened} ref={dropwdownRef} error={!!props.error}>
                <S.ValueContainer onClick={() => !props.isDisabled && openDropdown(!isOpened)}>
                    <S.Label active={!!selectedItem}>{props.label}</S.Label>
                    <S.RightIcon active={isOpened}>
                        <Icon type={props.icon || IconType.Expand}/>
                    </S.RightIcon>
                    {selectedItem && props.labelSelector(selectedItem)}
                </S.ValueContainer>
                <S.List active={isOpened}>
                    {props.items.map((item: T, ind: number) => (
                        <S.ListItem key={ind} onClick={() => selectItem(item)}>
                            <Typography
                                type="span"
                                size={16}
                                marginBottom={0}
                                weight={item === selectedItem ? 'medium' : 'regular'}
                            >
                                {props.labelSelector(item)}
                            </Typography>
                        </S.ListItem>
                    ))}
                </S.List>
            </S.Container>
            {props.error && <ErrorLabel>{props.error}</ErrorLabel>}
        </div>
    );
}
