// TODO: Transfer to rtk
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleWare from 'redux-saga';
import { createRootReducer } from './root.reducer';
import { createBrowserHistory } from 'history';
import rootSaga from './root.saga';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';

export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const sagaMiddleware = createSagaMiddleWare();
const store = createStore(
    createRootReducer(history),
    composeWithDevTools(applyMiddleware(routerMiddleware, sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default store;
