import { back } from '@lagunovsky/redux-react-router';
import { select, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import {
    ApiResponse,
    CheckupDiagnosticResult,
    CheckupTemplate,
    IPatient,
    LfkDiagnosticResult,
    LfkTemplate,
    Outcome,
    PatientApi,
    Result,
    ServiceType,
    TemplateApi,
    TemplateType,
} from '@ugmk/api';
import { SelectTemplate } from 'pages/main-app/appointment/store/appointment.actions';
import { call, put } from 'redux-saga/effects';
import { medDepIdSelector } from 'store/auth/auth.selectors';
import { RootState } from 'store/root.reducer';
import {
    AddEmptyTemplateToSelectedTemplate,
    CloseSaveAsTemplateModal,
    GetPatientById,
    GetPatientByIdError,
    GetPatientByIdSuccess,
    SaveAsTemplate,
    SaveAsTemplateError,
    SaveAsTemplateSuccess,
} from './patient-card.actions';

function* saveAsTemplateHandler(action: SaveAsTemplate): SagaIterator {
    const { data, error }: ApiResponse<TemplateType> = yield call(TemplateApi.createTemplate, action.request);

    if (data) {
        yield put({ ...new SaveAsTemplateSuccess() });
        yield put({ ...new CloseSaveAsTemplateModal() });
    } else if (error) {
        yield put({ ...new SaveAsTemplateError(error) });
    }
}

function* addEmptyTemplateToSelectedTemplateHandler(action: AddEmptyTemplateToSelectedTemplate): SagaIterator {
    const outcomes: Outcome[] = yield select((rootState: RootState) => rootState.handbooks.outcomes);
    const results: Result[] = yield select((rootState: RootState) => rootState.handbooks.results);

    if (action.diagnosticResult.serviceType === ServiceType.Checkup) {
        const diagnosticResult = action.diagnosticResult as CheckupDiagnosticResult;

        const emptyTemplate: CheckupTemplate = {
            _id: '0',
            user: '',
            title: 'empty',
            ...diagnosticResult,
            diagnosis: undefined,
            outcome: outcomes.find((outcome) => outcome.outcomeId === diagnosticResult.outcomeId),
            result: results.find((result) => result.resultId === diagnosticResult.resultId),
        };

        yield put({ ...new SelectTemplate(emptyTemplate) });
        yield put(back());
        yield put(back());
    }

    if (action.diagnosticResult.serviceType === ServiceType.LFK) {
        const diagnosticResult = action.diagnosticResult as LfkDiagnosticResult;
        const emptyTemplate: LfkTemplate = {
            _id: '0',
            user: '',
            title: 'empty',
            ...diagnosticResult,
        };

        yield put({ ...new SelectTemplate(emptyTemplate) });
        yield put(back());
        yield put(back());
    }
}

function* getPatientByIdHandler(action: GetPatientById) {
    const medDepId: string | undefined = yield select(medDepIdSelector);

    if (!medDepId) {
        yield put({ ...new GetPatientByIdError('Data corrupted. MedDepId does not exists.') });
        return;
    }

    const { data, error }: ApiResponse<IPatient> = yield call(PatientApi.getPatientById, action.patientId, medDepId);

    if (data) {
        yield put({ ...new GetPatientByIdSuccess(data) });
    } else {
        yield put({
            ...new GetPatientByIdError(`Get Patient Error. Status: ${error?.code}. Message: ${error?.message}`),
        });
    }
}

export default function* patientCardPageSaga(): SagaIterator {
    yield takeLatest(GetPatientById.Name, getPatientByIdHandler);
    yield takeLatest(SaveAsTemplate.Name, saveAsTemplateHandler);
    yield takeLatest(AddEmptyTemplateToSelectedTemplate.Name, addEmptyTemplateToSelectedTemplateHandler);
}
