import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import React, { useRef } from 'react';
import { useMainLayoutState } from '../../state/MainLayoutProvider';
import { HeaderContainer, HeaderOverlay, SearchButton } from './styles';

interface Props {
    isShowSearchButton?: boolean;
}

/**
 *
 * @param isShowSearchButton `boolean`
 */
const Header: React.FC<React.PropsWithChildren<Props>> = ({ children, isShowSearchButton = true }) => {
    const { headerActive, setHeaderActive } = useMainLayoutState();
    const headerRef = useRef<HTMLDivElement | null>(null);
    useOutsideClickHandler(headerRef, () => setHeaderActive(false));

    return (
        <React.Fragment>
            <HeaderContainer ref={headerRef}>
                {children}
                {isShowSearchButton && (
                    <SearchButton onClick={() => setHeaderActive(!headerActive)} active={headerActive}>
                        <Icon type={headerActive ? IconType.Expand : IconType.Search} />
                    </SearchButton>
                )}
            </HeaderContainer>
            {isShowSearchButton && <HeaderOverlay active={headerActive} />}
        </React.Fragment>
    );
};

export default Header;
