import { IPatient } from '@ugmk/api';
import {
    ClearPatientPage,
    GetPatientById,
    GetPatientByIdError,
    GetPatientByIdSuccess,
    PatientPageActions,
} from './patient.actions';

export interface PatientPageState {
    loading: boolean;
    patient?: IPatient;
    error?: string;
}

const initialState: PatientPageState = {
    loading: false,
};

export function patientPageReducer(state = initialState, action: PatientPageActions): PatientPageState {
    switch (action.type) {
        case GetPatientById.Name: {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        }

        case GetPatientByIdSuccess.Name: {
            return {
                ...state,
                loading: false,
                patient: action.data,
            };
        }

        case GetPatientByIdError.Name: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case ClearPatientPage.Name: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
}
