import { InputText, Textarea } from 'components/base/input';
import React from 'react';
import { LfkDiagnosticResult } from '@ugmk/api';
import * as S from '../../../../styles';

interface Props extends LfkDiagnosticResult {}
const EMPTY_STATE_STRING = 'Здесь ещё ничего нет...';

export const LfkResultForm: React.FC<Props> = (props) => {
    return (
        <>
            <S.TextareaOnlyRead isActive={!!props.complaints}>
                <Textarea
                    name="complaints"
                    defaultValue={props.complaints || EMPTY_STATE_STRING}
                    label="Жалобы"
                    disabled={true}
                />
            </S.TextareaOnlyRead>
            <S.TextareaOnlyRead isActive={!!props.program}>
                <Textarea
                    name="program"
                    defaultValue={props.program || EMPTY_STATE_STRING}
                    label="Программы"
                    disabled={true}
                />
            </S.TextareaOnlyRead>
            <S.TextareaOnlyRead isActive={!!props.progression}>
                <Textarea
                    name="progression"
                    defaultValue={props.progression || ''}
                    label="Прогрессия"
                    disabled={true}
                />
            </S.TextareaOnlyRead>
            <S.CardRow>
                <InputText
                    name="APbefore"
                    defaultValue={props.APbefore || ''}
                    label="АД до"
                    type="number"
                    disabled={true}
                />
                <S.Space style={{ minWidth: 15 }} />
                <InputText
                    name="APafter"
                    defaultValue={props.APafter || ''}
                    label="АД После"
                    type="number"
                    disabled={true}
                />
            </S.CardRow>
        </>
    );
};
