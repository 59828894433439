import { Appointment, AxiosError, TemplateType } from '@ugmk/api';
import {
    AppointmentPageActions,
    ClearAppointmentPageStore,
    ClearSelectedTemplate,
    CloseTemplateSelectionScreen,
    DeleteTamplate,
    DeleteTemplateError,
    DeleteTemplateSuccess,
    GetAppointmentById,
    GetAppointmentByIdError,
    GetAppointmentByIdSuccess,
    GetTemplates,
    GetTemplatesError,
    GetTemplatesSuccess,
    OpenTemplateSelectionScreen,
    SelectTemplate,
} from './appointment.actions';

export interface AppointmentPageState {
    isLoadingAppointment: boolean;
    appointment?: Appointment;
    errorAppointment?: AxiosError;

    isTemplateSelectionModalOpened: boolean;

    isLoadingTemplates: boolean;
    templates: TemplateType[];
    selectedTemplate?: TemplateType;
    errorGetTemplates?: AxiosError;

    isLoadingTemplateDeleting: boolean;
    errorTemplateDeleting?: AxiosError;
}

const initialState: AppointmentPageState = {
    isLoadingAppointment: false,
    appointment: undefined,
    errorAppointment: undefined,

    isTemplateSelectionModalOpened: false,

    isLoadingTemplates: false,
    templates: [],
    selectedTemplate: undefined,
    errorGetTemplates: undefined,

    isLoadingTemplateDeleting: false,
    errorTemplateDeleting: undefined,
};

export function appointmentPageReducer(state = initialState, action: AppointmentPageActions): AppointmentPageState {
    switch (action.type) {
        case GetAppointmentById.Name: {
            return {
                ...state,
                isLoadingAppointment: true,
            };
        }

        case GetAppointmentByIdSuccess.Name: {
            return {
                ...state,
                isLoadingAppointment: false,
                appointment: action.appointment,
            };
        }

        case GetAppointmentByIdError.Name: {
            return {
                ...state,
                isLoadingAppointment: false,
                errorAppointment: action.error,
            };
        }

        case OpenTemplateSelectionScreen.Name: {
            return {
                ...state,
                isTemplateSelectionModalOpened: true,
            };
        }

        case CloseTemplateSelectionScreen.Name: {
            return {
                ...state,
                isTemplateSelectionModalOpened: false,
            };
        }

        case GetTemplates.Name: {
            return {
                ...state,
                isLoadingTemplates: true,
            };
        }

        case GetTemplatesSuccess.Name: {
            return {
                ...state,
                isLoadingTemplates: false,
                templates: action.temapltes,
            };
        }

        case GetTemplatesError.Name: {
            return {
                ...state,
                isLoadingTemplates: false,
                errorGetTemplates: action.error,
            };
        }

        case SelectTemplate.Name: {
            return {
                ...state,
                selectedTemplate: action.template,
            };
        }

        case DeleteTamplate.Name: {
            return {
                ...state,
                isLoadingTemplateDeleting: true,
                errorTemplateDeleting: undefined,
            };
        }

        case DeleteTemplateSuccess.Name: {
            return {
                ...state,
                isLoadingTemplateDeleting: false,
                templates: state.templates.filter((t) => t._id !== action.templateId),
            };
        }

        case DeleteTemplateError.Name: {
            return {
                ...state,
                isLoadingTemplateDeleting: false,
                errorTemplateDeleting: action.error,
            };
        }

        case ClearSelectedTemplate.Name: {
            return {
                ...state,
                selectedTemplate: undefined,
            };
        }

        case ClearAppointmentPageStore.Name: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
}
