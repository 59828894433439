import { Appointment } from '@ugmk/api';
import { Tooltip } from 'components/base/tooltip/tooltip.component';
import Typography from 'components/base/Typography';
import { BackButton } from 'components/partial/back-button';
import { getPatientCardName } from 'helpers/patient';
import React from 'react';
import * as S from './appointment-header.styles';

interface Props {
    appointment: Appointment;
}

const AppointmentHeader: React.FC<Props> = ({ appointment }) => {
    const fullDescription = `${appointment.name} (${appointment.quantity})`;
    const shortDescription =
        fullDescription.split(' ').length > 7
            ? fullDescription.split(' ').slice(0, 7).join(' ') + '...'
            : fullDescription;

    return (
        <S.Container>
            <BackButton label="Вернуться назад" withIcon={true} styles={{ marginBottom: 20 }} />
            {appointment && (
                <React.Fragment>
                    <Typography type="span" weight="medium" size={17} marginBottom={10} textAlign="left">
                        {getPatientCardName(appointment.patient)}{' '}
                        <Typography type="span">({appointment.patient.birthYear} г.)</Typography>
                    </Typography>
                    <Tooltip label={fullDescription}>
                        <Typography weight="medium" marginBottom={0} size={14} type="p" textAlign="left">
                            {shortDescription}
                        </Typography>
                    </Tooltip>
                </React.Fragment>
            )}
        </S.Container>
    );
};

export default AppointmentHeader;
