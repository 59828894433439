import { IPatient } from '@ugmk/api';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClearPatientPage, GetPatientById } from '../store/patient.actions';
import {
    currentPatientErrorSelector,
    currentPatientSelector,
    isLoadingPatientSelector,
} from '../store/patient.selectors';

export function usePatientById(patientId: string | null) {
    const dispatch = useDispatch();
    const loadingPatient: boolean = useSelector(isLoadingPatientSelector);
    const patient: IPatient | undefined = useSelector(currentPatientSelector);
    const errorPatient: string | undefined = useSelector(currentPatientErrorSelector);

    useEffect(() => {
        if (!patientId) return;
        dispatch({ ...new GetPatientById(patientId) });

        return () => {
            dispatch({ ...new ClearPatientPage() });
        };
    }, [patientId, dispatch]);

    return { loadingPatient, patient, errorPatient };
}
