import { CloseFactoryModal, FactoryModalActions, OpenFactoryModal } from './factory-modal.actions';
import { ModalFactoryType, ModalTypeDataList } from './factory-modal.types';

export interface FactoryModalState<ModalType extends ModalFactoryType> {
    isOpen: boolean;
    data?: ModalTypeDataList[ModalType];
}

export function modalFactory<ModalType extends ModalFactoryType>() {
    const initialState: FactoryModalState<ModalType> = {
        isOpen: false,
    };

    return (state = initialState, action: FactoryModalActions<ModalType>): FactoryModalState<ModalType> => {
        switch (action.type) {
            case OpenFactoryModal.Name: {
                return {
                    ...state,
                    isOpen: true,
                    data: action.data,
                };
            }
            case CloseFactoryModal.Name: {
                return {
                    ...state,
                    isOpen: false,
                    data: undefined,
                };
            }
            default: {
                return state;
            }
        }
    };
}
