import { DocuemntModalData } from 'pages/main-app/document-page/document-page.types';
import { FactoryModalState } from './factory-modal.reducer';

export enum ModalFactoryType {
    PatientDocument = 'patientDocumentModal',
}

export type ModalTypeDataList = {
    [ModalFactoryType.PatientDocument]: DocuemntModalData;
};

export type FactoryModalsMapState = {
    [key in ModalFactoryType]: FactoryModalState<key>;
};
