import { AppointmentPageType } from 'models/appointment/appointment-page-type';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { GetAppointmentById } from '../store/appointment.actions';
import { currentAppointmentSelector, isLoadingAppointmentSelector } from '../store/appointment.selectors';

export function useAppointmentByParams(appointmentId: string | undefined, pageType: AppointmentPageType) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const isLoadingAppointment = useSelector(isLoadingAppointmentSelector);
    const appointment = useSelector(currentAppointmentSelector);

    useEffect(() => {
        if (appointmentId && !Number.isNaN(+appointmentId) && currentStaffUnit) {
            dispatch({ ...new GetAppointmentById(+appointmentId, currentStaffUnit.medDepId, pageType) });
        } else {
            navigate(AppRoutes.Schedule, { replace: true });
        }
    }, [navigate, dispatch, appointmentId, pageType, currentStaffUnit]);

    return { isLoadingAppointment, appointment };
}
