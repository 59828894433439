import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import {
    ApiResponse,
    AppointmentApi,
    GetAppointmentsRequest,
    Appointment,
    IAppointmentType,
    ConfirmAppointmentRequest,
} from '@ugmk/api';
import { back } from '@lagunovsky/redux-react-router';
import AppointmentFilters from 'models/appointment/AppointmentFilters';
import { ShowInfoModalAction } from 'store/common/info-modal/info-modal.actions';
import { RootState } from 'store/root.reducer';
import {
    ConfirmAppointmentAction,
    ConfirmAppointmentActionError,
    ConfirmAppointmentActionSuccess,
    FinishAppointmentsFetching,
    GetAppointmentsAction,
    GetAppointmentsErrorAction,
    GetAppointmentsSuccessAction,
    GetAppointmentTypesAction,
    GetAppointmentTypesErrorAction,
    GetAppointmentTypesSuccessAction,
    LoadNextPageAppointmentsAction,
    LoadNextPageAppointmentsSuccessAction,
    SetAppointmentFiltersAction,
    UpdateAppointmentQuantityAction,
} from './appointments.actions';

function* getAppointmentTypesHandler(action: GetAppointmentTypesAction) {
    const { data, error }: ApiResponse<IAppointmentType[]> = yield call(
        AppointmentApi.getAppointmentTypes,
        action.currentStaffUnit.medDepId,
        action.currentStaffUnit.externalMedDepId,
    );

    if (!error) {
        yield put({ ...new GetAppointmentTypesSuccessAction(data) });
        yield put({ ...new SetAppointmentFiltersAction({ type: data[0] }, action.currentStaffUnit) });
    } else {
        console.error('Get Appointment Types Error: ', error);
        yield put({ ...new GetAppointmentTypesErrorAction(error) });
    }
}

function* getAppointmentsHandler(
    action: SetAppointmentFiltersAction | GetAppointmentsAction | LoadNextPageAppointmentsAction,
) {
    let filters: AppointmentFilters = yield select((state: RootState) => state.appointments.filters);
    let isAppointmentsOver: boolean = yield select((state: RootState) => state.appointments.isAppointmentsOver);
    if (isAppointmentsOver && action.type === LoadNextPageAppointmentsAction.Name) return;

    if (action.type === SetAppointmentFiltersAction.Name) {
        filters = {
            ...filters,
            ...action.filters,
            page: action.filters.page ? action.filters.page : 1,
        };
    } else if (action.type === LoadNextPageAppointmentsAction.Name) {
        filters = {
            ...filters,
            page: filters.page + 1,
        };
    }

    if (filters.type && action.currentStaffUnit.externalMedDepId) {
        const request: GetAppointmentsRequest = {
            medDepId: action.currentStaffUnit.medDepId,
            page: filters.page,
            searchName: filters.searchName,
            appTypeId: filters.type.appTypeId,
            type: filters.filterType,
            dateFilter: filters.dateFilter,
            externalMedDepId: action.currentStaffUnit.externalMedDepId,
            serviceType: filters.serviceType,
        };
        const { data, error }: ApiResponse<Appointment[]> = yield call(AppointmentApi.getAppointments, request);

        if (!error) {
            if (action.type === LoadNextPageAppointmentsAction.Name) {
                if (data.length > 0) {
                    yield put({ ...new LoadNextPageAppointmentsSuccessAction(filters.page, data) });
                } else {
                    yield put({ ...new FinishAppointmentsFetching() });
                }
            } else {
                yield put({ ...new GetAppointmentsSuccessAction(data) });
            }
        } else {
            console.error('Get Appointments Error: ', error);
            yield put({ ...new GetAppointmentsErrorAction(error) });
        }
    } else {
        console.error('Current MedDepId Error: Not enough filters', action.currentStaffUnit.externalMedDepId);
    }
}

function* confirmAppointmentHandler(action: ConfirmAppointmentAction) {
    const confirmRequest: ConfirmAppointmentRequest = {
        ...action.data,
    };
    const { error }: ApiResponse<null> = yield call(AppointmentApi.confirmAppointment, confirmRequest);

    if (!error) {
        yield put({
            ...new ShowInfoModalAction({
                title: 'Услуга успешно подтверждена',
                contentMessage: '',
                buttonText: 'Ок',
            }),
        });
        yield put({ ...new UpdateAppointmentQuantityAction(action.data.appointmentId) });
        yield put(back());
        yield put({ ...new ConfirmAppointmentActionSuccess() });
    } else {
        if (error.response?.status === 400) {
            yield put({
                ...new ShowInfoModalAction({
                    title: 'Услуга уже подтверждена',
                    contentMessage: error.response.data.message,
                    buttonText: 'Ок',
                }),
            });
            yield put({ ...new ConfirmAppointmentActionError() });
        } else {
            yield put({
                ...new ShowInfoModalAction({
                    title: 'На данный момент сервис недоступен',
                    contentMessage: 'Обратитесь к своему IT-администратору',
                    buttonText: 'Ок',
                }),
            });
            yield put({ ...new ConfirmAppointmentActionError() });
        }
    }
}

export default function* appointmentSaga(): SagaIterator {
    yield takeLatest(GetAppointmentTypesAction.Name, getAppointmentTypesHandler);
    yield takeLatest(GetAppointmentsAction.Name, getAppointmentsHandler);
    yield takeLatest(SetAppointmentFiltersAction.Name, getAppointmentsHandler);
    yield takeLatest(LoadNextPageAppointmentsAction.Name, getAppointmentsHandler);
    yield takeLatest(ConfirmAppointmentAction.Name, confirmAppointmentHandler);
}
