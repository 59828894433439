import Loader from 'components/base/Loader';
import React, { PropsWithChildren } from 'react';

interface Props {
    isLoading: boolean;
    underContent?: boolean;
}

/**
 * @param isLoading Showing loader instead content
 * @param underContent `not required` showing loader under content
 */
const PreContentLayout: React.FC<PropsWithChildren<Props>> = ({ isLoading, underContent = false, children }) => {
    if (isLoading && !underContent) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            {children}
            {underContent && isLoading && <Loader />}
        </React.Fragment>
    );
};

export default PreContentLayout;
