import { HeaderContainer } from './components/Header/styles';
import { MenuNavigationContainer, MENU_NAVIGATION_HEIGHT } from './components/MenuNavigation/styles';
import * as HelpLink from './components/help-link/help-link.component';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';
import { DiagnosisSearchScreenContainer } from 'components/partial/diagnosis-search-screen/diagnosis-search-screen.styles';

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 0px 0px ${MENU_NAVIGATION_HEIGHT};
    overflow: hidden;
`;

export const MainLayoutContainer = styled.div<{ isHeaderActive: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: ${Colors.DarkWhite};

    ${HeaderContainer} {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 500;
    }

    ${DiagnosisSearchScreenContainer} {
        z-index: 600;
    }

    ${Content} {
        padding-bottom: ${({ isHeaderActive }) => (isHeaderActive ? 0 : MENU_NAVIGATION_HEIGHT)};
        transition: padding-bottom 0.3s;
    }

    ${Content} ${MenuNavigationContainer} {
        position: fixed;
        bottom: ${({ isHeaderActive }) => (isHeaderActive ? '-100%' : 0)};
        left: 0;
        transition: bottom 0.3s;
        z-index: 700;
    }

    ${HelpLink.Container} {
        display: ${({ isHeaderActive }) => (isHeaderActive ? 'none' : 'flex')};
        position: fixed;
        right: 20px;
        bottom: calc(20px + ${MENU_NAVIGATION_HEIGHT});
        z-index: 600;
    }
`;
