import { TemplateType } from '@ugmk/api';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { TemplateSelectionStep } from '../types/template-selection.types';

export interface ITemplateSelectionContext {
    currentStep: TemplateSelectionStep;
    changeStep: (step: TemplateSelectionStep) => void;
    templateItem: TemplateType | null;
    selectTemplateItem: (templateItem: TemplateType) => void;
}

export const TemplateSelectionContext = createContext<ITemplateSelectionContext>(null!);

const TemplateSelectionProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [currentStep, changeStep] = useState<TemplateSelectionStep>(TemplateSelectionStep.List);
    const [templateItem, selectTemplateItem] = useState<TemplateType | null>(null);

    return (
        <TemplateSelectionContext.Provider value={{ currentStep, changeStep, templateItem, selectTemplateItem }}>
            {children}
        </TemplateSelectionContext.Provider>
    );
};

export function useTemplateSelectionContext() {
    const context = useContext(TemplateSelectionContext);
    if (!context) {
        throw new Error('useTemplateSelectionContext must be used within a TemplateSelectionProvider');
    }
    return context;
}

export default TemplateSelectionProvider;
