import { Action } from 'redux';
import { AxiosError, Outcome, Result, Diagnosis } from '@ugmk/api';

export class GetResults implements Action {
    public static readonly Name = '[Handbooks] GetResults';
    readonly type = GetResults.Name;
    constructor(public medDepId: string) {}
}

export class GetResultsSuccess implements Action {
    public static readonly Name = '[Handbooks] GetResultsSuccess';
    readonly type = GetResultsSuccess.Name;
    constructor(public results: Result[]) {}
}

export class GetResultsError implements Action {
    public static readonly Name = '[Handbooks] GetResultsError';
    readonly type = GetResultsError.Name;
    constructor(public error: AxiosError) {}
}

export class GetOutcomes implements Action {
    public static readonly Name = '[Handbooks] GetOutcomes';
    readonly type = GetOutcomes.Name;
    constructor(public medDepId: string) {}
}

export class GetOutcomesSuccess implements Action {
    public static readonly Name = '[Handbooks] GetOutcomesSuccess';
    readonly type = GetOutcomesSuccess.Name;
    constructor(public outcomes: Outcome[]) {}
}

export class GetOutcomesError implements Action {
    public static readonly Name = '[Handbooks] GetOutcomesError';
    readonly type = GetOutcomesError.Name;
    constructor(public error: AxiosError) {}
}

export class GetDiagnosesBySearchString implements Action {
    public static readonly Name = '[Handbooks] GetDiagnosesBySearchString';
    readonly type = GetDiagnosesBySearchString.Name;
    constructor(public medDepId: string, public searchString: string) {}
}

export class GetDiagnosesBySearchStringSuccess implements Action {
    public static readonly Name = '[Handbooks] GetDiagnosesBySearchStringSuccess';
    readonly type = GetDiagnosesBySearchStringSuccess.Name;
    constructor(public diagnoses: Diagnosis[]) {}
}

export class GetDiagnosesBySearchStringError implements Action {
    public static readonly Name = '[Handbooks] GetDiagnosesBySearchStringError';
    readonly type = GetDiagnosesBySearchStringError.Name;
    constructor(public error: AxiosError) {}
}

export class GetDiagnosesByParent implements Action {
    public static readonly Name = '[Handbooks] GetDiagnosesByParent';
    readonly type = GetDiagnosesByParent.Name;
    constructor(public medDepId: string, public parentId: string) {}
}

export class GetDiagnosesByParentSuccess implements Action {
    public static readonly Name = '[Handbooks] GetDiagnosesByParentSuccess';
    readonly type = GetDiagnosesByParentSuccess.Name;
    constructor(public diagnoses: Diagnosis[]) {}
}

export class GetDiagnosesByParentError implements Action {
    public static readonly Name = '[Handbooks] GetDiagnosesByParentError';
    readonly type = GetDiagnosesByParentError.Name;
    constructor(public error: AxiosError) {}
}

export type HandbooksActions =
    | GetResults
    | GetResultsSuccess
    | GetResultsError
    | GetOutcomes
    | GetOutcomesSuccess
    | GetOutcomesError
    | GetDiagnosesBySearchString
    | GetDiagnosesBySearchStringSuccess
    | GetDiagnosesBySearchStringError
    | GetDiagnosesByParent
    | GetDiagnosesByParentSuccess
    | GetDiagnosesByParentError;
