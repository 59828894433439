import { MENU_NAVIGATION_HEIGHT } from 'components/layouts/MainLayout/components/MenuNavigation/styles';
import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { DiagnosisItemContainer } from './components/diagnosis-item/diagnosis-item.styles';

export const DiagnosisSearchScreenContainer = styled.div<{ active: boolean }>`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - ${MENU_NAVIGATION_HEIGHT});
    background-color: ${Colors.DarkWhite};
    display: ${({ active }) => (active ? 'flex' : 'none')};
    flex-direction: column;
    overflow: hidden;
    z-index: 1000;
`;

export const Header = styled.div`
    width: 100%;
    background-color: ${Colors.White};
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 0px 0px 15px 15px;
`;

export const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    svg {
        margin-right: 12px;
        transform: rotate(180deg);
    }
`;

export const DiagnosisList = styled.div<{ isDiagnosisSelected: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 18px 25px 0;
    padding-bottom: ${({ isDiagnosisSelected }) => (isDiagnosisSelected ? '65px' : 0)};
    overflow-y: scroll;

    ${DiagnosisItemContainer} {
        margin-bottom: 15px;
    }
`;

export const FloatingButton = styled.div`
    position: fixed;
    bottom: 90px;
    left: 0;
    width: 100%;
    padding: 0 25px;
`;
