import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLinkLogin } from './hooks/use-link-login';

const LinkLoginPage: React.FC = () => {
    useLinkLogin();

    return <Navigate to={'/'} replace={true} />;
};

export default LinkLoginPage;
