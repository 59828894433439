import { InputContainer } from 'components/base/input/styles';
import styled from 'styled-components';

export const PatientSearchFromContainer = styled.div`
    overflow: hidden;
    transition: max-height 0.5s;

    & > ${InputContainer} {
        margin-top: 25px;
        margin-bottom: 25px;
    }
`;
