import { ConfirmAppointmentRequest, Appointment, IAppointmentType, IStaffUnit } from '@ugmk/api';
import AppointmentFilters from 'models/appointment/AppointmentFilters';
import { Action } from 'redux';

export class GetAppointmentsAction implements Action {
    public static readonly Name = '[Appointment] Get Appointments';
    readonly type = GetAppointmentsAction.Name;
    constructor(public currentStaffUnit: IStaffUnit) {}
}

export class GetAppointmentsSuccessAction implements Action {
    public static readonly Name = '[Appointment] Get Appointments Success';
    readonly type = GetAppointmentsSuccessAction.Name;
    constructor(public data: Appointment[]) {}
}

export class GetAppointmentsErrorAction implements Action {
    public static readonly Name = '[Appointment] Get Appointments Error';
    readonly type = GetAppointmentsErrorAction.Name;
    constructor(public error: any) {}
}

export class UpdateAppointmentQuantityAction implements Action {
    public static readonly Name = '[Appointment] Update Appointment Quantity';
    readonly type = UpdateAppointmentQuantityAction.Name;
    constructor(public appointmentId: number) {}
}

export class GetAppointmentTypesAction implements Action {
    public static readonly Name = '[Appointment] Get Appointment Types';
    readonly type = GetAppointmentTypesAction.Name;
    constructor(public currentStaffUnit: IStaffUnit) {}
}

export class GetAppointmentTypesSuccessAction implements Action {
    public static readonly Name = '[Appointment] Get Appointment Types Success';
    readonly type = GetAppointmentTypesSuccessAction.Name;
    constructor(public data: IAppointmentType[]) {}
}

export class GetAppointmentTypesErrorAction implements Action {
    public static readonly Name = '[Appointment] Get Appointment Types Error';
    readonly type = GetAppointmentTypesErrorAction.Name;
    constructor(public error: any) {}
}

export class SetAppointmentFiltersAction implements Action {
    public static readonly Name = '[Appointment] Set Appointment Filters';
    readonly type = SetAppointmentFiltersAction.Name;
    constructor(public filters: Partial<AppointmentFilters>, public currentStaffUnit: IStaffUnit) {}
}

export class ConfirmAppointmentAction implements Action {
    public static readonly Name = '[Appointment] Confirm';
    readonly type = ConfirmAppointmentAction.Name;
    constructor(public data: ConfirmAppointmentRequest) {}
}

export class ConfirmAppointmentActionSuccess implements Action{
    public static readonly Name = '[Appointment] ConfirmAppointmentActionSuccess'
    readonly type = ConfirmAppointmentActionSuccess.Name;
}

export class ConfirmAppointmentActionError implements Action{
    public static readonly Name = '[Appointment] ConfirmAppointmentActionError'
    readonly type = ConfirmAppointmentActionError.Name;
}

export class LoadNextPageAppointmentsAction implements Action {
    public static readonly Name = '[Appointment] Load Next Page Appointments';
    readonly type = LoadNextPageAppointmentsAction.Name;
    constructor(public currentStaffUnit: IStaffUnit) {}
}

export class LoadNextPageAppointmentsSuccessAction implements Action {
    public static readonly Name = '[Appointment] Load Next Page Appointments Success';
    readonly type = LoadNextPageAppointmentsSuccessAction.Name;
    constructor(public nextPage: number, public newAppointments: Appointment[]) {}
}

export class ClearAppointmentsAction implements Action {
    public static readonly Name = '[Appointments] Clear Appointments';
    readonly type = ClearAppointmentsAction.Name;
}

export class FinishAppointmentsFetching implements Action {
    public static readonly Name = '[Appointments] FinishAppointmentsFetching';
    readonly type = FinishAppointmentsFetching.Name;
}

export class StartAppointmentsFetching implements Action {
    public static readonly Name = '[Appointments] StartAppointmentsFetching';
    readonly type = StartAppointmentsFetching.Name;
}

export type AppointmentsActions =
    | GetAppointmentsAction
    | GetAppointmentsSuccessAction
    | GetAppointmentsErrorAction
    | UpdateAppointmentQuantityAction
    | GetAppointmentTypesAction
    | GetAppointmentTypesSuccessAction
    | GetAppointmentTypesErrorAction
    | SetAppointmentFiltersAction
    | ConfirmAppointmentAction
    | LoadNextPageAppointmentsAction
    | LoadNextPageAppointmentsSuccessAction
    | ClearAppointmentsAction
    | FinishAppointmentsFetching
    | StartAppointmentsFetching
    | ConfirmAppointmentActionSuccess
    | ConfirmAppointmentActionError
