import React from 'react';

export interface IconProps {
    type: IconType;
    color?: string;
    onClick?: (event: React.MouseEvent) => void;
}

export enum IconType {
    Close = 'close',
    Eye = 'eye',
    Logo = 'logo',
    Arrow = 'arrow',
    Heart = 'heart',
    Calendar = 'calendar',
    BigCalendar = 'big-calendar',
    People = 'people',
    Doors = 'doors',
    Search = 'search',
    Expand = 'expand',
    PatientCard = 'patient-card',
    List = 'list',
    SquareList = 'square-list',
    Angle = 'angle',
    Check = 'check',
    VerticalDots = 'vertical-dots',
    Trash = 'trash',
    Telegram = 'telegram',
}
