import { TemplateType } from '@ugmk/api';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import { CloseTemplateSelectionScreen, GetTemplates } from 'pages/main-app/appointment/store/appointment.actions';
import { currentAppointmentSelector, templatesSelector } from 'pages/main-app/appointment/store/appointment.selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'styles/colors';
import { useTemplateSelectionContext } from '../../provider/template-selection-provider';
import { TemplateSelectionStep } from '../../types/template-selection.types';
import * as S from './template-list.styles';

const TemplateList: React.FC = () => {
    const dispatch = useDispatch();
    const { currentStep, changeStep, selectTemplateItem } = useTemplateSelectionContext();
    const templates = useSelector(templatesSelector);
    const appointment = useSelector(currentAppointmentSelector);

    useEffect(() => {
        // TODO: Кратенькую обработку
        if (!appointment) return;

        dispatch({ ...new GetTemplates(appointment.serviceType) });
    }, [appointment, dispatch]);

    const onSelectTemplateItem = (template: TemplateType) => {
        selectTemplateItem(template);
        changeStep(TemplateSelectionStep.Info);
    };

    if (currentStep !== TemplateSelectionStep.List) return null;

    return (
        <S.Container>
            <S.Header>
                <S.BackButton onClick={() => dispatch({ ...new CloseTemplateSelectionScreen() })}>
                    <Icon type={IconType.Arrow} />
                    <Typography type="span" color={Colors.Orange} marginBottom={0}>
                        Вернуться к карточке
                    </Typography>
                </S.BackButton>
            </S.Header>
            <S.Content>
                {templates.map((template) => (
                    <S.TemplateItem key={template._id} onClick={() => onSelectTemplateItem(template)}>
                        <Typography type="span" textAlign="left" marginBottom={0} size={16} weight="medium">
                            {template.title}
                        </Typography>
                        <Icon type={IconType.Angle} />
                    </S.TemplateItem>
                ))}
            </S.Content>
        </S.Container>
    );
};

export default TemplateList;
