import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/root.reducer';
import { ClearPatientCardPage, GetPatientById } from '../store/patient-card.actions';

export function usePatientById(patientId: string | null) {
    const dispatch = useDispatch();
    const patientState = useSelector((state: RootState) => state.patientCardPage.patient);

    useEffect(() => {
        if (!patientId) return;
        dispatch({ ...new GetPatientById(patientId) });

        return () => {
            dispatch({ ...new ClearPatientCardPage() });
        };
    }, [patientId, dispatch]);

    return { loadingPatient: patientState.loading, patient: patientState.data, errorPatient: patientState.error };
}
