import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { ApiResponse, GetPatientsRequest, IPatient, IPatientExtended, PatientApi } from '@ugmk/api';
import { back } from '@lagunovsky/redux-react-router';
import PatientCardResultsFilters from 'models/patient/PatientCardResultsFilters';
import PatientFilters from 'models/patient/PatientFilter';
import { ShowInfoModalAction } from 'store/common/info-modal/info-modal.actions';
import { RootState } from 'store/root.reducer';
import {
    GetPatientCardAction,
    GetPatientCardErrorAction,
    GetPatientCardForModalAction,
    GetPatientCardSuccessAction,
    GetPatientsAction,
    GetPatientsErrorAction,
    GetPatientsSuccessAction,
    LoadNextPagePatientsAction,
    LoadNextPagePatientsSuccessAction,
    LoadNextPageResultsPatientCardAction,
    LoadNextPageResultsPatientCardSuccessAction,
    SetCardResultsFiltersAction,
    SetPatientFiltersAction,
} from './patients.actions';

function* getPatientsHandler(
    action: GetPatientsAction | LoadNextPagePatientsAction | SetPatientFiltersAction,
): SagaIterator {
    let filters: PatientFilters = yield select((state: RootState) => state.patient.cardsFilters);

    if (action.type === SetPatientFiltersAction.Name) {
        filters = {
            ...filters,
            ...action.filters,
            page: 1,
        };
    } else if (action.type === LoadNextPagePatientsAction.Name) {
        filters = {
            ...filters,
            page: filters.page + 1,
        };
    } else {
        filters = {
            ...filters,
            page: 1,
        };
    }

    const request: GetPatientsRequest = {
        ...filters,
        medDepId: action.medDepId,
    };

    const { data, error }: ApiResponse<IPatient[]> = yield call(PatientApi.getPatients, request);

    if (!error) {
        if (action.type === LoadNextPagePatientsAction.Name) {
            yield put({ ...new LoadNextPagePatientsSuccessAction(data) });
        } else {
            yield put({ ...new GetPatientsSuccessAction(data) });
        }
    } else {
        console.error('Get Patients Error: ', error);
        yield put({ ...new GetPatientsErrorAction(error) });
    }
}

function* getPatientCardHandler(
    action:
        | GetPatientCardAction
        | GetPatientCardForModalAction
        | LoadNextPageResultsPatientCardAction
        | SetCardResultsFiltersAction,
): SagaIterator {
    let filters: PatientCardResultsFilters = yield select((state: RootState) => state.patient.cardResultsFilters);

    if (action.type === SetCardResultsFiltersAction.Name || action.type === GetPatientCardForModalAction.Name) {
        filters = {
            ...filters,
            ...action.filters,
            page: 1,
        };
    } else if (action.type === LoadNextPageResultsPatientCardAction.Name) {
        filters = {
            ...filters,
            page: filters.page + 1,
        };
    } else {
        filters = {
            ...filters,
            page: 1,
        };
    }

    if (filters.patientId) {
        const { data, error }: ApiResponse<IPatientExtended> = yield call(
            PatientApi.getPatientCard,
            filters.patientId,
            { ...filters, medDepId: action.medDepId },
        );

        if (data) {
            if (action.type === LoadNextPageResultsPatientCardAction.Name) {
                yield put({ ...new LoadNextPageResultsPatientCardSuccessAction(data) });
            } else {
                yield put({ ...new GetPatientCardSuccessAction(data) });
            }
        } else {
            if (error?.response?.status === 404) {
                yield put({
                    ...new ShowInfoModalAction({
                        contentMessage: 'Для данного пациента еще не было создано ни одного случая посещения',
                        buttonText: 'OK',
                    }),
                });
                //if (action.type === GetPatientCardAction.Name) {
                yield put(back());
                //}
            }
            yield put({ ...new GetPatientCardErrorAction(error) });
        }
    } else {
        console.error('Incorrect patient id');
    }
}

export default function* patientsSaga(): SagaIterator {
    yield takeLatest(GetPatientsAction.Name, getPatientsHandler);
    yield takeLatest(LoadNextPagePatientsAction.Name, getPatientsHandler);
    yield takeLatest(SetPatientFiltersAction.Name, getPatientsHandler);

    yield takeLatest(GetPatientCardAction.Name, getPatientCardHandler);
    yield takeLatest(SetCardResultsFiltersAction.Name, getPatientCardHandler);
    yield takeLatest(GetPatientCardForModalAction.Name, getPatientCardHandler);
    yield takeLatest(LoadNextPageResultsPatientCardAction.Name, getPatientCardHandler);
}
