import { CreateTemplateRequest, ServiceType } from '..';
import { TemplateType } from '../models/template/template-type';
import Api from '../utils/api';

export class TemplateApi {
    static createTemplate(req: CreateTemplateRequest) {
        return Api.post<TemplateType>('/cardTemplates', { ...req });
    }

    static getTemplates(serviceType: ServiceType) {
        return Api.get<TemplateType[]>('/cardTemplates', { params: { serviceType } });
    }

    static deleteTemplateById(templateId: string) {
        return Api.delete(`/cardTemplates/${templateId}`);
    }
}
