import { takeLatest } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { call, put } from 'redux-saga/effects';
import { HandbooksApi, Result, ApiResponse, Outcome, Diagnosis } from '@ugmk/api';
import {
    GetDiagnosesByParent,
    GetDiagnosesByParentError,
    GetDiagnosesByParentSuccess,
    GetDiagnosesBySearchString,
    GetDiagnosesBySearchStringError,
    GetDiagnosesBySearchStringSuccess,
    GetOutcomes,
    GetOutcomesError,
    GetOutcomesSuccess,
    GetResults,
    GetResultsError,
    GetResultsSuccess,
} from './handbooks.actions';

function* getResultsHandler(action: GetResults): SagaIterator {
    const { data, error }: ApiResponse<Result[]> = yield call(HandbooksApi.getResults, action.medDepId);

    if (!error) {
        yield put({ ...new GetResultsSuccess(data) });
    } else {
        yield put({ ...new GetResultsError(error) });
    }
}

function* getOutcomesHandler(action: GetOutcomes): SagaIterator {
    const { data, error }: ApiResponse<Outcome[]> = yield call(HandbooksApi.getOutcomes, action.medDepId);

    if (!error) {
        yield put({ ...new GetOutcomesSuccess(data) });
    } else {
        yield put({ ...new GetOutcomesError(error) });
    }
}

function* getDiagnosesBySearchStringHandler(action: GetDiagnosesBySearchString): SagaIterator {
    const { data, error }: ApiResponse<Diagnosis[]> = yield call(HandbooksApi.getDiagnosesBySearchString, {
        medDepId: action.medDepId,
        searchString: action.searchString,
        page: 1,
    });

    if (!error) {
        yield put({ ...new GetDiagnosesBySearchStringSuccess(data) });
    } else {
        yield put({ ...new GetDiagnosesBySearchStringError(error) });
    }
}

function* getDiagnosesByParentHandler(action: GetDiagnosesByParent): SagaIterator {
    const { data, error }: ApiResponse<Diagnosis[]> = yield call(HandbooksApi.getDiagnosesByParent, {
        medDepId: action.medDepId,
        parentId: action.parentId,
        page: 1,
    });

    if (!error) {
        yield put({ ...new GetDiagnosesByParentSuccess(data) });
    } else {
        yield put({ ...new GetDiagnosesByParentError(error) });
    }
}

export default function* handbooksSaga(): SagaIterator {
    yield takeLatest(GetResults.Name, getResultsHandler);
    yield takeLatest(GetOutcomes.Name, getOutcomesHandler);
    yield takeLatest(GetDiagnosesBySearchString.Name, getDiagnosesBySearchStringHandler);
    yield takeLatest(GetDiagnosesByParent.Name, getDiagnosesByParentHandler);
}
