import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const DiagnosisItemContainer = styled.div`
    padding: 20px;
    background-color: ${Colors.White};
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
