import MainLayout from 'components/layouts/MainLayout';
import PreContentLayout from 'components/layouts/PreContentLayout';
import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import AppointmentHeader from './components/appointment-header/appointment-header.component';
import { ClearAppointmentPageStore, OpenTemplateSelectionScreen } from './store/appointment.actions';
import * as S from './appointment.styles';
import { Button } from 'components/base/button';
import AppointmentForm from './components/appointment-form/appointment-form.component';
import { IconType } from 'components/base/icon/types';
import { AppointmentPageType } from 'models/appointment/appointment-page-type';
import TemplateSelectionScreen from './components/template-selection-screen/template-selection-screen.component';
import { CheckupFormData } from './components/appointment-form/checkup-form/checkup-form.component';
import { LFKFormData } from './components/appointment-form/lfk-form/lfk-form.component';
import { LocalStorageService } from 'helpers/local-storage-service/local-storage-service';
import { LocalStorageKey } from 'helpers/local-storage-service/local-storage-service.types';
import { useAppointmentPrefilledFormState } from './hooks/use-appointment-form-state';
import { useAppointmentByParams } from './hooks/use-appointment-by-id';
import { ShowInfoModalAction } from 'store/common/info-modal/info-modal.actions';
import { PatientPageLocationState } from '../patient/patient.component';

type AppointmentPageRouteParams = Record<'id', string>;

interface Props {
    pageType: AppointmentPageType;
}

const AppointmentPage: React.FC<Props> = ({ pageType }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageParams = useParams<AppointmentPageRouteParams>();
    const { appointment, isLoadingAppointment } = useAppointmentByParams(pageParams.id, pageType);

    const { prefilledFormState, clearPrefilledFormState } = useAppointmentPrefilledFormState();
    const currentFormStateRef = useRef<CheckupFormData | LFKFormData | null>(null);

    useEffect(() => {
        return () => {
            dispatch({ ...new ClearAppointmentPageStore() });
        };
    }, [dispatch]);

    const onFormChange = useCallback(
        (formState: CheckupFormData | LFKFormData) => {
            currentFormStateRef.current = formState;
        },
        [currentFormStateRef],
    );

    const openTemplateSelectionScreen = () => {
        if (appointment) {
            dispatch({ ...new OpenTemplateSelectionScreen(appointment.serviceType) });
        }
    };

    const onCardPatientButtonClick = () => {
        if (appointment) {
            LocalStorageService.setItem(LocalStorageKey.SAVED_APPOINTMENT_FORM_DATA, currentFormStateRef.current);
            navigate(generatePath(AppRoutes.Patient, { id: appointment.patient.patientId }), {
                state: {
                    fromAppointment: true,
                    serviceType: appointment?.serviceType,
                    oldAppointmentState: currentFormStateRef.current,
                } as PatientPageLocationState,
            });
        } else {
            dispatch({
                ...new ShowInfoModalAction({ title: 'Appointment is undefined', buttonText: 'OK', contentMessage: '' }),
            });
        }
    };

    return (
        <MainLayout>
            <PreContentLayout isLoading={isLoadingAppointment}>
                <MainLayout.Header isShowSearchButton={false}>
                    {appointment && <AppointmentHeader appointment={appointment} />}
                </MainLayout.Header>
                <S.Container>
                    <S.Content>
                        <Button
                            label="Выбрать шаблон"
                            size="medium"
                            theme="ghost"
                            onClick={openTemplateSelectionScreen}
                        />
                        <AppointmentForm
                            savedFormData={prefilledFormState}
                            onFormChange={onFormChange}
                            onClear={clearPrefilledFormState}
                        />
                        <Button
                            label="Карта и диагностика"
                            size="medium"
                            theme="ghost"
                            type="button"
                            side="left"
                            icon={IconType.PatientCard}
                            onClick={onCardPatientButtonClick}
                        />
                    </S.Content>
                </S.Container>
            </PreContentLayout>
            <MainLayout.MenuNavigation />
            <TemplateSelectionScreen />
        </MainLayout>
    );
};

export default AppointmentPage;
