import React, { useEffect } from 'react';
import ModalBase from 'components/base/ModalBase';
import Typography from 'components/base/Typography';
import { Button } from 'components/base/button';
import { InfoModalContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/root.reducer';
import { ClearInfoModalAction } from 'store/common/info-modal/info-modal.actions';

const InfoModal: React.FC = () => {
    const dispatch = useDispatch();
    const infoModalState = useSelector((root: RootState) => root.common.infoModal);

    useEffect(() => {
        return () => {
            dispatch({ ...new ClearInfoModalAction() });
        };
    }, [dispatch]);

    const onClose = () => {
        infoModalState.onClose && infoModalState.onClose();
        dispatch({ ...new ClearInfoModalAction() });
    };

    return (
        <ModalBase isOpen={infoModalState.isShow} onHide={onClose}>
            <InfoModalContainer>
                {infoModalState.title && (
                    <Typography type="h2" size={24} weight="semibold">
                        {infoModalState.title}
                    </Typography>
                )}
                <Typography type="p" size={14} weight="regular">
                    {infoModalState.contentMessage}
                </Typography>
                <Button label={infoModalState.buttonText} onClick={onClose} />
            </InfoModalContainer>
        </ModalBase>
    );
};

export default InfoModal;
