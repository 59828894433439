import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogInAction } from 'store/auth/auth.actions';

export function useLinkLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlParams = useMemo(() => {
        return new URLSearchParams(window.location.search);
    }, []);
    const link = useMemo(() => urlParams.get('link'), [urlParams]);
    const isForbiddenLogin = !Boolean(link);

    useEffect(() => {
        if (link) {
            dispatch({ ...new LogInAction('link', link) });
            urlParams.delete('link');
            navigate({
                search: urlParams.toString(),
            });
        }
    }, [dispatch, navigate, link, urlParams]);

    return isForbiddenLogin;
}
