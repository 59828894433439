import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

let Api: AxiosInstance = axios.create();

export interface ApiResponse<T> extends AxiosResponse {
    data: T;
    error?: AxiosError;
}

export const initAxios = (baseUrl: string, errorHandler: (error: AxiosError) => void) => {
    Api.defaults.baseURL = baseUrl;
    Api.defaults.withCredentials = true;
    Api.defaults.responseType = 'json';

    Api.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            errorHandler && errorHandler(error);
            return Promise.resolve({ error });
        }
    );
};

export const clearAuthHeaders = () => {
    delete Api.defaults.headers.common['Authorization'];
};

export const setAuthHeaders = (token: string) => {
    clearAuthHeaders();
    if (token) {
        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
};

export default Api;
