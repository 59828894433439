import { Diagnosis } from '@ugmk/api';
import { CheckupFormData } from 'pages/main-app/appointment/components/appointment-form/checkup-form/checkup-form.component';
import { LFKFormData } from 'pages/main-app/appointment/components/appointment-form/lfk-form/lfk-form.component';

export enum LocalStorageKey {
    SAVED_APPOINTMENT_FORM_DATA = 'SAVED_APPOINTMENT_FORM_DATA',
    SAVED_DIAGNOSIS_FORM_DATA = 'SAVED_DIAGNOSIS_FORM_DATA',
    STAFF_UNIT_MEDDEP_ID = 'STAFF_UNIT_MEDDEP_ID',
}

export type LocalStorageValue<T extends LocalStorageKey> = T extends LocalStorageKey.SAVED_APPOINTMENT_FORM_DATA
    ? CheckupFormData | LFKFormData | null
    : T extends LocalStorageKey.SAVED_DIAGNOSIS_FORM_DATA
    ? Diagnosis | null
    : T extends LocalStorageKey.STAFF_UNIT_MEDDEP_ID
    ? string | null
    : unknown;
