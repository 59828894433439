import { IPatient } from '@ugmk/api';
import { Action } from 'redux';

export class GetPatientById implements Action {
    public static readonly Name = '[Patient Page] GetPatientById';
    readonly type = GetPatientById.Name;
    constructor(public patientId: string) {}
}

export class GetPatientByIdSuccess implements Action {
    public static readonly Name = '[Patient Page] GetPatientByIdSuccess';
    readonly type = GetPatientByIdSuccess.Name;
    constructor(public data: IPatient) {}
}

export class GetPatientByIdError implements Action {
    public static readonly Name = '[Patient Page] GetPatientByIdError';
    readonly type = GetPatientByIdError.Name;
    constructor(public error: string) {}
}

export class ClearPatientPage implements Action {
    public static readonly Name = '[Patient Page] ClearPatientPage';
    readonly type = ClearPatientPage.Name;
}

export type PatientPageActions = GetPatientById | GetPatientByIdSuccess | GetPatientByIdError | ClearPatientPage;
