import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const MENU_NAVIGATION_HEIGHT = '70px';

export const MenuNavigationContainer = styled.div`
    width: 100%;
    height: ${MENU_NAVIGATION_HEIGHT};
    background-color: ${Colors.White};
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -4px 193px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25px;
    z-index: 600;
`;
