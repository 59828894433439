import { useRef, useState } from 'react';
import { Diagnosis } from '@ugmk/api';

export type SetDiagnosisCb = (diagnosis: Diagnosis) => void;

export function useDiagnosisSearchState() {
    const [isDiagnosisScreenOpen, setOpen] = useState<boolean>(false);
    const setDiagnosisCbRef = useRef<SetDiagnosisCb | null>(null);

    const openDiagnosisScreen = (setDiagnosisCb: SetDiagnosisCb) => {
        setOpen(true);
        setDiagnosisCbRef.current = setDiagnosisCb;
    };

    const closeDiagnosisScreen = () => {
        setOpen(false);
        setDiagnosisCbRef.current = null;
    };

    const setDiagnosisToCard = (diagnosis: Diagnosis) => {
        const setDiagnosisCb = setDiagnosisCbRef.current;

        if (setDiagnosisCb) setDiagnosisCb(diagnosis);
    };

    return { isDiagnosisScreenOpen, openDiagnosisScreen, closeDiagnosisScreen, setDiagnosisToCard };
}
