import React from 'react';
import { IconProps, IconType } from './types';
import { ReactComponent as Close } from 'assets/icons/ic-close.svg';
import { ReactComponent as Eye } from 'assets/icons/ic-eye.svg';
import { ReactComponent as Logo } from 'assets/icons/ic-logo.svg';
import { ReactComponent as Arrow } from 'assets/icons/ic-arrow.svg';
import { ReactComponent as Heart } from 'assets/icons/ic-heart.svg';
import { ReactComponent as Calendar } from 'assets/icons/ic-calendar.svg';
import { ReactComponent as BigCalendar } from 'assets/icons/ic-calendar-big.svg';
import { ReactComponent as People } from 'assets/icons/ic-people.svg';
import { ReactComponent as Doors } from 'assets/icons/ic-doors.svg';
import { ReactComponent as Search } from 'assets/icons/ic-search.svg';
import { ReactComponent as Expand } from 'assets/icons/ic-expand.svg';
import { ReactComponent as PatientCard } from 'assets/icons/ic-patient-card.svg';
import { ReactComponent as List } from 'assets/icons/ic-list.svg';
import { ReactComponent as SquareList } from 'assets/icons/ic-square-list.svg';
import { ReactComponent as Angle } from 'assets/icons/ic-angle.svg';
import { ReactComponent as Check } from 'assets/icons/ic-check.svg';
import { ReactComponent as Trash } from 'assets/icons/ic-trash.svg';
import { ReactComponent as VerticalDots } from 'assets/icons/ic-vertical-dots.svg';
import { ReactComponent as Telegram } from 'assets/icons/ic-telegram.svg';

const Icon: React.FC<IconProps> = (props) => {
    const { type, ...otherProps } = props;
    switch (type) {
        case IconType.Close:
            return <Close {...otherProps} />;
        case IconType.Eye:
            return <Eye {...otherProps} />;
        case IconType.Logo:
            return <Logo {...otherProps} />;
        case IconType.Arrow:
            return <Arrow {...otherProps} />;
        case IconType.Heart:
            return <Heart {...otherProps} />;
        case IconType.Calendar:
            return <Calendar {...otherProps} />;
        case IconType.BigCalendar:
            return <BigCalendar {...otherProps} />;
        case IconType.People:
            return <People {...otherProps} />;
        case IconType.Doors:
            return <Doors {...otherProps} />;
        case IconType.Search:
            return <Search {...otherProps} />;
        case IconType.Expand:
            return <Expand {...otherProps} />;
        case IconType.PatientCard:
            return <PatientCard {...otherProps} />;
        case IconType.List:
            return <List {...otherProps} />;
        case IconType.SquareList:
            return <SquareList {...otherProps} />;
        case IconType.Angle:
            return <Angle {...otherProps} />;
        case IconType.Check:
            return <Check {...otherProps} />;
        case IconType.Trash:
            return <Trash {...otherProps} />;
        case IconType.VerticalDots:
            return <VerticalDots {...otherProps} />;
        case IconType.Telegram:
            return <Telegram {...otherProps} />;
        default:
            return null;
    }
};

export default Icon;
