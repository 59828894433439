import { MENU_NAVIGATION_HEIGHT } from 'components/layouts/MainLayout/components/MenuNavigation/styles';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.DarkWhite};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - ${MENU_NAVIGATION_HEIGHT});
    z-index: 600;
`;
