import React, {useRef} from 'react';
import {Page, Document, pdfjs} from 'react-pdf';
import {DocumentModalStyles as S} from './document-page.styles';
import Typography from 'components/base/Typography';
import {useDocumentFile} from './hooks/use-document-blob';
import {useDocumentPagesCount} from './hooks/use-document-pages-count';
import MainLayout from "components/layouts/MainLayout";
import {BackButton} from "components/partial/back-button";
import {useParams} from "react-router";
import {useDocumentModalWidth} from "./hooks/use-document-modal-width";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type DocumentPageParams = Record<'documentId', string>;

const DocumentPage: React.FC = () => {
    const {documentId} = useParams<DocumentPageParams>()
    const docContainerRef = useRef<HTMLDivElement>(null);
    const docWidth = useDocumentModalWidth(docContainerRef);
    const {data: documentData} = useDocumentFile(documentId || null);
    const pagesCount = useDocumentPagesCount(documentData || null);

    return (
        <MainLayout>
            <MainLayout.Header isShowSearchButton={false}>
                <BackButton label="Вернуться назад" withIcon={true} styles={{marginBottom: 20}}/>
                <Typography type={'h1'} textAlign="left">Дигностика</Typography>
            </MainLayout.Header>
            <S.Container ref={docContainerRef}>
                {documentData && (
                    <S.DocumentContainer>
                        <Document file={documentData}>
                            {[...Array(pagesCount).keys()].map((_, idx) => (
                                <Page key={idx} pageNumber={idx + 1} width={docWidth} scale={1}
                                      renderTextLayer={false}/>
                            ))}
                        </Document>
                    </S.DocumentContainer>
                )}
            </S.Container>
            <MainLayout.MenuNavigation/>
        </MainLayout>
    );
};

export default DocumentPage;
