import { AnalysisResult } from '@ugmk/api';
import {
    GetAnalysisFileById,
    GetAnalysisFileByIdError,
    GetAnalysisFileByIdSuccess,
    GetAnalysisResults,
    GetAnalysisResultsError,
    GetAnalysisResultsSuccess,
    LoadNewPageAnalysisResults,
    LoadNewPageAnalysisResultsSuccess,
    PatientAnalyzesPageActions,
} from './patient-analyzes.actions';

export interface PatientAnalyzesPageState {
    analysisResults: {
        loading: boolean;
        data: AnalysisResult[];
        page: number;
        isEnd: boolean;
        error?: string;
    };
    analysisFile: {
        loading: boolean;
        data?: Blob;
        error?: string;
    };
}

const initialState: PatientAnalyzesPageState = {
    analysisResults: {
        loading: false,
        page: 1,
        isEnd: false,
        data: [],
    },
    analysisFile: {
        loading: false,
    },
};

export function patientAnalyzesPageReducer(
    state = initialState,
    action: PatientAnalyzesPageActions,
): PatientAnalyzesPageState {
    switch (action.type) {
        case GetAnalysisResults.Name: {
            return {
                ...state,
                analysisResults: {
                    ...state.analysisResults,
                    loading: true,
                    error: undefined,
                },
            };
        }

        case GetAnalysisResultsSuccess.Name: {
            return {
                ...state,
                analysisResults: {
                    ...state.analysisResults,
                    loading: false,
                    data: action.data,
                    isEnd: action.data.length < 10,
                },
            };
        }

        case GetAnalysisResultsError.Name: {
            return {
                ...state,
                analysisResults: {
                    data: [],
                    page: 1,
                    isEnd: false,
                    loading: false,
                    error: action.error,
                },
            };
        }

        case LoadNewPageAnalysisResults.Name: {
            return {
                ...state,
                analysisResults: {
                    ...state.analysisResults,
                    page: state.analysisResults.page + 1,
                },
            };
        }

        case LoadNewPageAnalysisResultsSuccess.Name: {
            return {
                ...state,
                analysisResults: {
                    ...state.analysisResults,
                    data: [...state.analysisResults.data, ...action.data],
                    isEnd: action.data.length < 10,
                },
            };
        }

        case GetAnalysisFileById.Name: {
            return {
                ...state,
                analysisFile: {
                    loading: true,
                    error: undefined,
                },
            };
        }

        case GetAnalysisFileByIdSuccess.Name: {
            return {
                ...state,
                analysisFile: {
                    loading: false,
                    data: action.file,
                },
            };
        }

        case GetAnalysisFileByIdError.Name: {
            return {
                ...state,
                analysisFile: {
                    loading: false,
                    error: action.error,
                },
            };
        }
        default: {
            return state;
        }
    }
}
