import { yupResolver } from '@hookform/resolvers/yup';
import { LfkTemplate } from '@ugmk/api';
import { Button } from 'components/base/button';
import { Grid } from 'components/base/grid/grid.styles';
import { InputText, Textarea } from 'components/base/input';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import * as S from './lfk-form.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store/root.reducer';

export type LFKFormData = {
    complaints: string;
    program: string;
    progression: string;
    APbefore: number;
    APafter: number;
};

const schema = yup.object().shape({
    complaints: yup.string().nullable(),
    program: yup.string().nullable(),
    progression: yup.string().nullable(),
    APbefore: yup
        .number()
        .nullable()
        .max(999, 'Максимальное значение 999')
        .min(0, 'Минимальное значение 0')
        .transform((value: number, originalValue: string) => {
            return originalValue === '' ? undefined : value;
        }),
    APafter: yup
        .number()
        .nullable()
        .max(999, 'Максимальное значение 999')
        .min(0, 'Минимальное значение 0')
        .transform((value: number, originalValue: string) => {
            return originalValue === '' ? undefined : value;
        }),
});

interface Props {
    savedFormData?: LFKFormData | null;
    selectedTemplate?: LfkTemplate;
    onConfirm: (data: LFKFormData) => void;
    onFormChange?: (data: LFKFormData) => void;
    onClear: () => void;
}

const LfkForm: React.FC<Props> = ({ onConfirm, onFormChange, savedFormData, selectedTemplate, onClear }) => {
    const isConfirmPending = useSelector((root: RootState) => root.appointments.isConfirmPending);

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<LFKFormData>({
        resolver: yupResolver(schema),
    });

    const formStateValuesMap = watch();
    useEffect(() => {
        if (onFormChange) {
            onFormChange(formStateValuesMap);
        }
    }, [formStateValuesMap, onFormChange]);

    useEffect(() => {
        if (!selectedTemplate) return;

        setValue('complaints', selectedTemplate.complaints || '');
        setValue('program', selectedTemplate.program || '');
        setValue('progression', selectedTemplate.progression || '');
        setValue('APafter', Number(selectedTemplate.APafter) || 0);
        setValue('APbefore', Number(selectedTemplate.APbefore) || 0);
    }, [selectedTemplate, setValue]);

    const onConfirmHandler = handleSubmit((data) => {
        onConfirm(data);
        onClear();
        reset({
            complaints: '',
            program: '',
            progression: '',
            APafter: 0,
            APbefore: 0,
        });
    });

    return (
        <S.Form>
            <Controller
                name="complaints"
                control={control}
                defaultValue={selectedTemplate?.complaints || savedFormData?.complaints || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Жалобы" />;
                }}
            />

            <Controller
                name="program"
                control={control}
                defaultValue={selectedTemplate?.program || savedFormData?.program || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Программы" />;
                }}
            />

            <Controller
                name="progression"
                control={control}
                defaultValue={selectedTemplate?.progression || savedFormData?.progression || ''}
                render={({ field }) => {
                    return <Textarea disabled={isConfirmPending} {...field} label="Прогрессия" />;
                }}
            />

            <Grid.Row style={{ marginBottom: 30 }}>
                <InputText
                    disabled={isConfirmPending}
                    {...register}
                    name="APbefore"
                    label="АД до"
                    type="number"
                    defaultValue={selectedTemplate?.APbefore || savedFormData?.APbefore || undefined}
                    error={errors.APbefore?.message}
                />
                <S.Space style={{ width: 15 }} />
                <InputText
                    disabled={isConfirmPending}
                    {...register}
                    name="APafter"
                    label="АД После"
                    type="number"
                    defaultValue={selectedTemplate?.APafter || savedFormData?.APafter || undefined}
                    error={errors.APafter?.message}
                />
            </Grid.Row>
            {isConfirmPending ? (
                <Button disabled load={true} />
            ) : (
                <Button label="Подтвердить услугу" size="medium" onClick={onConfirmHandler} />
            )}
        </S.Form>
    );
};

export default React.memo(LfkForm);
