import { createSelector } from 'reselect';
import { RootState } from 'store/root.reducer';

export const patientAnalyzesPageSelector = (state: RootState) => state.patientAnalyzesPage;

export const patientAnalysisResultsSelector = createSelector(
    [patientAnalyzesPageSelector],
    (state) => state.analysisResults,
);

export const patientAnalysisFileSelector = createSelector([patientAnalyzesPageSelector], (state) => state.analysisFile);
