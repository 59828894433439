import styled from 'styled-components';
import { Colors } from 'styles/colors';
import { OverlayStyle } from 'styles/global-styles';

export const HeaderContainer = styled.div`
    background-color: ${Colors.White};
    padding: 20px 25px;
    box-shadow: 0px 4px 193px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 15px 15px;
    width: 100%;
`;

export const SearchButton = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.White};
    border-radius: 50%;
    width: 54px;
    height: 54px;
    box-shadow: 8px 7px 26px rgba(0, 0, 0, 0.04);
    position: absolute;
    bottom: -27px;
    left: calc(50% - 27px);
    transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    svg {
        width: 24px;
        height: 24px;

        path {
            fill: ${({ active }) => (active ? Colors.White : Colors.Orange)};
            stroke: ${({ active }) => (active ? Colors.Orange : 'inherit')};
        }
    }
`;

export const HeaderOverlay = styled.div<{ active: boolean }>`
    ${OverlayStyle};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${({ active }) => (active ? '100vh' : 0)};
    opacity: ${({ active }) => (active ? 1 : 0)};
    z-index: 400;
`;
