import styled from 'styled-components';
import { Colors } from 'styles/colors';
import LoginHeader from 'assets/images/login-background.svg';

export const AuthLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    background-color: ${Colors.White};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 4;
    margin-bottom: 40px;
    max-height: 310px;
    position: relative;
    background: url(${LoginHeader}) 0 0 no-repeat;
    background-size: 100% 100%;
    padding: 0 15px;
`;

export const MainContent = styled.div`
    padding: 0 20px;

    @media only screen and (min-width: 375px) {
        padding: 0 40px;
    }
`;

export const Footer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 30px;
`;
