import React from 'react';
import { LoaderContainer, LoaderSpinner } from './styles';
import { ReactComponent as LoaderIcon } from 'assets/icons/ic-loader.svg';

interface Props {
    fullScreen?: boolean;
    size?: number;
}

const Loader: React.FC<Props> = ({ fullScreen, size }) => {
    return (
        <LoaderContainer active={fullScreen}>
            <LoaderSpinner style={{ width: size, height: size }}>
                <LoaderIcon />
            </LoaderSpinner>
        </LoaderContainer>
    );
};

export default Loader;
