import React, { useState, useEffect } from 'react';
import * as Styles from './styles';

interface Props {
    label?: string;
    value?: boolean;
    disabled?: boolean;
    onChange: (isActive: boolean) => void;
}

const Switcher: React.FC<Props> = (props) => {
    const { label, value, disabled = false, onChange } = props;

    useEffect(() => {
        if (value !== undefined) {
            changeActive(value);
        }
    }, [value, onChange]);

    const [isActive, changeActive] = useState<boolean>(value !== undefined ? value : false);
    return (
        <Styles.SwitcherContainer
            onClick={() => {
                if (disabled) return;
                onChange(!isActive);
                changeActive((prevState) => !prevState);
            }}
        >
            <Styles.Label>{label}</Styles.Label>
            <Styles.Controller active={isActive} disabled={disabled}>
                <Styles.SwitchButton />
            </Styles.Controller>
        </Styles.SwitcherContainer>
    );
};

export default Switcher;
