export enum Colors {
    LightBlack = '#232427',
    Orange = '#F4662A',
    DarkBlue = '#2C9CE6',
    Blue = '#65B8EF',
    DarkGray = '#909EAA',
    Gray = '#D6E0E8',
    DarkWhite = '#F2F7FA',
    White = '#ffffff',
    Red = '#EB5757',
}
