import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogInAction } from 'store/auth/auth.actions';

export function useLinkLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlPrams = new URLSearchParams(window.location.search);
        const link = urlPrams.get('link');

        if (link) {
            dispatch({ ...new LogInAction('link', link) });
            urlPrams.delete('link');
            navigate({
                search: urlPrams.toString(),
            });
        }
    }, [dispatch, navigate]);
}
