import { CheckupTemplateLabelsMapType, LfkTemplateLabelsMapType } from './template-info.types';

export const CHECKUP_TEMPLATE_LABELS_MAP: CheckupTemplateLabelsMapType = {
    complaints: 'Жалобы',
    expertAnamnesis: 'Экспертный анамнез',
    diseaseHistory: 'История заболевания',
    objectively: 'Объективно',
    diagnosis: 'Код диагноза',
    diagnosisDescription: 'Диагноз',
    recommendations: 'Рекомендации',
    isClose: 'Случай закрыт',
    result: 'Результат',
    outcome: 'Исход',
};

export const LFK_TEMPLATE_LABELS_MAP: LfkTemplateLabelsMapType = {
    complaints: 'Жалобы',
    program: 'Программы',
    progression: 'Прогрессия',
    APbefore: 'АД До',
    APafter: 'АД После',
};
