import { AxiosError, CreateTemplateRequest, DiagnosticResultType, IPatient } from '@ugmk/api';
import { Action } from 'redux';

export class SaveAsTemplate implements Action {
    public static readonly Name = '[Patient Card Page] SaveAsTemplate';
    readonly type = SaveAsTemplate.Name;
    constructor(public request: CreateTemplateRequest) {}
}

export class SaveAsTemplateSuccess implements Action {
    public static readonly Name = '[Patient Card Page] SaveAsTemplateSuccess';
    readonly type = SaveAsTemplateSuccess.Name;
}

export class SaveAsTemplateError implements Action {
    public static readonly Name = '[Patient Card Page] SaveAsTemplateError';
    readonly type = SaveAsTemplateError.Name;
    constructor(public error: AxiosError) {}
}

export class OpenSaveAsTemplateModal implements Action {
    public static readonly Name = '[Patient Card Page] OpenSaveAsTemplateModal';
    readonly type = OpenSaveAsTemplateModal.Name;
    constructor(public saveRequest: DiagnosticResultType) {}
}

export class CloseSaveAsTemplateModal implements Action {
    public static readonly Name = '[Patient Card Page] CloseSaveAsTemplateModal';
    readonly type = CloseSaveAsTemplateModal.Name;
}

export class AddEmptyTemplateToSelectedTemplate implements Action {
    public static readonly Name = '[Patient Card Page] AddEmptyTemplateToSelectedTemplate';
    readonly type = AddEmptyTemplateToSelectedTemplate.Name;
    constructor(public diagnosticResult: DiagnosticResultType) {}
}

export class GetPatientById implements Action {
    public static readonly Name = '[Patient Card Page] GetPatientById';
    readonly type = GetPatientById.Name;
    constructor(public patientId: string) {}
}

export class GetPatientByIdSuccess implements Action {
    public static readonly Name = '[Patient Card Page] GetPatientByIdSuccess';
    readonly type = GetPatientByIdSuccess.Name;
    constructor(public data: IPatient) {}
}

export class GetPatientByIdError implements Action {
    public static readonly Name = '[Patient Card Page] GetPatientByIdError';
    readonly type = GetPatientByIdError.Name;
    constructor(public error: string) {}
}

export class ClearPatientCardPage implements Action {
    public static readonly Name = '[Patient Card Page] ClearPatientPage';
    readonly type = ClearPatientCardPage.Name;
}

export type PatientCardPageActions =
    | SaveAsTemplate
    | SaveAsTemplateSuccess
    | SaveAsTemplateError
    | OpenSaveAsTemplateModal
    | CloseSaveAsTemplateModal
    | AddEmptyTemplateToSelectedTemplate
    | GetPatientById
    | GetPatientByIdSuccess
    | GetPatientByIdError
    | ClearPatientCardPage;
