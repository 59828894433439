import { TextInputContainer } from 'components/base/input/styles';
import styled from 'styled-components';
import { ServiceTypeFilterContainer } from '../service-type-filter/styles';

export const SearchFormContainer = styled.div`
    ${TextInputContainer} {
        margin-bottom: 20px;
    }
    ${ServiceTypeFilterContainer} {
        margin-bottom: 30px;
    }
`;
