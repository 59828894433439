import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const DiagnosisScreenOpener = styled.div`
    width: 58px;
    height: 58px;
    background-color: ${Colors.Orange};
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 15px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
`;
