import Icon from 'components/base/icon';
import {IconType} from 'components/base/icon/types';
import {Link} from 'components/base/link';
import Typography from 'components/base/Typography';
import AuthLayout from 'components/layouts/AuthLayout';
import React from 'react';
import {AppRoutes} from 'router/routes';
import {LoginForm} from './components/login-form';
import styled from 'styled-components/macro';
import {Colors} from 'styles/colors';
import {useLinkLogin} from './hooks/use-link-login';

const HelpLink = styled.a`
  color: ${Colors.Orange};
  font-size: 14px;
  text-align: center;
  display: block;
  margin-top: 15px;
`;

const TelegramContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TelegramBlock = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 15px;
  margin-bottom: 15px;
  background: ${Colors.DarkWhite};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginPage: React.FC = () => {
    useLinkLogin();

    const renderHeaderContent = () => <Icon type={IconType.Logo}/>;

    const renderFooterContent = () => (
        <React.Fragment>
            {/* <Typography type="p" size={14}>
                Ещё нет аккаунта?
            </Typography>
            <Link to={AppRoutes.Register} icon={IconType.Arrow} iconSide="right">
                Зарегистрироваться
            </Link> */}
        </React.Fragment>
    );

    return (
        <AuthLayout headerContent={renderHeaderContent()} footerContent={renderFooterContent()}>
            {/* <LoginForm />
            <Link to={AppRoutes.ResetPassword}>Забыли пароль?</Link> */}
            <TelegramContainer>
                <TelegramBlock>
                    <Icon type={IconType.Telegram}/>
                </TelegramBlock>
            </TelegramContainer>
            <Typography type='p' size={24} weight="semibold">
                Авторизация осуществляется через Telegram-бота
            </Typography>
            <Typography type='p' size={14}>
                Обратитесь к системному администратору для предоставления доступа к системе
            </Typography>
            <HelpLink
                href="https://wiki.phardoc.com/ru/Врач/Поликлиника/Пользовательские_инструкции/Мобильное_приложение"
                rel="noopener noreferrer" target="_blank">
                Как пользоваться?
            </HelpLink>
        </AuthLayout>
    );
};
