import styled from 'styled-components';
import * as PatientResultCard from '../patient-result-card/styles';

export const PatientResultCardFeedContainer = styled.div`
    padding: 20px 25px 60px;
    flex: 1;
    overflow: auto;

    ${PatientResultCard.Container} {
        margin-top: 20px;

        &:last-of-type {
            margin-bottom: 20px;
        }
    }
`;
