import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import React from 'react';
import { Diagnosis } from '@ugmk/api';
import * as S from './diagnosis-item.styles';
import RadioButton from 'components/base/RadioButton';

interface Props {
    diagnosis: Diagnosis;
    isActive: boolean;
    onClick: () => void;
}

const DiagnosisItem: React.FC<Props> = ({ diagnosis, onClick, isActive }) => {
    return (
        <S.DiagnosisItemContainer onClick={onClick}>
            <S.Content>
                <Typography textAlign="left" type="p" marginBottom={5} weight="medium">
                    {diagnosis.title}
                </Typography>
                <Typography textAlign="left" type="span" size={14} weight="regular" marginBottom={0}>
                    {diagnosis.code}
                </Typography>
            </S.Content>
            {diagnosis.forbidden ? <Icon type={IconType.Angle} /> : <RadioButton isActive={isActive} />}
        </S.DiagnosisItemContainer>
    );
};

export default DiagnosisItem;
