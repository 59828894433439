import InfoModalModel from "models/common/InfoModalModel";
import { ClearInfoModalAction, InfoModalActions, ShowInfoModalAction } from "./info-modal.actions";

export interface InfoModalState extends InfoModalModel {
    isShow: boolean;
}

const initialState: InfoModalState = {
    isShow: false,
    title: undefined,
    contentMessage: '',
    onClose: undefined,
    buttonText: ''
}

export function infoModalReducer(state = initialState, action: InfoModalActions) {
    switch(action.type) {
        case ShowInfoModalAction.Name:
            return {
                ...initialState,
                ...action.options,
                isShow: true
            }

        case ClearInfoModalAction.Name:
            return {
                ...initialState
            }

        default:
            return state;
    }
}