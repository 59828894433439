import { IUser, RegisterStatus, IStaffUnit } from '@ugmk/api';
import { LocalStorageService } from 'helpers/local-storage-service/local-storage-service';
import { LocalStorageKey } from 'helpers/local-storage-service/local-storage-service.types';
import {
    AuthActions,
    LogInErrorAction,
    LogInAction,
    LogInSuccessAction,
    RegisterAction,
    RegisterSuccessAction,
    RegisterErrorAction,
    GetMeAction,
    GetMeSuccessAction,
    GetMeErrorAction,
    LogoutAction,
    LogoutSuccessAction,
    LogoutErrorAction,
    GetWaitingTimeCodeAction,
    GetWaitingTimeCodeSuccessAction,
    ConfirmCodeErrorAction,
    SetCurrentStaffUnitAction,
} from './auth.actions';

export interface AuthState {
    isLoading: boolean;
    isWaitingCodePending: boolean;
    isRegisterPending: boolean;
    isLogoutPending: boolean;
    userInfo?: IUser;
    currentStaffUnit?: IStaffUnit;
    authError?: string;
    isAuthorized: boolean;
    isInitialized: boolean;
    registerStatus?: RegisterStatus;
    waitingTimeCode?: number;
    confirmCodeError?: string;
    emailExistsError?: string;
    registerError?: string;
}

const initialState: AuthState = {
    isLoading: false,
    isWaitingCodePending: false,
    isRegisterPending: false,
    isLogoutPending: false,
    userInfo: undefined,
    currentStaffUnit: undefined,
    authError: undefined,
    isAuthorized: false,
    isInitialized: false,
    registerStatus: undefined,
    waitingTimeCode: undefined,
    confirmCodeError: undefined,
    registerError: undefined,
};

export function authReducer(state = initialState, action: AuthActions): AuthState {
    switch (action.type) {
        case GetMeSuccessAction.Name: {
            return {
                ...state,
                isInitialized: true,
                isLoading: false,
                isAuthorized: true,
                authError: undefined,
                userInfo: action.me,
            };
        }

        case GetMeErrorAction.Name: {
            return {
                ...state,
                isInitialized: true,
                isLoading: false,
                authError: action.error,
            };
        }

        case LogInAction.Name:
        case GetMeAction.Name: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case GetWaitingTimeCodeAction.Name: {
            return {
                ...state,
                isWaitingCodePending: true,
            };
        }

        case GetWaitingTimeCodeSuccessAction.Name: {
            return {
                ...state,
                isWaitingCodePending: false,
                waitingTimeCode: action.seconds,
            };
        }

        case LogInSuccessAction.Name: {
            return {
                ...state,
                isInitialized: true,
                isLoading: false,
                isAuthorized: true,
                authError: undefined,
                userInfo: action.user,
            };
        }

        case LogInErrorAction.Name: {
            return {
                ...state,
                isLoading: false,
                isInitialized: true,
                authError: action.error,
            };
        }

        case RegisterAction.Name: {
            return {
                ...initialState,
                isRegisterPending: true,
            };
        }

        case RegisterSuccessAction.Name: {
            return {
                ...state,
                isRegisterPending: false,
                isInitialized: true,
                registerStatus: action.status,
            };
        }

        case RegisterErrorAction.Name: {
            return {
                ...state,
                isRegisterPending: false,
                isInitialized: true,
                registerError: action.error,
                registerStatus: action.status,
            };
        }

        case LogoutAction.Name: {
            return {
                ...state,
                isLogoutPending: true,
            };
        }

        case LogoutSuccessAction.Name: {
            return {
                ...initialState,
                isLogoutPending: false,
                isInitialized: true,
            };
        }

        case LogoutErrorAction.Name: {
            return {
                ...state,
                isLogoutPending: false,
                authError: action.error,
            };
        }

        case ConfirmCodeErrorAction.Name: {
            return {
                ...state,
                confirmCodeError: action.error,
            };
        }

        case SetCurrentStaffUnitAction.Name: {
            LocalStorageService.setItem(LocalStorageKey.STAFF_UNIT_MEDDEP_ID, action.staffUnit.medDepId);
            return {
                ...state,
                currentStaffUnit: action.staffUnit,
            };
        }

        default:
            return state;
    }
}
