import { Colors } from 'styles/colors';
import { ButtonContainerProps } from './button.types';

export function getBackgroundColor(props: ButtonContainerProps) {
    switch (props.theme) {
        case 'filled':
            return !!props.disabled ? Colors.Gray : Colors.Orange;
        default:
            return Colors.White;
    }
}

export function getHeight(props: ButtonContainerProps) {
    switch (props.size) {
        case 'big':
            return '58px';
        case 'medium':
            return '48px';
        default:
            return '39px';
    }
}

export function getContentColor(props: ButtonContainerProps) {
    switch (props.theme) {
        case 'filled':
            return Colors.White;
        default:
            return !!props.disabled ? Colors.DarkGray : Colors.Orange;
    }
}
