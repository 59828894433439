import styled from 'styled-components/macro';
import { ServiceTypeFilterContainer } from '../service-type-filter/styles';

export const AppointmentSearchFormContainer = styled.div`
    overflow: hidden;
    transition: max-height 0.5s;

    & > * {
        margin-bottom: 20px;
        margin-top: 25px;

        &:last-of-type {
            margin-bottom: 25px;
        }
    }

    ${ServiceTypeFilterContainer} {
        margin-bottom: 30px;
    }
`;
