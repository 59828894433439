import PreContentLayout from 'components/layouts/PreContentLayout';
import usePaginateByScroll from 'hooks/usePaginateByScroll';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadNextPageResultsPatientCardAction } from 'store/patients/patients.actions';
import { RootState } from 'store/root.reducer';
import PatientResultCard from '../patient-result-card';
import SearchEmptyState from '../../../../../components/partial/search-empty-state';
import { PatientResultCardFeedContainer } from './styles';
import { PatientCardPageLocationState } from '../..';

interface Props {
    patientId?: string;
    medDepId?: string;
    fromAppointmentState?: PatientCardPageLocationState;
}

const PatientResultCardFeed: React.FC<Props> = ({ patientId, medDepId, fromAppointmentState }) => {
    const dispatch = useDispatch();
    const feedContainer = useRef<HTMLDivElement | null>(null);
    const isLoadingCard = useSelector((state: RootState) => state.patient.isLoadingCard);
    const patientResultCards = useSelector((state: RootState) => state.patient.patientCard?.diagnosticResults);
    const isEmpty = useMemo(
        () => !patientId || !medDepId || patientResultCards?.length === 0,
        [patientResultCards, patientId, medDepId],
    );

    usePaginateByScroll(feedContainer, () => {
        if (medDepId) {
            dispatch({ ...new LoadNextPageResultsPatientCardAction(medDepId) });
        }
    });

    return (
        <PatientResultCardFeedContainer ref={feedContainer}>
            <PreContentLayout isLoading={isLoadingCard}>
                {patientResultCards?.map((resultCard) => {
                    return (
                        <PatientResultCard
                            key={resultCard.id}
                            resultData={resultCard}
                            fromAppointmentState={fromAppointmentState}
                        />
                    );
                })}
                {isEmpty && <SearchEmptyState />}
            </PreContentLayout>
        </PatientResultCardFeedContainer>
    );
};

export default PatientResultCardFeed;
