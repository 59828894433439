import { Button } from 'components/base/button';
import { Grid } from 'components/base/grid/grid.styles';
import ModalBase from 'components/base/ModalBase';
import Typography from 'components/base/Typography';
import React from 'react';

interface Props {
    isOpened: boolean;
    onClose: () => void;
    onAccept: () => void;
}

const TemplateDeleteModal: React.FC<Props> = ({ isOpened, onAccept, onClose }) => {
    return (
        <ModalBase isOpen={isOpened} onHide={onClose}>
            <Typography type="h1" weight="semibold" size={24}>
                Вы уверены?
            </Typography>
            <Grid.Row style={{ justifyContent: 'space-between' }}>
                <Button label="Отмена" onClick={onClose} theme="ghost" style={{ marginRight: 7.5 }} />
                <Button label="Удалить" onClick={onAccept} style={{ marginLeft: 7.5 }} />
            </Grid.Row>
        </ModalBase>
    );
};

export default TemplateDeleteModal;
