import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const TemplateItem = styled.div`
    background-color: ${Colors.White};
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
        flex-shrink: 0;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    overflow-y: auto;

    ${TemplateItem} {
        margin-top: 15px;

        &:last-child {
            margin-bottom: 15px;
        }
    }
`;

export const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 12px;
        transform: rotate(180deg);
    }
`;

export const Header = styled.div`
    background-color: ${Colors.White};
    border-radius: 0 0 15px 15px;
    padding: 25px;
`;

export const Container = styled.div`
    display: flex;
    height: inherit;
    flex-direction: column;
`;
