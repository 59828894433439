import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import CodeConfirmPage from './code-confirmation';
import { LoginPage } from './login';
import { NewPasswordPage } from './new-password';
import { ResetPasswordPage } from './reset-password';
import { SignUpPage } from './sign-up';

const AuthPageSwitcher: React.FC = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Navigate to={AppRoutes.Login} replace={true} />} />
            <Route path={AppRoutes.Login} element={<LoginPage />} />
            {/* <Route path={AppRoutes.RegisterConfirm} element={<CodeConfirmPage />} />
            <Route path={AppRoutes.Register} element={<SignUpPage />} />
            <Route path={AppRoutes.ResetPasswordConfirm} element={<CodeConfirmPage />} />
            <Route path={AppRoutes.ResetPassword} element={<ResetPasswordPage />} />
            <Route path={AppRoutes.NewPassword} element={<NewPasswordPage />} /> */}
            <Route element={<div>Not Found</div>} />
        </Routes>
    );
};

export default AuthPageSwitcher;
