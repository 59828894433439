import * as Autocomplete from 'components/base/autocomplete/autocomplete.styles';
import { DropdownContainer } from 'components/base/dropdown';
import { InputContainer } from 'components/base/input/styles';
import { SwitcherContainer } from 'components/base/Switcher/styles';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${Colors.White};
    padding: 20px;
    border-radius: 15px;
    width: 100%;
`;

export const PatientCardButton = styled.button`
    transition: opacity 0.3s;
`;

export const Form = styled.form<{ active: boolean }>`
    overflow: hidden;
    height: auto;
    width: 100%;

    & > div:first-child {
        margin-top: 20px;
    }

    ${PatientCardButton} {
        margin-top: 20px;
        opacity: ${({ active }) => (active ? 1 : 0)};

        &:last-of-type {
            margin-top: 15px;
        }
    }

    & > ${InputContainer} {
        margin-bottom: 15px;
        transition: opacity 0.3s;
        opacity: ${({ active }) => (active ? 1 : 0)};

        &:first-of-type {
            margin-top: 20px;
        }
    }

    ${DropdownContainer} {
        margin-bottom: 15px;
    }
    ${SwitcherContainer}, ${Autocomplete.Container} {
        margin-bottom: 20px;
    }
`;

export const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -7.5px;

    ${InputContainer} {
        flex: 1;
        margin: 0 7.5px;

        input {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
`;
