import { Expander } from 'components/base/expander';
import { useMainLayoutState } from 'components/layouts/MainLayout/state/MainLayoutProvider';
import { AppointmentSearchForm, AppointmentSearchFormField } from 'components/partial/appointment-search-form';
import StuffUnitDropdown from 'components/partial/stuff-unit-dropdown';
import React from 'react';

const ServicePageHeader: React.FC = () => {
    const { headerActive, setHeaderActive } = useMainLayoutState();

    return (
        <>
            <StuffUnitDropdown />
            <Expander isActive={headerActive}>
                <AppointmentSearchForm fields={filterFields} onAfterSearch={() => setHeaderActive(false)} />
            </Expander>
        </>
    );
};

const filterFields: AppointmentSearchFormField = { searchByName: true, appointmentType: true, serviceType: true };

export default ServicePageHeader;
