import React from 'react';
import { ServiceType } from '@ugmk/api';
import * as Styles from './styles';

interface Props {
    value: ServiceType;
    onChange: (serviceType: ServiceType) => void;
}

const FilterValueNames = {
    [ServiceType.All]: 'Все',
    [ServiceType.Checkup]: 'Осмотр',
    [ServiceType.LFK]: 'ЛФК',
};

const ServiceTypeFilter: React.FC<Props> = ({ value, onChange }) => {
    return (
        <Styles.ServiceTypeFilterContainer>
            {[ServiceType.All, ServiceType.Checkup, ServiceType.LFK].map((serviceType) => (
                <Styles.FilterValue
                    active={value === serviceType}
                    key={serviceType}
                    onClick={() => onChange(serviceType)}
                >
                    {FilterValueNames[serviceType]}
                </Styles.FilterValue>
            ))}
        </Styles.ServiceTypeFilterContainer>
    );
};

export default ServiceTypeFilter;
