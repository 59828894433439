export enum AppRoutes {
    Service = '/service',
    Login = '/login',
    Register = '/register',
    RegisterConfirm = '/register/confirm',
    ResetPassword = '/password/reset',
    ResetPasswordConfirm = '/password/reset/confirm',
    NewPassword = '/password/new',
    Schedule = '/schedule',
    PatientList = '/patients',
    Patient = '/patients/:id',
    PatientCard = '/patients/:id/card',
    PatientAnalyzesList = '/patients/:id/analizes',
    PatientDocument = '/patients/:patientId/analizes/:documentId',
    AppointmentSchedule = '/appointment/schedule/:id',
    AppointmentService = '/appointment/service/:id',
}
