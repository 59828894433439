import styled from 'styled-components/macro';

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Grid = { Row, Col };
