import { AxiosError, DiagnosticResultType, IPatient } from '@ugmk/api';
import {
    ClearPatientCardPage,
    CloseSaveAsTemplateModal,
    GetPatientById,
    GetPatientByIdError,
    GetPatientByIdSuccess,
    OpenSaveAsTemplateModal,
    PatientCardPageActions,
    SaveAsTemplate,
    SaveAsTemplateError,
    SaveAsTemplateSuccess,
} from './patient-card.actions';

export interface PatientCardPageState {
    isLoadingSaveAsTemplate: boolean;
    errorSaveAsTemplate?: AxiosError;
    isSaveAsTemplateModalOpened: boolean;
    saveAsTemplateData?: DiagnosticResultType;
    patient: {
        loading: boolean;
        data?: IPatient;
        error?: string;
    };
}

const initialState: PatientCardPageState = {
    isLoadingSaveAsTemplate: false,
    errorSaveAsTemplate: undefined,
    isSaveAsTemplateModalOpened: false,
    saveAsTemplateData: undefined,
    patient: {
        loading: false,
    },
};

export function patientCardPageReducer(state = initialState, action: PatientCardPageActions): PatientCardPageState {
    switch (action.type) {
        case SaveAsTemplate.Name: {
            return {
                ...state,
                isLoadingSaveAsTemplate: true,
            };
        }

        case SaveAsTemplateSuccess.Name: {
            return {
                ...state,
                isLoadingSaveAsTemplate: false,
            };
        }

        case SaveAsTemplateError.Name: {
            return {
                ...state,
                isLoadingSaveAsTemplate: false,
                errorSaveAsTemplate: action.error,
            };
        }

        case OpenSaveAsTemplateModal.Name: {
            return {
                ...state,
                isSaveAsTemplateModalOpened: true,
                saveAsTemplateData: action.saveRequest,
            };
        }

        case CloseSaveAsTemplateModal.Name: {
            return {
                ...state,
                isSaveAsTemplateModalOpened: false,
                saveAsTemplateData: undefined,
            };
        }

        case GetPatientById.Name: {
            return {
                ...state,
                patient: {
                    ...state.patient,
                    loading: true,
                    error: undefined,
                },
            };
        }

        case GetPatientByIdSuccess.Name: {
            return {
                ...state,
                patient: {
                    ...state.patient,
                    loading: false,
                    data: action.data,
                },
            };
        }

        case GetPatientByIdError.Name: {
            return {
                ...state,
                patient: {
                    ...state.patient,
                    loading: false,
                    error: action.error,
                },
            };
        }

        case ClearPatientCardPage.Name: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
}
