import { AppointmentFilterType } from '@ugmk/api';
import MainLayout from 'components/layouts/MainLayout';
import { AppointmentsFeed } from 'components/partial/appointments-feed';
import React from 'react';
import ServicePageHeader from './components/service-page-header/service-page-header';

const ServicePage: React.FC = () => {
    return (
        <MainLayout>
            <MainLayout.Header>
                <ServicePageHeader />
            </MainLayout.Header>
            <AppointmentsFeed filterType={AppointmentFilterType.Service} />
            <MainLayout.HelpLink />
            <MainLayout.MenuNavigation />
        </MainLayout>
    );
};

export default ServicePage;
