import Typography from 'components/base/Typography';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { AppRoutes } from 'router/routes';
import { Colors } from 'styles/colors';
import * as S from './styles';

interface Props {
    name: string;
    surname: string;
    patronymic: string;
    birthYear: string | null;
    patientId: string;
}

const PatientListItem: React.FC<Props> = (props) => {
    const patientName = `${props.surname} ${props.name} ${props.patronymic[0]}.`;

    return (
        <S.Container to={generatePath(AppRoutes.Patient, { id: props.patientId })}>
            <Typography type="h3" color={Colors.Orange} textAlign="left" marginBottom={6} weight="medium">
                {patientName}
            </Typography>
            <Typography type="span" size={14} textAlign="left" marginBottom={0}>
                Год рождения — {props.birthYear}
            </Typography>
        </S.Container>
    );
};

export default PatientListItem;
