import { RootState } from 'store/root.reducer';
import { createSelector } from 'reselect';
import { AppointmentPageState } from './appointment.reducers';
import { DiagnosisFieldState } from '@ugmk/api';

export const appointmentPageSelector = (state: RootState): AppointmentPageState => state.appointmentPage;

export const currentAppointmentSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.appointment,
);

export const isLoadingAppointmentSelector = createSelector(
    [appointmentPageSelector],
    (appoinemntPage) => appoinemntPage.isLoadingAppointment,
);

export const appointmentPatientIdSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.appointment?.patient.patientId,
);

export const appointmentServiceTypeSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.appointment?.serviceType,
);

export const appointmentIdSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.appointment?.appointmentId,
);
export const isTemplateSelectionModalOpenedSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.isTemplateSelectionModalOpened,
);
export const templatesSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.templates,
);
export const selectedTemplateSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.selectedTemplate,
);
export const isLoadingTemplateDeletingSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.isLoadingTemplateDeleting,
);
export const errorTemplateDeletingSelector = createSelector(
    [appointmentPageSelector],
    (appointmentPage) => appointmentPage.errorTemplateDeleting,
);

export const isDiagnosisFieldRequiredSelector = createSelector(
    [currentAppointmentSelector],
    (appointment) => appointment?.needDiagnosis === DiagnosisFieldState.Required,
);
