import styled, { css } from 'styled-components';
import { Colors } from 'styles/colors';

export const SwitcherContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Label = styled.div`
    font-size: 14px;
    color: ${Colors.LightBlack};
    font-weight: 500;
`;

const switchButtonSize = 20;

export const SwitchButton = styled.div`
    width: ${switchButtonSize}px;
    height: ${switchButtonSize}px;
    border-radius: 50%;
    transition: background-color 0.3s;
`;

export const Controller = styled.div<{ active: boolean; disabled: boolean }>`
    width: 44.57px;
    height: 24px;
    border-radius: 16px;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: ${({ active, disabled }) =>
        disabled ? Colors.DarkWhite : active ? Colors.Orange : Colors.White};
    border: 1px solid;
    border-color: ${({ active, disabled }) => (disabled ? Colors.DarkWhite : active ? Colors.Orange : Colors.DarkGray)};

    ${SwitchButton} {
        position: absolute;
        top: 1px;
        transition: right 0.3s;
        background-color: ${({ active, disabled }) =>
            disabled ? Colors.DarkGray : active ? Colors.White : Colors.DarkGray};
        ${({ active }) =>
            active
                ? css`
                      right: 1.71px;
                  `
                : css`
                      right: calc(100% - ${switchButtonSize}px - 1.71px);
                  `};
    }
`;
