import { IStaffUnit } from '@ugmk/api';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutAction, SetCurrentStaffUnitAction } from 'store/auth/auth.actions';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { RootState } from 'store/root.reducer';
import {
    DropdownList,
    DropdownListItem,
    ExpandIcon,
    HeaderUserInfo,
    LogoutButton,
    StuffUnitDropdownContainer,
    UserInfo,
} from './styles';

const StuffUnitDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const [isDropdownOpened, setDropdownOpened] = useState(false);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const dropwdownRef = useRef<HTMLDivElement | null>(null);
    useOutsideClickHandler(dropwdownRef, () => setDropdownOpened(false));

    const logout = () => {
        dispatch({ ...new LogoutAction() });
    };

    const onSelectedStaffUnit = (staffUnit: IStaffUnit) => {
        dispatch({ ...new SetCurrentStaffUnitAction(staffUnit) });
        setDropdownOpened(false);
    };

    return (
        <StuffUnitDropdownContainer ref={dropwdownRef}>
            <HeaderUserInfo>
                <UserInfo
                    onClick={() => {
                        setDropdownOpened(!isDropdownOpened);
                    }}
                >
                    <Typography type="h2" size={17} textAlign="left" marginBottom={8}>
                        {userInfo?.surnameAndInitials}
                    </Typography>
                    <Typography type="span" size={14} marginBottom={0} weight="regular">
                        {currentStaffUnit && currentStaffUnit.name}
                    </Typography>
                    <ExpandIcon active={isDropdownOpened}>
                        <Icon type={IconType.Expand} />
                    </ExpandIcon>
                </UserInfo>
                <LogoutButton onClick={logout}>
                    <Icon type={IconType.Doors} />
                </LogoutButton>
            </HeaderUserInfo>
            <DropdownList active={isDropdownOpened}>
                {userInfo?.staffUnits.map((staffUnit) => {
                    const isActive = currentStaffUnit?.medDepId === staffUnit.medDepId;
                    return (
                        <DropdownListItem
                            key={staffUnit.externalMedDepId}
                            onClick={() => onSelectedStaffUnit(staffUnit)}
                        >
                            <Typography type="span" size={14} weight={isActive ? 'medium' : 'regular'}>
                                {staffUnit.name}
                            </Typography>
                        </DropdownListItem>
                    );
                })}
            </DropdownList>
        </StuffUnitDropdownContainer>
    );
};

export default React.memo(StuffUnitDropdown);
