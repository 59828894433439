import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseTemplateSelectionScreen } from '../../store/appointment.actions';
import { isTemplateSelectionModalOpenedSelector } from '../../store/appointment.selectors';
import TemplateListStep from './components/template-list/template-list.component';
import TemplateInfoStep from './components/template-info/template-info.component';
import TemplateSelectionProvider from './provider/template-selection-provider';
import * as S from './template-selection-screen.styles';

const TemplateSelectionScreen: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);
    useOutsideClickHandler(containerRef, () => dispatch({ ...new CloseTemplateSelectionScreen() }));
    const isOpened = useSelector(isTemplateSelectionModalOpenedSelector);

    useEffect(() => {
        const containerElement = containerRef.current;
        if (!containerElement) return;

        containerElement.scrollTo(0, containerElement.scrollHeight);
    }, []);

    if (!isOpened) {
        return null;
    }

    return (
        <S.Container ref={containerRef}>
            <TemplateSelectionProvider>
                <TemplateListStep />
                <TemplateInfoStep />
            </TemplateSelectionProvider>
        </S.Container>
    );
});

export default TemplateSelectionScreen;
