import React, { PropsWithChildren } from 'react';

interface Props {
    type: 'h1' | 'h2' | 'h3' | 'p' | 'span';
    size?: number;
    weight?: 'bold' | 'semibold' | 'medium' | 'regular';
    color?: string;
    textAlign?: 'center' | 'left' | 'right';
    marginBottom?: number;
    lh?: number;
}

const Typography: React.FC<PropsWithChildren<Props>> = (props) => {
    let fontFamily: string = '';
    switch (props.weight) {
        case 'bold':
            fontFamily = 'Montserrat Bold';
            break;
        case 'semibold':
            fontFamily = 'Montserrat Semibold';
            break;
        case 'medium':
            fontFamily = 'Montserrat Medium';
            break;
        default:
            fontFamily = 'Montserrat Regular';
            break;
    }
    return (
        <props.type
            style={{
                fontSize: props.size,
                fontFamily,
                textAlign: props.textAlign,
                color: props.color,
                marginBottom: props.marginBottom,
                marginTop: 0,
                lineHeight: `${props.lh}px`,
                whiteSpace: 'pre-line',
            }}
        >
            {props.children}
        </props.type>
    );
};

Typography.defaultProps = {
    type: 'p',
    size: 16,
    weight: 'regular',
    textAlign: 'center',
    marginBottom: 15,
};

export default Typography;
