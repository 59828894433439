import { back } from '@lagunovsky/redux-react-router';
import { takeLatest, call, select, put } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { ApiResponse, IPatient, PatientApi } from '@ugmk/api';
import { medDepIdSelector } from 'store/auth/auth.selectors';
import { ShowInfoModalAction } from 'store/common/info-modal/info-modal.actions';
import { GetPatientById, GetPatientByIdError, GetPatientByIdSuccess } from './patient.actions';

function* getPatientByIdHandler(action: GetPatientById) {
    const medDepId: string | undefined = yield select(medDepIdSelector);

    if (!medDepId) {
        yield put({ ...new GetPatientByIdError('Data corrupted. MedDepId does not exists.') });
        return;
    }

    const { data, error }: ApiResponse<IPatient> = yield call(PatientApi.getPatientById, action.patientId, medDepId);

    if (data) {
        yield put({ ...new GetPatientByIdSuccess(data) });
    } else if (error) {
        if (error.response?.status === 404) {
            yield put(back());
            yield put({ ...new ShowInfoModalAction({ buttonText: 'ОК', contentMessage: 'Пациент не найден' }) });
        }
        yield put({
            ...new GetPatientByIdError(`Get Patient Error. Status: ${error?.code}. Message: ${error?.message}`),
        });
    }
}

export default function* patientPageSaga(): SagaIterator {
    yield takeLatest(GetPatientById.Name, getPatientByIdHandler);
}
