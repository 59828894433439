import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const FilterValue = styled.div<{ active: boolean }>`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: ${({ active }) => (active ? Colors.Orange : Colors.Gray)};
    color: ${({ active }) => (active ? Colors.Orange : Colors.LightBlack)};
    transition: color 0.3s, border-color 0.3s;

    &:first-child {
        border-radius: 15px 0px 0px 15px;
        border-right-width: ${({ active }) => (active ? 1 : 0)};
    }
    &:last-child {
        border-radius: 0px 15px 15px 0px;
        border-left-width: ${({ active }) => (active ? 1 : 0)};
    }
`;

export const ServiceTypeFilterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 48px;
`;
