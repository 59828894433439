import { usePrevious } from 'hooks/usePrevious';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
interface Props {
    isActive: boolean;
    expandTime?: number;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Expander: React.FC<PropsWithChildren<Props>> = ({ isActive, expandTime = 300, onClick, children }) => {
    const [isComponentUnmounted, setComponentUnmounted] = useState<boolean>(isActive);
    const ref = useRef<HTMLDivElement | null>(null);
    const isActivePrev = usePrevious(isActive);

    useEffect(() => {
        if (isActive) {
            setComponentUnmounted(true);
        } else {
            setTimeout(() => {
                setComponentUnmounted(false);
            }, expandTime);
        }
    }, [isActive, expandTime]);

    useEffect(() => {
        const container = ref.current;
        if (container && isActivePrev !== null) {
            if (isActive) {
                setTimeout(() => {
                    container.style.height = `${container.scrollHeight}px`;
                    setTimeout(() => {
                        container.style.height = 'auto';
                    }, expandTime);
                }, 10);
            } else {
                container.style.height = `${container.scrollHeight}px`;
                setTimeout(() => {
                    container.style.height = '0px';
                }, 10);
            }
        }
    }, [isActive, isActivePrev, expandTime]);

    return (
        <div
            style={{ transition: `height ${expandTime}ms`, overflow: 'hidden', height: '0px' }}
            ref={ref}
            onClick={onClick}
        >
            {isComponentUnmounted && children}
        </div>
    );
};
