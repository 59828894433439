import { LocalStorageService } from 'helpers/local-storage-service/local-storage-service';
import { LocalStorageKey } from 'helpers/local-storage-service/local-storage-service.types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigationType } from 'react-router-dom';
import { CheckupFormData } from '../components/appointment-form/checkup-form/checkup-form.component';
import { LFKFormData } from '../components/appointment-form/lfk-form/lfk-form.component';

export function useAppointmentPrefilledFormState() {
    const dispatch = useDispatch();
    const navigationType = useNavigationType();
    const [prefilledFormState, setPrefilledFormState] = useState<CheckupFormData | LFKFormData | null>(null);

    useEffect(() => {
        const savedAppointmentFormData = LocalStorageService.getItem(LocalStorageKey.SAVED_APPOINTMENT_FORM_DATA);

        if (navigationType === 'POP' && savedAppointmentFormData) {
            setPrefilledFormState(savedAppointmentFormData);
            LocalStorageService.removeItem(LocalStorageKey.SAVED_APPOINTMENT_FORM_DATA);
        }
    }, [navigationType, dispatch]);

    const clearPrefilledFormState = useCallback(() => {
        setPrefilledFormState(null);
    }, []);

    return { prefilledFormState, clearPrefilledFormState };
}
