import { DiagnosticResultType } from '@ugmk/api';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import { Kebab } from 'components/base/kebab-menu/kebab-menu.component';
import { PatientCardPageLocationState } from 'pages/main-app/patient-card';
import {
    AddEmptyTemplateToSelectedTemplate,
    OpenSaveAsTemplateModal,
} from 'pages/main-app/patient-card/store/patient-card.actions';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as S from './patient-result-options-button.styles';

interface Props {
    resultData: DiagnosticResultType;
    fromAppointmentState?: PatientCardPageLocationState;
}

const PatientResultOptionsButton: React.FC<Props> = ({ resultData, fromAppointmentState }) => {
    const dispatch = useDispatch();

    const onSaveTemplateClick = useCallback(() => {
        dispatch({ ...new OpenSaveAsTemplateModal(resultData) });
    }, [resultData, dispatch]);

    const onAddToProtocol = useCallback(() => {
        dispatch({ ...new AddEmptyTemplateToSelectedTemplate(resultData) });
    }, [dispatch, resultData]);

    return (
        <Kebab>
            <Kebab.Toggler>
                <Icon type={IconType.VerticalDots} />
            </Kebab.Toggler>
            <Kebab.List>
                <S.Item onClick={onSaveTemplateClick}>Сохранить как шаблон</S.Item>
                {fromAppointmentState && fromAppointmentState.serviceType === resultData.serviceType && (
                    <S.Item onClick={onAddToProtocol}>Добавить в протокол</S.Item>
                )}
            </Kebab.List>
        </Kebab>
    );
};

export default PatientResultOptionsButton;
