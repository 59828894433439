import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const StuffUnitDropdownContainer = styled.div`
    position: relative;
`;

export const UserInfo = styled.div`
    position: relative;
    height: 68px;
`;

export const HeaderUserInfo = styled.div`
    display: flex;
    flex-direction: row;

    & > div:first-of-type {
        flex: 1;
    }
`;

export const LogoutButton = styled.div`
    background-color: ${Colors.Orange};
    border-radius: 50%;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DropdownListItem = styled.div`
    margin: 6px 20px;
    &:first-of-type {
        margin-top: 20px;
    }
    &:last-of-type {
        margin-bottom: 20px;
    }
`;

export const DropdownList = styled.div<{ active: boolean }>`
    position: absolute;
    top: 80px;
    max-height: ${({ active }) => (active ? '265px' : 0)};
    transition: max-height 0.3s;
    overflow: auto;
    background-color: ${Colors.DarkWhite};
    border-radius: 15px;
    z-index: 1;
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity 0.3s;
    box-shadow: 8px 7px 26px rgba(0, 0, 0, 0.04);
    border: 1.5px solid ${Colors.Blue};

    ${DropdownListItem} {
        opacity: ${({ active }) => (active ? 1 : 0)};
        transition: opacity 0.3s;
    }
`;

export const ExpandIcon = styled.div<{ active: boolean }>`
    position: absolute;
    right: 20px;
    top: 40%;
    transition: transform 0.3s;
    transform: ${({ active }) => (active ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;
