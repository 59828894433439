import { IconType } from 'components/base/icon/types';
import React from 'react';
import { AppRoutes } from 'router/routes';
import MenuNavigationLink from './MenuNavigationLink';
import { MenuNavigationContainer } from './styles';

const MenuNavigation: React.FC = () => {
    return (
        <MenuNavigationContainer>
            {MenuLinks.map((menuLink) => {
                return (
                    <MenuNavigationLink
                        key={menuLink.route}
                        to={menuLink.route}
                        icon={menuLink.icon}
                        label={menuLink.title}
                    />
                );
            })}
        </MenuNavigationContainer>
    );
};

interface IMenuLink {
    title: string;
    icon: IconType;
    route: AppRoutes;
}

const MenuLinks: IMenuLink[] = [
    {
        title: 'На оказание',
        icon: IconType.Heart,
        route: AppRoutes.Service,
    },
    {
        title: 'По расписанию',
        icon: IconType.BigCalendar,
        route: AppRoutes.Schedule,
    },
    {
        title: 'Пациенты',
        icon: IconType.People,
        route: AppRoutes.PatientList,
    },
];

export default MenuNavigation;
