import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAnalysisResults, LoadNewPageAnalysisResults } from '../store/patient-analyzes.actions';
import { patientAnalysisResultsSelector } from '../store/patient-analyzes.selectors';

export function useAnalysisResults(patientId: string | null) {
    const dispatch = useDispatch();
    const { data, loading, error } = useSelector(patientAnalysisResultsSelector);

    useEffect(() => {
        if (!patientId) return;
        dispatch({ ...new GetAnalysisResults(patientId) });
    }, [patientId, dispatch]);

    const loadNewPageAnalysisResults = useCallback(() => {
        if (!patientId) return;
        dispatch({ ...new LoadNewPageAnalysisResults(patientId) });
    }, [patientId, dispatch]);

    return {
        isLoadingAnalysisResults: loading,
        analysisResults: data,
        analysisResultsError: error,
        loadNewPageAnalysisResults,
    };
}
