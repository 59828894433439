import { InputContainer } from 'components/base/input/styles';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Container = styled.div<{ active: boolean, error: boolean }>`
    width: 100%;
    border-radius: 15px;
    background-color: ${Colors.DarkWhite};
    border: 1.5px solid ${({ active, error }) => getColorState(active, error, Colors.DarkWhite)};
    transition: border-color 0.3s;
    position: relative;

    ${InputContainer} {
        margin: 0;

        input {
            border: none;
        }
    }
`;

export const ValueContainer = styled.div`
    width: 100%;
    height: 58px;
    padding: 26px 50px 12px 22px;
`;

export const Label = styled.div<{ active: boolean }>`
    position: absolute;
    top: ${({ active }) => (active ? '12px' : '19px')};
    left: 20px;
    font-size: ${({ active }) => (active ? '11px' : '16px')};
    font-weight: ${({ active }) => (active ? 500 : 400)};
    color: ${Colors.DarkGray};
    transition: color 0.3s, font-size 0.3s, font-weight 0.3s;
`;

export const RightIcon = styled.div<{ active: boolean }>`
    position: absolute;
    top: 21px;
    right: 21px;
    width: 16px;
    height: 16px;

    svg path {
        stroke: ${({ active }) => (active ? Colors.LightBlack : Colors.DarkGray)};
        transition: stroke 0.3s;
    }
`;

export const ListItem = styled.div`
    padding: 10px 25px 10px 20px;
`;

export const List = styled.div<{ active: boolean }>`
    max-height: ${({ active }) => (active ? '150px' : 0)};
    transition: max-height 0.3s;
    overflow: auto;
    margin-right: 25px;

    ${ListItem} {
        opacity: ${({ active }) => (active ? 1 : 0)};
        transition: opacity 0.3s;
    }
`;

export const ErrorLabel = styled.div`
    color: ${Colors.Red};
    font-size: 11px;
    font-weight: 500;
    width: 100%;
    padding: 9px 15px 0;
`;

function getColorState(active: boolean, error: boolean, defaultColor: string) {
    if (error) return Colors.Red;
    if (active) return Colors.Blue;
    return defaultColor;
}
