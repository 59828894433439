import { AppointmentFilterType } from '@ugmk/api';
import MainLayout from 'components/layouts/MainLayout';
import { AppointmentsFeed } from 'components/partial/appointments-feed';
import React from 'react';
import SchedulePageHeader from './components/schedule-page-header/schedule-page-header';

export const SchedulePage: React.FC = () => {
    return (
        <MainLayout>
            <MainLayout.Header>
                <SchedulePageHeader />
            </MainLayout.Header>
            <AppointmentsFeed filterType={AppointmentFilterType.Schedule} />
            <MainLayout.HelpLink />
            <MainLayout.MenuNavigation />
        </MainLayout>
    );
};
