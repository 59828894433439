import { createSelector } from 'reselect';
import { RootState } from 'store/root.reducer';

export const patientCardPageSelector = (state: RootState) => state.patientCardPage;

export const isSaveAsTemplateModalOpenedSelector = createSelector(
    [patientCardPageSelector],
    (state) => state.isSaveAsTemplateModalOpened,
);
export const saveAsTemplateDataSelector = createSelector(
    [patientCardPageSelector],
    (state) => state.saveAsTemplateData,
);
export const isLoadingSaveAsTemplateSelector = createSelector(
    [patientCardPageSelector],
    (state) => state.isLoadingSaveAsTemplate,
);
export const errorSaveAsTemplateSelector = createSelector(
    [patientCardPageSelector],
    (state) => state.errorSaveAsTemplate,
);
