import { MENU_NAVIGATION_HEIGHT } from 'components/layouts/MainLayout/components/MenuNavigation/styles';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const FloatingButton = styled.div`
    width: 100%;
`;

export const TemplateInfoItem = styled.div`
    border-radius: 15px;
    background-color: ${Colors.DarkWhite};
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    min-height: 62px;
`;

export const TemplateInfo = styled.div`
    border-radius: 15px;
    background-color: ${Colors.White};
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    margin-bottom: calc(70px + ${MENU_NAVIGATION_HEIGHT});

    ${TemplateInfoItem} {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    overflow-y: auto;
    position: relative;

    ${FloatingButton} {
        position: fixed;
        width: 284px;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(15px + ${MENU_NAVIGATION_HEIGHT});
    }
`;

export const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 12px;
        transform: rotate(180deg);
    }
`;

export const Header = styled.div`
    background-color: ${Colors.White};
    border-radius: 0 0 15px 15px;
    padding: 25px;

    ${BackButton} {
        margin-bottom: 20px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
`;
