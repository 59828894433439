import { Expander } from 'components/base/expander';
import { useMainLayoutState } from 'components/layouts/MainLayout/state/MainLayoutProvider';
import PatientSearchForm from 'components/partial/patient-search-form';
import StuffUnitDropdown from 'components/partial/stuff-unit-dropdown';
import React from 'react';

const PatientCardListPageHeader: React.FC = () => {
    const { headerActive, setHeaderActive } = useMainLayoutState();
    return (
        <>
            <StuffUnitDropdown />
            <Expander isActive={headerActive}>
                <PatientSearchForm onAfterSearch={() => setHeaderActive(false)} />
            </Expander>
        </>
    );
};

export default PatientCardListPageHeader;
