import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import MainLayout from 'components/layouts/MainLayout';
import { BackButton } from 'components/partial/back-button';
import usePaginateByScroll from 'hooks/usePaginateByScroll';
import moment from 'moment';
import React, { useRef } from 'react';
import {generatePath, useParams} from 'react-router-dom';
import { useAnalysisResults } from './hooks/use-analysis-results';
import * as S from './patient-analyzes.styles';
import {AppRoutes} from "../../../router/routes";

type PatientAnalyzesPageParams = Record<'id', string>;

const PatientAnalyzesPage: React.FC = () => {
    const feedContainer = useRef<HTMLDivElement | null>(null);

    const params = useParams<PatientAnalyzesPageParams>();
    const patientId = params.id;

    const { analysisResults, loadNewPageAnalysisResults } = useAnalysisResults(patientId || null);
    usePaginateByScroll(feedContainer, loadNewPageAnalysisResults);

    return (
        <MainLayout>
            <MainLayout.Header isShowSearchButton={false}>
                <S.HeaderContainer>
                    <BackButton label="Вернуться назад" withIcon={true} styles={{ marginBottom: 20 }} />
                    <Typography type={'h1'} textAlign="left">
                        Диагностика
                    </Typography>
                </S.HeaderContainer>
            </MainLayout.Header>
            <S.Body ref={feedContainer}>
                {analysisResults.map((analisys, ind) => {
                    const serializedDateConsultation = moment(analisys.dateConsultation).format('DD.MM.YYYY');
                    const title = `${serializedDateConsultation} ${analisys.title} выполнил ${analisys.executor}`;
                    return (
                        <S.SectionButton
                            key={ind}
                            to={generatePath(path, {patientId: analisys.patientId, documentId: analisys.fileId})}
                        >
                            {title}
                            <Icon type={IconType.Angle} />
                        </S.SectionButton>
                    );
                })}
            </S.Body>
            <MainLayout.MenuNavigation />
        </MainLayout>
    );
};

const path = AppRoutes.PatientDocument;

export default PatientAnalyzesPage;
