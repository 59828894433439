import { ConfirmAppointmentRequest } from '..';
import { GetAppointmentsRequest } from '../models/appointments/GetAppointmentsRequest';
import { Appointment } from '../models/appointments/Appointment';
import { IAppointmentType } from '../models/appointments/IAppointmentType';
import Api from '../utils/api';

export class AppointmentApi {
    static getAppointmentTypes(medDepId: string, externalMedDepId: string) {
        return Api.get<IAppointmentType[]>('/appointments/types', { params: { medDepId, externalMedDepId } });
    }

    static getAppointments(request: GetAppointmentsRequest) {
        return Api.get<Appointment[]>('/appointments', { params: { ...request } });
    }

    static confirmAppointment(request: ConfirmAppointmentRequest) {
        return Api.post('/appointments/confirm', { ...request });
    }

    static getAppointmentScheduleById<Appointment>(id: number, medDepId: string) {
        return Api.get<Appointment>(`/appointments/schedule/${id}`, { params: { medDepId } });
    }

    static getAppointmentServiceById<Appointment>(id: number, medDepId: string) {
        return Api.get<Appointment>(`/appointments/service/${id}`, { params: { medDepId } });
    }
}
