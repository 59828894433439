import React, { useEffect, useState } from 'react';

export function useDocumentModalWidth(docContainerRef: React.RefObject<HTMLDivElement>) {
    const [pageWidth, setPageWidth] = useState<number | undefined>(undefined);

    useEffect(() => {
        const docContainer = docContainerRef.current;
        if (!docContainer) return;

        setTimeout(() => {
            console.log(docContainerRef.current?.clientWidth);

            setPageWidth(docContainerRef.current?.clientWidth);
        }, 1000);

        window.addEventListener('orientationchange', () => {
            console.log('???');

            setTimeout(() => setPageWidth(docContainer.clientWidth), 100);
        });
    }, [docContainerRef]);

    return pageWidth;
}
