import { EmptyState } from 'components/base/empty-state/empty-state.component';
import { IconType } from 'components/base/icon/types';
import { useMainLayoutState } from 'components/layouts/MainLayout/state/MainLayoutProvider';
import React from 'react';

const SearchEmptyState: React.FC = () => {
    // It's a very bad solution, but I don't know how resolve this :(
    const { setHeaderActive } = useMainLayoutState();
    return (
        <EmptyState
            icon={IconType.Search}
            title="Мы ничего не нашли..."
            description="Возможно стоит изменить параметры поиска"
            button={{ label: 'Изменить параметры поиска', onClick: () => setHeaderActive(true) }}
        />
    );
};

export default SearchEmptyState;
