import { useEffect, useState } from 'react';

export function useDocumentPagesCount(pdfFile: Blob | null) {
    const [pagesCount, setPagesCount] = useState<number>(0);
    useEffect(() => {
        if (!pdfFile) return;

        const reader = new FileReader();
        reader.readAsBinaryString(pdfFile);
        reader.onloadend = () => {
            setPagesCount(Number(reader.result?.toString().match(/\/Type[\s]*\/Page[^s]/g)?.length || 0));
        };
    }, [pdfFile]);

    return pagesCount;
}
