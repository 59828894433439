import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const MenuNavigationLinkContainer = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin: auto;

    span {
        transition: color 0.3s;
        color: ${({ active }) => (active ? Colors.Orange : Colors.DarkGray)};
    }

    svg {
        margin-bottom: 6px;
        path {
            transition: fill 0.3s;
            fill: ${({ active }) => (active ? Colors.Orange : Colors.DarkGray)};
        }
    }
`;
