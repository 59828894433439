import InfoModalModel from "models/common/InfoModalModel";
import { Action } from "redux";

export class ShowInfoModalAction implements Action {
    public static readonly Name = "[Info Modal] Show";
    readonly type = ShowInfoModalAction.Name;
    constructor(public options: InfoModalModel) {}
}

export class ClearInfoModalAction implements Action {
    public static readonly Name = "[Info Modal] Clear";
    readonly type = ClearInfoModalAction.Name;
}

export type InfoModalActions = 
    | ShowInfoModalAction
    | ClearInfoModalAction;