import * as React from 'react';
import { AuthLayoutContainer, Footer, Header, MainContent } from './styles';

interface Props {
    headerContent: React.ReactNode;
    footerContent: React.ReactNode;
}

const AuthLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
    return (
        <AuthLayoutContainer>
            <Header>{props.headerContent}</Header>
            <MainContent>{props.children}</MainContent>
            <Footer>{props.footerContent}</Footer>
        </AuthLayoutContainer>
    );
};

export default AuthLayout;
