import { createGlobalStyle, css } from 'styled-components/macro';
import { Colors } from './colors';

export const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
    }
    html {
        scroll-behavior: smooth;
    }
    * {
        box-sizing: border-box;
        color: ${Colors.LightBlack};
        font-family: "Montserrat Regular";
        outline: none;
    }

    a {
        text-decoration: none;
    }

    p {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
    }
`;

export const OverlayStyle = css`
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    transition: opacity 0.3s;
`;
