import { LocalStorageKey, LocalStorageValue } from './local-storage-service.types';

export class LocalStorageService {
    public static getItem<T extends LocalStorageKey>(key: T): LocalStorageValue<T> | null {
        const stringifyData = localStorage.getItem(key);
        if (!stringifyData) {
            return null;
        } else {
            try {
                return JSON.parse(stringifyData) as LocalStorageValue<T> | null;
            } catch (e) {
                console.error('Parse Local Storage Error: ', e);
                return null;
            }
        }
    }

    public static setItem<T extends LocalStorageKey>(key: T, value: LocalStorageValue<T>) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static removeItem<T extends LocalStorageKey>(key: T) {
        localStorage.removeItem(key);
    }
}
