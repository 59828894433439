import { useEffect, useState } from "react";

export default function useShowPassword(inputType?: string, onShowPasswordCallback?: () => void) {
    const [isShowPassword, showPassword] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (inputType === 'password') showPassword(false);
    }, [inputType, showPassword]);

    const getInputType = () => {
        if (isShowPassword === undefined) return inputType;
        return isShowPassword ? 'text' : 'password';
    };

    const onShowPassword = () => {
        showPassword(!isShowPassword);
        onShowPasswordCallback && onShowPasswordCallback();
    };

    return { getInputType, onShowPassword }
}