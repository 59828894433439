import styled from 'styled-components';
import { Colors } from 'styles/colors';
import {Link} from "react-router-dom";

export const HeaderContainer = styled.div``;
export const SectionButton = styled(Link)`
    background-color: ${Colors.White};
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${Colors.LightBlack};
`;
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    overflow-y: scroll;

    ${SectionButton} {
        margin-top: 15px;
    }
`;
