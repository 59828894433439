import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

export interface IMainLayoutContext {
    headerActive: boolean;
    setHeaderActive: (isActive: boolean) => void;
}

export const MainLayoutContext = createContext<IMainLayoutContext>(null!);

const MainLayoutProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [headerActive, setHeaderActive] = useState(false);
    return (
        <MainLayoutContext.Provider
            value={{
                headerActive,
                setHeaderActive,
            }}
        >
            {children}
        </MainLayoutContext.Provider>
    );
};

export function useMainLayoutState() {
    const context = useContext(MainLayoutContext);
    if (!context) {
        throw new Error('useMainLayoutState must be used within the MainLayoutProvider');
    }
    return context;
}

export default MainLayoutProvider;
