import Loader from 'components/base/Loader';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAppointmentTypesAction } from 'store/appointments/appointments.actions';
import { GetMeAction } from 'store/auth/auth.actions';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { RootState } from 'store/root.reducer';
import AuthPageSwitcher from 'pages/auth/auth';
import MainApp from 'pages/main-app/main-app';
import { GetOutcomes, GetResults } from 'store/handbooks/handbooks.actions';

const RootRouter: React.FC = () => {
    const dispatch = useDispatch();
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const isAppointmentTypesInitialized = useSelector(
        (state: RootState) => state.appointments.isAppointmentTypesInitialized,
    );
    const isInitialized = useSelector((state: RootState) => state.auth.isInitialized);
    const isLoading = useSelector((state: RootState) => state.auth.isLoading);
    const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);

    useEffect(() => {
        dispatch({ ...new GetMeAction() });
    }, [dispatch]);

    useEffect(() => {
        if (currentStaffUnit) {
            dispatch({
                ...new GetAppointmentTypesAction(currentStaffUnit),
            });
            dispatch({ ...new GetResults(currentStaffUnit.medDepId) });
            dispatch({ ...new GetOutcomes(currentStaffUnit.medDepId) });
        }
    }, [dispatch, currentStaffUnit]);

    if (isLoading || !isInitialized) {
        return <Loader fullScreen={true} />;
    }

    if (!isAuthorized) {
        return <AuthPageSwitcher />;
    }

    if (!isAppointmentTypesInitialized) {
        return <Loader fullScreen={true} />;
    }

    return <MainApp />;
};

export default RootRouter;
