import { SagaIterator } from 'redux-saga';
import {
    ApiResponse,
    clearAuthHeaders,
    IUser,
    LoginResponse,
    RegisterStatus,
    setAuthHeaders,
    UserIdentityApi,
} from '@ugmk/api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    AuthErrorAction,
    ConfirmCodeAction,
    ConfirmCodeErrorAction,
    GetMeAction,
    GetMeErrorAction,
    GetMeSuccessAction,
    GetWaitingTimeCodeAction,
    GetWaitingTimeCodeSuccessAction,
    LogInAction,
    LogInErrorAction,
    LogoutAction,
    LogoutSuccessAction,
    RegisterAction,
    RegisterErrorAction,
    RegisterSuccessAction,
    RestorePasswordAction,
    SendCodeAction,
    SetCurrentStaffUnitAction,
} from './auth.actions';
import { push, replace } from '@lagunovsky/redux-react-router';
import { AppRoutes } from 'router/routes';
import { ShowInfoModalAction } from 'store/common/info-modal/info-modal.actions';
import { CONFIRM_EMAIL, RESET_PASSWORD_ID } from 'state/constants';
import { RootState } from 'store/root.reducer';
import { clearStorage, getToken, setToken } from './auth.helper';
import { LocalStorageService } from 'helpers/local-storage-service/local-storage-service';
import { LocalStorageKey } from 'helpers/local-storage-service/local-storage-service.types';

function* loginHandler(action: LogInAction): SagaIterator {
    const { data, error }: ApiResponse<LoginResponse> = yield call(
        UserIdentityApi.login,
        action.email,
        action.password,
    );

    if (data) {
        setToken(data.access_token);
        setAuthHeaders(data.access_token);
        yield put({ ...new GetMeAction() });
        yield put(replace(AppRoutes.Service));
    } else {
        yield put({ ...new LogInErrorAction(error) });
        yield put({
            ...new ShowInfoModalAction({
                title: 'Ошибка при входе',
                buttonText: 'OK',
                contentMessage: 'Проверьте правильность ввода “логина” и “пароля”',
            }),
        });
    }
}

function* registerHandler(action: RegisterAction) {
    const { error } = yield call(UserIdentityApi.register, action.email, action.password);

    if (!error) {
        yield put({ ...new RegisterSuccessAction(RegisterStatus.Success) });
        yield put({
            ...new ShowInfoModalAction({
                title: 'Проверьте почту',
                contentMessage: `Вы успешно зарегистрированы! Мы отправили ссылку для активации аккаунта на почту ${action.email}`,
                buttonText: 'Хорошо!',
            }),
        });
        window.localStorage.setItem(CONFIRM_EMAIL, action.email);
        yield put(push(AppRoutes.RegisterConfirm));
    } else {
        const regError = error.response.status === 403 ? RegisterStatus.Exists : RegisterStatus.ServerError;
        yield put({ ...new RegisterErrorAction(regError, error) });
    }
}

function* getMeHandler() {
    const token = getToken();
    if (token) {
        setAuthHeaders(token);
        const { data, error }: ApiResponse<IUser> = yield call(UserIdentityApi.getMe);

        console.log(data);
        if (data) {
            yield put({ ...new GetMeSuccessAction(data) });
            const savedMedDepIdOfStaffUnit: string | null = LocalStorageService.getItem(
                LocalStorageKey.STAFF_UNIT_MEDDEP_ID,
            );
            let currentStaffUnit = data.staffUnits[0];
            if (savedMedDepIdOfStaffUnit) {
                currentStaffUnit =
                    data.staffUnits.find((staffUnit) => staffUnit.medDepId === savedMedDepIdOfStaffUnit) ||
                    currentStaffUnit;
            }
            yield put({ ...new SetCurrentStaffUnitAction(currentStaffUnit) });
        } else if (error) {
            yield put({ ...new GetMeErrorAction(error) });
            console.error('Get me Error: ', error);
        }
    } else {
        yield put({ ...new GetMeErrorAction("Token doesn't exists") });
    }
}

function* authErrorHandler() {
    const currentLocation: Location = yield select((state: RootState) => state.router.location);
    const isAuthPage = [
        AppRoutes.Login,
        AppRoutes.ResetPassword,
        AppRoutes.Register,
        AppRoutes.NewPassword,
        AppRoutes.RegisterConfirm,
        AppRoutes.ResetPasswordConfirm,
    ].find((r) => r === currentLocation.pathname);

    if (!isAuthPage) {
        yield put(replace(AppRoutes.Login));
    }
}

function* logoutHandler() {
    const data: ApiResponse<null> = yield call(UserIdentityApi.logout);

    if (data.status === 200) {
        clearAuthHeaders();
        clearStorage();
        yield put({ ...new LogoutSuccessAction() });
        yield put(replace(AppRoutes.Login));
    } else {
        console.error(data.error);
    }
}

function* sendCodeHandler(action: SendCodeAction) {
    const { error } = yield call(UserIdentityApi.sendCode, action.request);

    if (!error) {
        yield put({ ...new GetWaitingTimeCodeAction(action.request.email) });
        if (action.request.action === 'U' && window.location.pathname !== AppRoutes.ResetPasswordConfirm) {
            window.localStorage.setItem(CONFIRM_EMAIL, action.request.email);
            yield put(push(AppRoutes.ResetPasswordConfirm));
            yield put({
                ...new ShowInfoModalAction({
                    title: 'Проверьте почту',
                    contentMessage: `Мы отправили Вам ссылку для восстановления доступа к аккаунту на почту ***${action.request.email.slice(
                        3,
                    )}`,
                    buttonText: 'Хорошо!',
                }),
            });
        }
    } else {
        if (action.request.action === 'U' && window.location.pathname !== AppRoutes.ResetPasswordConfirm) {
            if (error.response.status === 404) {
                yield put({
                    ...new ShowInfoModalAction({
                        title: 'Пользователь не найден',
                        contentMessage: `Пользователь с такой почтой не зарегистрирован в системе`,
                        buttonText: 'Ок',
                    }),
                });
                yield put(push(AppRoutes.Register));
            }
        }
    }
}

function* confirmCodeHandler(action: ConfirmCodeAction) {
    const { data, error } = yield call(UserIdentityApi.confirmCode, action.request);

    if (data && data.is_verified) {
        if (action.request.action === 'C') {
            yield put(replace(AppRoutes.Login));
            yield put({
                ...new ShowInfoModalAction({
                    title: 'Аккаунт активирован',
                    buttonText: 'Хорошо!',
                    contentMessage: 'Теперь можете зайти под своей новой учетной записью',
                }),
            });
            window.localStorage.removeItem(CONFIRM_EMAIL);
        } else {
            window.localStorage.setItem(RESET_PASSWORD_ID, data._id);
            yield put(replace(AppRoutes.NewPassword));
        }
    } else if (error) {
        console.log('Confirm error: ', error);
        yield put({ ...new ConfirmCodeErrorAction('Код введен неверно') });
    }
}

function* getWaitingTimeCodeHandler(action: GetWaitingTimeCodeAction) {
    const { data, error } = yield call(UserIdentityApi.getWaitingTimeCode, action.email);

    if (!error) {
        yield put({ ...new GetWaitingTimeCodeSuccessAction(data.time) });
    }
}

function* restorePasswordHandler(action: RestorePasswordAction) {
    const { data, error } = yield call(UserIdentityApi.restorePassword, action.userId, action.password);

    if (data) {
        if (data.is_verified) {
            yield put(replace(AppRoutes.Login));
            yield put({
                ...new ShowInfoModalAction({
                    title: 'Пароль успешно изменен!',
                    buttonText: 'ОК',
                    contentMessage: '',
                }),
            });
            window.localStorage.removeItem(RESET_PASSWORD_ID);
        }
    } else if (error) {
        window.localStorage.removeItem(RESET_PASSWORD_ID);
        yield put(replace(AppRoutes.ResetPassword));
        yield put({
            ...new ShowInfoModalAction({
                title: 'Ошибка',
                buttonText: 'ОК',
                contentMessage: 'Пароль не может быть изменен',
            }),
        });
        console.log('Restore error: ', error);
    }
}

export default function* authSaga(): SagaIterator {
    yield takeLatest(AuthErrorAction.Name, authErrorHandler);
    yield takeLatest(GetMeAction.Name, getMeHandler);
    yield takeLatest(LogInAction.Name, loginHandler);
    yield takeLatest(RegisterAction.Name, registerHandler);
    yield takeLatest(LogoutAction.Name, logoutHandler);
    yield takeLatest(SendCodeAction.Name, sendCodeHandler);
    yield takeLatest(ConfirmCodeAction.Name, confirmCodeHandler);
    yield takeLatest(GetWaitingTimeCodeAction.Name, getWaitingTimeCodeHandler);
    yield takeLatest(RestorePasswordAction.Name, restorePasswordHandler);
}
