import React, { PropsWithChildren } from 'react';
import * as S from './tooltip.styles';

interface Props {
    label: string;
    disabled?: boolean;
}

export const Tooltip: React.FC<PropsWithChildren<Props>> = (props) => {
    const { label, disabled } = props;
    return (
        <S.Container>
            <S.HoverComponent>{props.children}</S.HoverComponent>
            {!disabled && <S.TooltipContainer>{label}</S.TooltipContainer>}
        </S.Container>
    );
};
