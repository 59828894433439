import { useEffect } from 'react';
import { debounce } from 'lodash';

export default function usePaginateByScroll(
    scrolledContainerRef: React.MutableRefObject<HTMLDivElement | null>,
    onLoadNextPage: () => void,
) {
    useEffect(() => {
        const scrolledContainer = scrolledContainerRef.current;
        if (scrolledContainer) {
            const onScrollHandler = debounce(() => {
                if (
                    scrolledContainer.offsetHeight + scrolledContainer.scrollTop >=
                    scrolledContainer.scrollHeight - 500
                ) {
                    onLoadNextPage();
                }
            }, 350);
            scrolledContainer.addEventListener('scroll', onScrollHandler);

            return () => {
                onScrollHandler.cancel();
                scrolledContainer.removeEventListener('scroll', onScrollHandler);
            };
        }
    }, [onLoadNextPage, scrolledContainerRef]);
}
