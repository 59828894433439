import { Button } from 'components/base/button';
import { Grid } from 'components/base/grid/grid.styles';
import { InputText } from 'components/base/input';
import ModalBase from 'components/base/ModalBase';
import Typography from 'components/base/Typography';
import React, { useCallback, ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { Colors } from 'styles/colors';
import { CloseSaveAsTemplateModal, SaveAsTemplate } from '../../store/patient-card.actions';
import {
    errorSaveAsTemplateSelector,
    isLoadingSaveAsTemplateSelector,
    isSaveAsTemplateModalOpenedSelector,
    saveAsTemplateDataSelector,
} from '../../store/patient-card.selectors';

const SaveAsTemplateModal: React.FC = () => {
    const dispatch = useDispatch();
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const [templateTitle, setTemplateTitle] = useState<string>('');
    const isSaveAsTemplateModalOpened = useSelector(isSaveAsTemplateModalOpenedSelector);
    const saveAsTemplateData = useSelector(saveAsTemplateDataSelector);
    const isLoadingSaveAsTemplate = useSelector(isLoadingSaveAsTemplateSelector);
    const errorSaveAsTemplate = useSelector(errorSaveAsTemplateSelector);

    const onTitleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setTemplateTitle(e.target.value);
    }, []);

    const onSaveTemplate = () => {
        if (currentStaffUnit && saveAsTemplateData) {
            dispatch({
                ...new SaveAsTemplate({
                    medDepId: currentStaffUnit.medDepId,
                    title: templateTitle,
                    ...saveAsTemplateData,
                }),
            });
        }
    };

    return (
        <ModalBase
            isOpen={isSaveAsTemplateModalOpened}
            onHide={() => {
                setTemplateTitle('');
                dispatch({ ...new CloseSaveAsTemplateModal() });
            }}
            hideOnOverlayClicked={true}
        >
            <Grid.Col>
                <Typography type="h1" weight="semibold" size={24} marginBottom={15}>
                    Сохранить шаблон
                </Typography>
                <Typography type="span" marginBottom={15} lh={23} textAlign="center">
                    Придумайте информативное название для нового шаблона
                </Typography>
                <InputText
                    disabled={isLoadingSaveAsTemplate}
                    label="Название шаблона"
                    onChange={onTitleInputChange}
                    style={{ marginBottom: !!errorSaveAsTemplate ? 10 : 20 }}
                />
                {errorSaveAsTemplate && (
                    <Typography type="span" size={12} marginBottom={20} color={Colors.Red}>
                        {errorSaveAsTemplate.message}
                    </Typography>
                )}
                <Button disabled={isLoadingSaveAsTemplate} label="Сохранить шаблон" onClick={onSaveTemplate} />
            </Grid.Col>
        </ModalBase>
    );
};

export default SaveAsTemplateModal;
