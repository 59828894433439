import styled from 'styled-components/macro';

export const Container = styled.div`
    padding: 20px 25px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;

    & > * {
        margin-top: 20px;

        &:last-of-type {
            margin-bottom: 20px;
        }
    }
`;
