import { CheckupDiagnosticResult, LfkDiagnosticResult, DiagnosticResultType, ServiceType } from '@ugmk/api';
import React from 'react';
import { CheckupResultForm } from './components/checkup-result';
import { LfkResultForm } from './components/lfk-result';

interface Props {
    serviceType: ServiceType;
    resultData: DiagnosticResultType;
}

const PatientResultForm: React.FC<Props> = ({ serviceType, resultData }) => {
    if (serviceType === ServiceType.Checkup) {
        return <CheckupResultForm {...(resultData as CheckupDiagnosticResult)} />;
    }
    if (serviceType === ServiceType.LFK) {
        return <LfkResultForm {...(resultData as LfkDiagnosticResult)} />;
    }
    return <></>;
};

export default PatientResultForm;
