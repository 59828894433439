import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Container = styled(Link)`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.White};
    padding: 20px;
    border-radius: 15px;
    width: 100%;
`;
