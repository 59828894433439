import MainLayout from 'components/layouts/MainLayout';
import PatientListItem from 'pages/main-app/patient-list/components/patient-list-item';
import usePaginateByScroll from 'hooks/usePaginateByScroll';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentStaffUnitSelector } from 'store/auth/auth.selectors';
import { GetPatientsAction, LoadNextPagePatientsAction } from 'store/patients/patients.actions';
import { RootState } from 'store/root.reducer';
import PatientCardListPageHeader from './components/patient-list-page-header/patient-list-page-header';
import * as S from './patient-list.styles';

export const PatientListPage: React.FC = () => {
    const dispatch = useDispatch();
    const feedContainer = useRef<HTMLDivElement | null>(null);
    const currentStaffUnit = useSelector(currentStaffUnitSelector);
    const patientCards = useSelector((state: RootState) => state.patient.patients);

    usePaginateByScroll(feedContainer, () => {
        if (currentStaffUnit) {
            dispatch({ ...new LoadNextPagePatientsAction(currentStaffUnit.medDepId) });
        }
    });

    useEffect(() => {
        if (currentStaffUnit) {
            dispatch({ ...new GetPatientsAction(currentStaffUnit.medDepId) });
        }
    }, [dispatch, currentStaffUnit]);

    return (
        <MainLayout>
            <MainLayout.Header>
                <PatientCardListPageHeader />
            </MainLayout.Header>
            <S.Container ref={feedContainer}>
                {patientCards.map((patientCard) => (
                    <PatientListItem
                        name={patientCard.name}
                        surname={patientCard.surname}
                        patronymic={patientCard.patronymic}
                        birthYear={patientCard.birthYear}
                        patientId={patientCard.patientId}
                        key={patientCard.patientId}
                    />
                ))}
            </S.Container>
            <MainLayout.HelpLink />
            <MainLayout.MenuNavigation />
        </MainLayout>
    );
};
