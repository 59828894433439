import { takeLatest, call, select, put } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { ApiResponse, AnalysisResult, PatientApi } from '@ugmk/api';
import { medDepIdSelector } from 'store/auth/auth.selectors';
import { RootState } from 'store/root.reducer';
import {
    GetAnalysisFileById,
    GetAnalysisFileByIdError,
    GetAnalysisFileByIdSuccess,
    GetAnalysisResults,
    GetAnalysisResultsError,
    GetAnalysisResultsSuccess,
    LoadNewPageAnalysisResults,
    LoadNewPageAnalysisResultsSuccess,
} from './patient-analyzes.actions';

function* getAnalysisResultsHandler(action: GetAnalysisResults): SagaIterator {
    const medDepId: string | undefined = yield select(medDepIdSelector);

    if (!medDepId) {
        yield put({ ...new GetAnalysisResultsError('Data corrupted. MedDepId does not exists.') });
        return;
    }

    const { data, error }: ApiResponse<AnalysisResult[]> = yield call(PatientApi.getAnalysisResults, action.patientId, {
        medDepId,
        page: 1,
    });

    if (data) {
        yield put({ ...new GetAnalysisResultsSuccess(data) });
    } else {
        yield put({
            ...new GetAnalysisResultsError(
                `Get Diagnostic Results Error. Status: ${error?.code}. Message: ${error?.message}`,
            ),
        });
    }
}

function* loadNewPageAnalysisResultsHandler(action: LoadNewPageAnalysisResults): SagaIterator {
    const medDepId: string | undefined = yield select(medDepIdSelector);

    if (!medDepId) {
        yield put({ ...new GetAnalysisResultsError('Data corrupted. MedDepId does not exists.') });
        return;
    }
    const pageNumber: number = yield select((state: RootState) => state.patientAnalyzesPage.analysisResults.page);
    const isEndList: boolean = yield select((state: RootState) => state.patientAnalyzesPage.analysisResults.isEnd);

    if (isEndList) return;

    const { data, error }: ApiResponse<AnalysisResult[]> = yield call(PatientApi.getAnalysisResults, action.patientId, {
        medDepId,
        page: pageNumber,
    });

    if (data) {
        yield put({ ...new LoadNewPageAnalysisResultsSuccess(data) });
    } else {
        yield put({
            ...new GetAnalysisResultsError(
                `Get Diagnostic Results Error. Status: ${error?.code}. Message: ${error?.message}`,
            ),
        });
    }
}

function* getAnalysisFileByIdHandler(action: GetAnalysisFileById): SagaIterator {
    const medDepId: string | undefined = yield select(medDepIdSelector);

    if (!medDepId) {
        yield put({ ...new GetAnalysisResultsError('Data corrupted. MedDepId does not exists.') });
        return;
    }

    const { data: fileResponse, error: errorGetAnalysisResult }: ApiResponse<{ fileUrl: string }> = yield call(
        PatientApi.getAnalysisResultById,
        action.fileId,
        medDepId,
    );

    if (fileResponse) {
        const { data: file, error: errorGetFile }: ApiResponse<Blob> = yield call(
            PatientApi.getAnalysisResultFileByUrl,
            fileResponse.fileUrl,
        );
        if (file) {
            yield put({ ...new GetAnalysisFileByIdSuccess(file) });
        } else if (errorGetFile) {
            const { code, message } = errorGetFile;
            yield put({
                ...new GetAnalysisFileByIdError(`Get analysis file error. Code: ${code}. Message: ${message}`),
            });
        }
    } else if (errorGetAnalysisResult) {
        const { code, message } = errorGetAnalysisResult;
        yield put({
            ...new GetAnalysisFileByIdError(`Get analysis file error. Code: ${code}. Message: ${message}`),
        });
    }
}

export default function* patientAnalyzesPageSaga(): SagaIterator {
    yield takeLatest(GetAnalysisResults.Name, getAnalysisResultsHandler);
    yield takeLatest(GetAnalysisFileById.Name, getAnalysisFileByIdHandler);
    yield takeLatest(LoadNewPageAnalysisResults.Name, loadNewPageAnalysisResultsHandler);
}
