import { InputContainer, TextareaContainer, TextInputContainer } from 'components/base/input/styles';
import { SwitcherContainer } from 'components/base/Switcher/styles';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    background-color: ${Colors.White};
    border-radius: 15px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;

    ${TextareaContainer}, ${SwitcherContainer} {
        margin-bottom: 15px;
    }

    ${TextInputContainer}, ${TextareaContainer} {
        &:disabled {
            color: ${Colors.LightBlack};
            -webkit-text-fill-color: ${Colors.LightBlack};
            opacity: 1;
        }
    }
`;

export const Space = styled.div``;

export const CardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -7.5px;

    ${InputContainer} {
        flex: 1;
        margin: 0 7.5px;

        input {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
`;

export const TextareaOnlyRead = styled.div<{ isActive: boolean }>`
    ${TextareaContainer}:disabled {
        -webkit-text-fill-color: ${({ isActive }) => (isActive ? Colors.LightBlack : Colors.DarkGray)};
        color: ${({ isActive }) => (isActive ? Colors.LightBlack : Colors.DarkGray)};
    }
`;
