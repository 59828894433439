import { InputContainer } from 'components/base/input/styles';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Container = styled.div<{ active: boolean }>`
    width: 100%;
    border-radius: 15px;
    background-color: ${Colors.DarkWhite};
    border: 1.5px solid ${({ active }) => (active ? Colors.Blue : Colors.DarkWhite)};
    transition: border-color 0.3s;
    position: relative;

    ${InputContainer} {
        margin: 0;

        input {
            border: none;
        }
    }
`;

export const ListItem = styled.div`
    padding: 10px 25px 10px 20px;
    word-wrap: anywhere;
`;

export const List = styled.div<{ active: boolean }>`
    max-height: ${({ active }) => (active ? '150px' : 0)};
    transition: max-height 0.3s;
    overflow: auto;
    margin-right: 10px;

    ${ListItem} {
        opacity: ${({ active }) => (active ? 1 : 0)};
        transition: opacity 0.3s;
    }
`;
