import { CheckupTemplate, LfkTemplate, ServiceType } from '@ugmk/api';
import { Button } from 'components/base/button';
import { Grid } from 'components/base/grid/grid.styles';
import Icon from 'components/base/icon';
import { IconType } from 'components/base/icon/types';
import Typography from 'components/base/Typography';
import {
    CloseTemplateSelectionScreen,
    DeleteTamplate,
    SelectTemplate,
} from 'pages/main-app/appointment/store/appointment.actions';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from 'styles/colors';
import { useTemplateSelectionContext } from '../../provider/template-selection-provider';
import { TemplateSelectionStep } from '../../types/template-selection.types';
import TemplateDeleteModal from '../template-delete-modal/template-delete-modal.component';
import { CHECKUP_TEMPLATE_LABELS_MAP, LFK_TEMPLATE_LABELS_MAP } from './template-info.constants';
import { getCheckupTemplateFieldText } from './template-info.helpers';
import * as S from './template-info.styles';
import { CheckupTemplateInfoKey, LfkTemplateInfoKey } from './template-info.types';

const TemplateInfo: React.FC = () => {
    const dispatch = useDispatch();
    const [isDeleteModalOpened, toggleDeleteModal] = useState(false);
    const { currentStep, templateItem, changeStep } = useTemplateSelectionContext();

    const applyTemplate = () => {
        if (templateItem) {
            dispatch({ ...new SelectTemplate(templateItem) });
            dispatch({ ...new CloseTemplateSelectionScreen() });
        }
    };

    const deleteTemplate = useCallback(() => {
        if (templateItem) {
            dispatch({
                ...new DeleteTamplate(templateItem._id, () => {
                    changeStep(TemplateSelectionStep.List);
                }),
            });
        }
    }, [dispatch, templateItem, changeStep]);

    if (currentStep !== TemplateSelectionStep.Info || !templateItem) return null;

    return (
        <S.Container>
            <S.Header>
                <S.BackButton onClick={() => changeStep(TemplateSelectionStep.List)}>
                    <Icon type={IconType.Arrow} />
                    <Typography type="span" color={Colors.Orange} marginBottom={0}>
                        Вернуться к выбору шаблонов
                    </Typography>
                </S.BackButton>
                <Grid.Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography type="span" textAlign="left" size={17} weight="medium" lh={25.5} marginBottom={0}>
                        {templateItem.title}
                    </Typography>
                    <Button
                        icon={IconType.Trash}
                        onClick={() => toggleDeleteModal(true)}
                        label=""
                        theme="ghost"
                        style={{ width: 58, flexShrink: 0 }}
                    />
                </Grid.Row>
            </S.Header>
            <S.Content>
                <S.TemplateInfo>
                    {templateItem.serviceType === ServiceType.Checkup &&
                        Object.keys(CHECKUP_TEMPLATE_LABELS_MAP).map((templateInfoKey: string) => {
                            return (
                                <S.TemplateInfoItem key={templateInfoKey}>
                                    <Typography
                                        type="span"
                                        size={11}
                                        color={Colors.DarkGray}
                                        weight="medium"
                                        marginBottom={5}
                                        textAlign="left"
                                    >
                                        {CHECKUP_TEMPLATE_LABELS_MAP[templateInfoKey as CheckupTemplateInfoKey]}
                                    </Typography>
                                    <Typography type="span" lh={22.4} textAlign="left" marginBottom={0}>
                                        {getCheckupTemplateFieldText(
                                            templateItem as CheckupTemplate,
                                            templateInfoKey as CheckupTemplateInfoKey,
                                        )}
                                    </Typography>
                                </S.TemplateInfoItem>
                            );
                        })}
                    {templateItem.serviceType === ServiceType.LFK &&
                        Object.keys(LFK_TEMPLATE_LABELS_MAP).map((templateInfoKey: string) => {
                            return (
                                <S.TemplateInfoItem key={templateInfoKey}>
                                    <Typography
                                        type="span"
                                        size={11}
                                        color={Colors.DarkGray}
                                        weight="medium"
                                        marginBottom={5}
                                        textAlign="left"
                                    >
                                        {LFK_TEMPLATE_LABELS_MAP[templateInfoKey as LfkTemplateInfoKey]}
                                    </Typography>
                                    <Typography type="span" lh={22.4} textAlign="left" marginBottom={0}>
                                        {(templateItem as LfkTemplate)[templateInfoKey as LfkTemplateInfoKey]}
                                    </Typography>
                                </S.TemplateInfoItem>
                            );
                        })}
                </S.TemplateInfo>
                <S.FloatingButton>
                    <Button label="Применить шаблон" onClick={applyTemplate} />
                </S.FloatingButton>
            </S.Content>
            <TemplateDeleteModal
                isOpened={isDeleteModalOpened}
                onClose={() => toggleDeleteModal(false)}
                onAccept={() => {
                    deleteTemplate();
                    toggleDeleteModal(false);
                }}
            />
        </S.Container>
    );
};

export default TemplateInfo;
